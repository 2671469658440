import React, { useContext, useEffect } from 'react';
import Context from '../../../context/Context';
import { Tooltip, IconButton } from '@mui/material';
import { Dialog, DialogActions, DialogContent, DialogTitle } from '@mui/material';
import { Button, Stepper, Step, StepLabel, Typography } from '@mui/material';
import { Box } from '@mui/material';
import { postInvoice } from '../../../services/operations';
import { ExceptionsHandler } from '../../../services/exceptions';
import CloseIcon from '@mui/icons-material/Close';
import SelectOperation from './invoiceSteps/SelectOperation';
import GenerateInvoice from './invoiceSteps/GenerateInvoice';

function InvoiceRegisterDialog() {
  const { setAlert } = useContext(Context);
  const { registerDialog, setRegisterDialog } = useContext(Context);
  const { invoiceData, setInvoiceData } = useContext(Context);
  const { setRefresh } = useContext(Context);
  const [disabledLoading, setDisabledLoading] = React.useState(false);
  const [activeStep, setActiveStep] = React.useState(0);

  const session_token = localStorage.getItem('session_token');

  const steps = [
    {
      label: 'Selecione um Centro de Custo',
      requirements: 'operation_id',
      element: <SelectOperation />
    },
    {
      label: 'Gerar Fatura',
      requirements: 'operation_id',
      element: <GenerateInvoice />
    }
  ];

  const handleNext = () => {
    // TODO REMOVER PRINT ALL
    setInvoiceData({ print_all: true, ...invoiceData});
    setActiveStep((prevActiveStep) => prevActiveStep + 1);
  };

  const handleBack = () => {
    setActiveStep((prevActiveStep) => prevActiveStep - 1);
  };

  const handleReset = () => {
    setActiveStep(0);
    setInvoiceData({ print_all: true, attach_files: false });
  };

  const generateInvoiceHandler = () => {
    setDisabledLoading(true);
    postInvoice(invoiceData, session_token)
      .then(({ data }) => {
        setAlert({
          state: true,
          type: 'success',
          message: 'Fatura gerada com sucesso!'
        });
        open(data.invoice_s3_link, '_blank');
        handleClose();
        setDisabledLoading(true);
      })
      .catch((err) => {
        setAlert(ExceptionsHandler(err));
        setDisabledLoading(false);
      });
  };

  const disabledButton = () => {
    const { requirements } = steps[activeStep];
    if (disabledLoading){
      return disabledLoading
    }
    if (requirements === 'reimbursement_ids') {
      console.log(invoiceData[requirements]);
      return !invoiceData[requirements]?.length > 0; 
    }
    return !invoiceData[requirements];
  };

  const handleClose = () => {
    handleReset();
    setRegisterDialog(false);
    setRefresh(true);
  };

  return (
    <>
      <Dialog open={registerDialog} onClose={handleClose} sx={{zIndex: '1202'}}>
        <DialogTitle>
          Gerar Fatura
          <Tooltip title="Fechar">
            <IconButton
              color="secondary"
              onClick={handleClose}
              sx={{ position: 'absolute', top: 0, right: 0 }}>
              <CloseIcon />
            </IconButton>
          </Tooltip>
        </DialogTitle>
        <DialogContent style={{ padding: '20px' }}>
          <Stepper activeStep={activeStep}>
            {steps.map((obj) => {
              const stepProps = {};
              const labelProps = {};
              return (
                <Step key={obj.label} {...stepProps}>
                  <StepLabel {...labelProps}>{obj.label}</StepLabel>
                </Step>
              );
            })}
          </Stepper>
          <Box sx={{ marginTop: '2rem' }}>{steps[activeStep].element}</Box>
        </DialogContent>
        <DialogActions sx={{ justifyContent: 'space-between' }}>
        {activeStep === steps.length ? (
            <>
              <Box sx={{ display: 'flex', flexDirection: 'row', pt: 2 }}>
                <Box sx={{ flex: '1 1 auto' }} />
                <Button onClick={handleReset}>Reset</Button>
              </Box>
            </>
          ) : (
            <>
              <Box sx={{ display: 'flex', flexDirection: 'row' }}>
                {activeStep > 0 && (
                  <Button
                    variant="outlined"
                    disabled={activeStep === 0}
                    onClick={handleBack}
                    sx={{ mr: 1 }}>
                    Voltar
                  </Button>
                )}
                {activeStep === steps.length - 1 ? (
                  <Button
                    onClick={generateInvoiceHandler}
                    disabled={disabledLoading}>
                    <Typography>{disabledLoading? 'Gerando Aguarde' : 'Gerar Fatura'}</Typography>
                  </Button>
                ) : (
                  <Button
                    onClick={handleNext}
                    disabled={disabledButton()}>
                    Próximo
                  </Button>
                )}
              </Box>
            </>
          )}
        </DialogActions>
      </Dialog>
    </>
  );
}

export default InvoiceRegisterDialog;
