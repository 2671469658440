import React, { useContext, useState, useEffect } from 'react';
import { Card, CardHeader, CardContent, Grid, MenuItem, InputLabel, FormControl, TextField, Select } from '@mui/material';
import Context from '../../../context/Context';
import { DataGrid, ptBR } from '@mui/x-data-grid';
import { customMask, formatObject } from '../../../utils/helpers';
import { CustomFilter, CustomPagination } from '../../../utils/customGrid';
import { ExceptionsHandler } from '../../../services/exceptions';
import AddCircleIcon from '@mui/icons-material/AddCircle';
import {Button} from '@mui/material';
import { getClients } from '../../../services/clients';
import { clientsForm } from '../../../utils/registerFields';
import ClientsRegisterDialog from '../../dialogs/clientsDialogs/ClientsRegisterDialog';
import ClientsFormDialog from '../../dialogs/clientsDialogs/ClientsFormDialog';
import { CleaningServices, FilterAlt, FilterAltOff } from '@mui/icons-material';

function Clients() {
  const { setAlert } = useContext(Context);
  const [clientsData, setClientsData] = useState([]);
  const [editRow, setEditRow] = useState({});
  const { dialog, setDialog } = useContext(Context);
  const { refresh, setRefresh } = useContext(Context);
  const [filter, setFilter] = useState({});
  const [page, setPage] = React.useState(0);
  const [loading, setLoading] = useState(false);
  const { registerDialog, setRegisterDialog } = useContext(Context);
  // Filter section vars
  const [ filterSection, setFilterSection ] = useState(false);

  const session_token = localStorage.getItem('session_token');

  const handleValues = (params) => {
    const { field, value } = params;
    return customMask(field, value);
  };

  const clientsColumns = clientsForm.map(({ name, label, width, align, type, hiden }) => ({
    field: name,
    headerName: label,
    flex: width,
    minWidth: 100 * width,
    align: align,
    headerAlign: align,
    type: type,
    valueOptions:
      name === 'status' ? ['Aberto', 'Fechado', 'Aguardando Pagamento', 'Pendente'] : null,
    hide: hiden,
    filterable: false,
    sortable: false,
    filterOperators: CustomFilter(name),
    valueGetter: (params) => handleValues(params)
  }));

  const ClientsList = (filter) =>
    getClients(formatObject(filter), session_token)
      .then(({ data }) => data)
      .catch((err) => setAlert(ExceptionsHandler(err)));

  const clientsListHandler = async (method) => {
    setLoading(true);
    const prevPage = clientsData.actual_page;
    const data = await ClientsList({ ...filter, page: page + 1 });
    const { clients } = data;
    if (method === 'add') {
      let newClients = [];
      for (let i = prevPage + 1; i < page; i++) {
        const remainingData = await ClientsList({ ...filter, page: i });
        newClients.push(remainingData.clients);
      }
      newClients.push(clients);
      setClientsData({
        ...data,
        clients: clientsData.clients.concat(newClients.flat())
      });
    } else setClientsData(data);
    setLoading(false);
  };

  const handleEditClients = (row) => {
    setEditRow(row);
    setDialog(true);
  };

  const handleAddClients = () => {
    setRegisterDialog(true);
  };

  useEffect(() => {
    if (refresh === true) {
      setPage(0);
      clientsListHandler();
      setRefresh(false);
    }
  }, [refresh]);

  useEffect(() => {
    clientsListHandler();
  }, []);

  useEffect(() => {
    clientsData.has_next && clientsData.actual_page < page
      ? clientsListHandler('add')
      : null;
  }, [page]);

    //Filter Section Stuff

    const handleAddFilter = () => {
      setFilterSection(!filterSection);
    };
  
    const mountFilterGrid = () => {
      const styleForGrid = {
        paddingBottom: "10px",
        textAlign: 'center',
        display: 'flex',
        justifyContent: 'center',
        alignItems: 'center',
      }
  
      const styleForInputs = {
        width: "100%"
      }
  
      const handleFilterChange = ({ name, value }) => {
        if (value === null || value === ''){
          const filterHelper = filter
          delete filterHelper[name]
          setFilter({...filterHelper})
        }else{
          setFilter({ ...filter, [name]: customMask(name, value) });
        }
      };
  
      const handleFilterClean = () => {
        setFilter({});
        setRefresh(true);
      };
  
      const handleFilterAction = () => {
        setRefresh(true);
      };
      
      return (
        <div style={{paddingBottom: "20px"}}>
          <Grid container>
            <Grid item xs={12}><h3>Filtros:</h3></Grid>
            <Grid item xs={12} sm={4} style={styleForGrid}>
            <TextField
                  label="Nome"
                  name="name"
                  multiline
                  value={filter.name || ''}
                  onChange={({ target }) => handleFilterChange(target)}
                  style={styleForInputs}
                  />
            </Grid>
            <Grid item xs={12} sm={4} style={styleForGrid}>
            <TextField
                  label="Documento do Cliente (CPF/CNPJ)"
                  name="document_number"
                  multiline
                  value={filter.document_number || ''}
                  onChange={({ target }) => handleFilterChange(target)}
                  style={styleForInputs}
                  />
            </Grid>
            <Grid item xs={12} sm={4} style={styleForGrid}>
              <FormControl fullWidth>
                <InputLabel id="type-label">Categoria</InputLabel>
                <Select
                  name="type"
                  required
                  label="Pago"
                  value={filter.type || ''}
                  onChange={({target}) => handleFilterChange(target)}
                  style={styleForInputs}>
                  <MenuItem value="PJ">PJ</MenuItem>
                  <MenuItem value="PF">PF</MenuItem>
                </Select>
              </FormControl>
            </Grid>
            <Grid item xs={12} sm={4} style={styleForGrid}>
              <TextField
                  label="Silga"
                  name="initials"
                  multiline
                  value={filter.initials || ''}
                  onChange={({ target }) => handleFilterChange(
                    {
                      name: target.name,
                      value: target.value.toUpperCase()
                    }
                  )}
                  style={styleForInputs}
                  />
            </Grid>
            <Grid item xs={12} sm={4} style={styleForGrid}>
              <TextField
                  label="Email"
                  name="email"
                  multiline
                  value={filter.email || ''}
                  onChange={({ target }) => handleFilterChange(target)}
                  style={styleForInputs}
                  />
            </Grid>
            <Grid item xs={12} sm={12} style={styleForGrid}></Grid>
            <Grid item xs={12} sm={6} style={styleForGrid}>
              <Button 
                style={{height: "100%"}} 
                onClick={handleFilterAction}>
                  Filtrar <FilterAlt/>
              </Button>
            </Grid>
            <Grid item xs={12} sm={6} style={styleForGrid}>
              <Button 
                style={{height: "100%"}}  
                variant="outlined" 
                onClick={handleFilterClean}>
                  Limpar <CleaningServices/>
              </Button>
            </Grid>
          </Grid>
        </div>
      )
    }

  return (
    <div className="page-content">
      {dialog && <ClientsFormDialog row={editRow} />}
      {registerDialog && <ClientsRegisterDialog />}
      <Card className="data-grid-card">
        <CardHeader className="card-header" title="Gestão de Clientes" />
        <CardContent className="card-body">
          <div style={{ width: '100%' }}>
            <div className="data-grid-header">
            <Button 
                style={{width: "auto", marginRight: "5px"}} 
                variant="outlined" 
                onClick={handleAddFilter}>
                  {filterSection ? <FilterAltOff/> : <FilterAlt/>}
                  {Object.keys(filter).length}
              </Button>
              <Button id="btn-reimbursements-register" variant="outlined" onClick={handleAddClients}>Adicionar Cliente<AddCircleIcon/></Button>
            </div>
            {filterSection && mountFilterGrid()}
            <DataGrid
              rows={clientsData.clients || []}
              columns={clientsColumns}
              pageSize={10}
              rowCount={(clientsData.total_pages || 1) * 10}
              page={page}
              autoHeight
              loading={loading}
              disableSelectionOnClick
              onPageChange={(newPage) => (newPage != 'NaN' ? setPage(newPage) : null)}
              onRowDoubleClick={({ row }) => handleEditClients(row)}
              localeText={ptBR.components.MuiDataGrid.defaultProps.localeText}
              components={{
                Pagination: CustomPagination
              }}
            />
          </div>
        </CardContent>
      </Card>
    </div>
  );
}

export default Clients;
