import React, { useContext } from 'react';
import PropTypes from 'prop-types';
import Context from '../../../context/Context';
import Button from '@mui/material/Button';
import Dialog from '@mui/material/Dialog';
import DialogActions from '@mui/material/DialogActions';
import DialogContent from '@mui/material/DialogContent';
import DialogContentText from '@mui/material/DialogContentText';
import DialogTitle from '@mui/material/DialogTitle';
import { patchUsersDebtsPaid } from '../../../services/dashboards';
import { ExceptionsHandler } from '../../../services/exceptions';
import { customMask } from '../../../utils/helpers';

function CollaboratorsDebtDialog({ row }) {
  const { setAlert } = useContext(Context);
  const { confirmeDialogSecond, setConfirmeDialogSecond } = useContext(Context);
  const { setRefresh } = useContext(Context);
  const { setDialog } = useContext(Context);
  const session_token = localStorage.getItem('session_token');

  const payUserDebtsHandler = () => {
    patchUsersDebtsPaid(
      {
        user_id: row.user_id,
        reimbursement_ids: row.reimbursement_ids
      },
      session_token
    )
      .then(() => {
        setAlert({
          state: true,
          type: 'success',
          header: 'Deu certo!',
          message: 'Registramos o pagamento da despesa!'
        });
        handleClose();
        setConfirmeDialogSecond(false);
      })
      .catch((err) => {
        setAlert(ExceptionsHandler(err));
      });
  };

  const handleConfirme = () => {
    payUserDebtsHandler();
    setDialog(false);
    setRefresh(true);
  };

  const handleClose = () => {
    setConfirmeDialogSecond(false);
  };

  return (
    <div>
      <Dialog
        open={confirmeDialogSecond}
        onClose={handleClose}
        aria-labelledby="alert-dialog-title"
        aria-describedby="alert-dialog-description"
        sx={{zIndex: '1202'}}>
        <DialogTitle id="alert-dialog-title">
          Deseja registrar o pagamento do saldo em aberto do colaborador <b>{row.name}</b>?
        </DialogTitle>
        <DialogContent>
          <DialogContentText id="alert-dialog-description">
            Ao definir como pago o saldo de <b>{customMask('debt_amount', row.debt_amount)}</b>{' '}
            deste colaborador todas as despesas aprovadas cujo a soma resulta nesse valor serão
            pagos. Não é possível voltar atrás dessa ação!
          </DialogContentText>
        </DialogContent>
        <DialogActions>
          <Button onClick={handleClose}>Cancelar</Button>
          <Button variant="delete" onClick={handleConfirme} autoFocus>
            Definir como Pago
          </Button>
        </DialogActions>
      </Dialog>
    </div>
  );
}

CollaboratorsDebtDialog.propTypes = {
  row: PropTypes.object
};

export default CollaboratorsDebtDialog;
