import React, { useContext, useState, useEffect } from 'react';
import {
  Button,
  Dialog,
  Grid,
  Select,
  InputLabel,
  MenuItem,
  TextField,
  FormControl,
} from '@mui/material';
import { Card, CardHeader, CardContent, IconButton } from '@mui/material';
import Context from '../../../context/Context';
import { DataGrid, ptBR as dataGridLocale } from '@mui/x-data-grid';
import { customMask, formatObject, renderCellReimbursementHelper } from '../../../utils/helpers';
import {
  getReimbursements, getReimbursementsUrl
} from '../../../services/reimbursements';
import { reimbursementForm } from '../../../utils/registerFields';
import { CustomFilter, CustomPagination } from '../../../utils/customGrid';
import { ExceptionsHandler } from '../../../services/exceptions';
import ReimbursementsFormDialog from '../../dialogs/reimbursementsDialog/ReimbursementsFormDialog';
import { CleaningServices, FilterAlt, FilterAltOff, PhotoLibrary, PictureAsPdf } from '@mui/icons-material';
import { LocalizationProvider, DatePicker, ptBR as datePickerLocale } from '@mui/x-date-pickers';
import { AdapterDateFns } from '@mui/x-date-pickers/AdapterDateFns';
import { ptBR as ptBRLocale } from 'date-fns/locale';

function Reimbursements() {
  const { setAlert } = useContext(Context);
  const [allReimbursementsData, setAllReimbursementsData] = useState([]);
  const [editRow, setEditRow] = useState({});
  const { dialog, setDialog } = useContext(Context);
  const [ filterSection, setFilterSection ] = useState(false);
  const { refresh, setRefresh } = useContext(Context);
  const [filter, setFilter] = useState({});
  const [page, setPage] = React.useState(0);
  const [img_url, setImg_url] = useState('');
  const [loading, setLoading] = useState(false);
  const [open, setOpen] = React.useState(false);
  // Filter section vars
  const [invoiceDateInit, setInvoiceDateInit] = useState(null);
  const [invoiceDateEnd, setInvoiceDateEnd] = useState(null);
  const [ isPaidLabel, setIsPaidLabel ] = useState("");
  const [ isInternalLabel, setIsInternalLabel ] = useState("");

  const session_token = localStorage.getItem('session_token');

  const handleAddFilter = () => {
    setFilterSection(!filterSection);
  };

  const GetReimbursementsUrlHandle = (value) => 
  getReimbursementsUrl(value, session_token)
  .then(({ data }) => data)
    .catch((err) => setAlert(ExceptionsHandler(err)));

  const getS3UrlRb = async (value) => {
    const data = await GetReimbursementsUrlHandle(value);
    const { file_url } = data;
    handleClickOpen(file_url);
    return file_url
  }

  const handleClickOpen = (value) => {
    console.log(value)
    if(value){
      if (value.includes('.pdf')) {
        window.open(value)
      } else {
        setOpen(true);
        setImg_url(value);
      }
    }
  };

  const handleClose = () => {
    setOpen(false);
  };
  
  const handleValues = (params) => {
    const { field, value } = params;
    return customMask(field, value);
  };

  const reimbursementsColumns = reimbursementForm.map(
    ({ name, label, width, align, type, hiden }) => ({
      field: name,
      headerName: label,
      flex: width,
      minWidth: 100 * width,
      align: align,
      headerAlign: align,
      type: type,
      valueOptions:
        name === 'flow_status'
          ? ['Aprovado', 'Pendente', 'Reprovado']
          : name === 'type'
          ? ['Alimentação', 'Transporte', 'Hospedagem', 'Custas Judiciais', 'Outros']
          : null,
      hide: hiden,
      filterable: false,
      sortable: false,
      filterOperators: CustomFilter(name, type),
      valueGetter: (params) => handleValues(params),
      renderCell: (params) => renderCellReimbursementHelper(params)
    })
  );

  const AllReimbursementList = (filter) =>
    getReimbursements(formatObject(filter), session_token)
      .then(({ data }) => data)
      .catch((err) => setAlert(ExceptionsHandler(err)));

  const allReimbursementListHandler = async (method) => {
    setLoading(true);
    const prevPage = allReimbursementsData.actual_page;
    const data = await AllReimbursementList({ ...filter, page: page + 1 });
    const { reimbursements } = data;
    if (method === 'add') {
      let newReimbursements = [];
      for (let i = prevPage; i < page; i++) {
        const remainingData = await AllReimbursementList({ ...filter, page: i + 1 });
        newReimbursements.push(remainingData.reimbursements);
      }
      newReimbursements.push(reimbursements);
      setAllReimbursementsData({
        ...data,
        reimbursements: allReimbursementsData.reimbursements.concat(newReimbursements.flat())
      });
    } else setAllReimbursementsData(data);
    setLoading(false);
  };

  const handleDialogReimbursement = (row) => {
    setEditRow(row);
    setDialog(true);
  };

  const mountFilterGrid = () => {
    const styleForGrid = {
      paddingBottom: "10px",
      textAlign: 'center',
      display: 'flex',
      justifyContent: 'center',
      alignItems: 'center',
    }

    const styleForInputs = {
      width: "100%"
    }

    const handleFilterChange = ({ name, value }) => {
      if (value === null || value === ''){
        const filterHelper = filter
        delete filterHelper[name]
        setFilter({...filterHelper})
      }else{
        setFilter({ ...filter, [name]: customMask(name, value) });
      }
    };

    const handleFilterClean = () => {
      setIsPaidLabel("");
      setInvoiceDateInit(null);
      setInvoiceDateEnd(null);
      setFilter({});
      setRefresh(true);
    };

    const handleFilterAction = () => {
      setRefresh(true);
    };
    
    return (
      <div style={{paddingBottom: "20px"}}>
        <Grid container>
          <Grid item xs={12}><h3>Filtros:</h3></Grid>
          <Grid item xs={12} sm={4} style={styleForGrid}>
            <TextField
                label="Colaborador"
                name="user_name"
                multiline
                value={filter.user_name || ''}
                onChange={({ target }) => handleFilterChange(target)}
                style={styleForInputs}
                />
          </Grid>
          <Grid item xs={12} sm={4} style={styleForGrid}>
            <FormControl fullWidth>
              <InputLabel id="type-label">Tipo da Despesa</InputLabel>
              <Select
                name="expenses_type"
                required
                value={filter.expenses_type || ''}
                label="Tipo da Despesa"
                onChange={({ target }) => handleFilterChange(target)}
                style={styleForInputs}>
                <MenuItem value="Solicitação de Reembolso">Solicitação de Reembolso</MenuItem>
                <MenuItem value="Solicitação de Pagamento">Solicitação de Pagamento</MenuItem>
              </Select>
            </FormControl>
          </Grid>
          <Grid item xs={12} sm={4} style={styleForGrid}>
            <FormControl fullWidth>
              <InputLabel id="type-label">Categoria</InputLabel>
              <Select
                name="type"
                required
                value={filter.type || ''}
                label="Categoria"
                onChange={({ target }) => handleFilterChange(target)}
                style={styleForInputs}>
                <MenuItem value="Alimentação">Alimentação</MenuItem>
                <MenuItem value="Transporte">Transporte</MenuItem>
                <MenuItem value="Hospedagem">Hospedagem</MenuItem>
                <MenuItem value="Custas Judiciais">Custas Judiciais</MenuItem>
                <MenuItem value="Outros">Outros</MenuItem>
              </Select>
            </FormControl>
          </Grid>
          <Grid item xs={12} sm={4} style={styleForGrid}>
            <TextField
                label="TAG Centro de Custo"
                name="or_tag"
                multiline
                value={filter.or_tag || ''}
                onChange={({ target }) => handleFilterChange(target)}
                style={styleForInputs}
                />
          </Grid>
          <Grid item xs={12} sm={4} style={styleForGrid}>
            <FormControl fullWidth>
              <InputLabel id="type-label">Status</InputLabel>
              <Select
                name="flow_status"
                required
                value={filter.flow_status || ''}
                label="Status"
                onChange={({ target }) => handleFilterChange(target)}
                style={styleForInputs}>
                <MenuItem value="Pendente">Pendente</MenuItem>
                <MenuItem value="Aprovado">Aprovado</MenuItem>
                <MenuItem value="Reprovado">Reprovado</MenuItem>
              </Select>
            </FormControl>
          </Grid>
          <Grid item xs={12} sm={4} style={styleForGrid}>
            <FormControl fullWidth>
              <InputLabel id="type-label">Pago</InputLabel>
              <Select
                name="is_paid"
                required
                value={isPaidLabel}
                label="Pago"
                onChange={({target}) => setIsPaidLabel(target.value)}
                style={styleForInputs}>
                <MenuItem value={true}>Sim</MenuItem>
                <MenuItem value={false}>Não</MenuItem>
              </Select>
            </FormControl>
          </Grid>
          <Grid item xs={12} sm={4} style={styleForGrid}>
            <FormControl fullWidth>
              <InputLabel id="type-label">Despesa Interna</InputLabel>
              <Select
                name="is_internal"
                required
                value={isInternalLabel}
                label="Despesa Interna"
                onChange={({target}) => setIsInternalLabel(target.value)}
                style={styleForInputs}>
                <MenuItem value={true}>Sim</MenuItem>
                <MenuItem value={false}>Não</MenuItem>
              </Select>
            </FormControl>
          </Grid>
          <Grid item xs={12} sm={4} style={styleForGrid}>
            <div style={styleForInputs}>
              <LocalizationProvider
                dateAdapter={AdapterDateFns}
                adapterLocale={ptBRLocale}
                localeText={
                  datePickerLocale.components.MuiLocalizationProvider.defaultProps.localeText
                }>
                <DatePicker
                  disableFuture
                  className="input-invoice_dt"
                  name="invoice_dt_init"
                  label="Data do Recibo *"
                  openTo="day"
                  views={['year', 'month', 'day']}
                  inputFormat="dd/MM/yyyy"
                  maxDate={new Date()}
                  value={invoiceDateInit}
                  onChange={(newValue) => setInvoiceDateInit(newValue)}
                  renderInput={(params) => <TextField {...params} />}
                  />
              </LocalizationProvider>
            </div>
          </Grid>
          <Grid item xs={12} sm={4} style={styleForGrid}>
            <div style={styleForInputs}>
              <LocalizationProvider
                dateAdapter={AdapterDateFns}
                adapterLocale={ptBRLocale}
                localeText={
                  datePickerLocale.components.MuiLocalizationProvider.defaultProps.localeText
                }>
                <DatePicker
                  disableFuture
                  className="input-invoice_dt"
                  name="invoice_dt_end"
                  label="Data do Recibo *"
                  openTo="day"
                  views={['year', 'month', 'day']}
                  inputFormat="dd/MM/yyyy"
                  maxDate={new Date()}
                  value={invoiceDateEnd}
                  onChange={(newValue) => setInvoiceDateEnd(newValue)}
                  renderInput={(params) => <TextField {...params} />}
                />
              </LocalizationProvider>
            </div>
          </Grid>
          <Grid item xs={12} sm={4} style={styleForGrid}>
            <TextField
                label="Descrição"
                name="description"
                multiline
                value={filter.description || ''}
                onChange={({ target }) => handleFilterChange(target)}
                style={styleForInputs}
                />
          </Grid>
          <Grid item xs={12} sm={12} style={styleForGrid}></Grid>
          <Grid item xs={12} sm={6} style={styleForGrid}>
            <Button 
              style={{height: "100%"}} 
              onClick={handleFilterAction}>
                Filtrar <FilterAlt/>
            </Button>
          </Grid>
          <Grid item xs={12} sm={6} style={styleForGrid}>
            <Button 
              style={{height: "100%"}}  
              variant="outlined" 
              onClick={handleFilterClean}>
                Limpar <CleaningServices/>
            </Button>
          </Grid>
        </Grid>
      </div>
    )
  }

  useEffect(() => {
    if (refresh === true) {
      setPage(0);
      allReimbursementListHandler();
      setRefresh(false);
    }
  }, [refresh]);

  useEffect(() => {
    allReimbursementListHandler();
  }, []);

  useEffect(() => {
    allReimbursementsData.has_next && allReimbursementsData.actual_page <= page
      ? allReimbursementListHandler('add')
      : null;
  }, [page]);

  useEffect(() => {
    if (invoiceDateInit === null){
      const filterHelper = filter
      delete filterHelper.invoice_dt_init
      setFilter({...filterHelper})
    } else {
      const invoiceDateInitHelper = invoiceDateInit
      invoiceDateInitHelper.setHours(0);
      invoiceDateInitHelper.setMinutes(0);
      setFilter({ ...filter, invoice_dt_init: invoiceDateInitHelper });
    }
  }, [invoiceDateInit]);

  useEffect(() => {
    if (invoiceDateEnd === null){
      const filterHelper = filter
      delete filterHelper.invoice_dt_end
      setFilter({...filterHelper})
    } else {
      const invoiceDateEndHelper = invoiceDateEnd
      invoiceDateEndHelper.setHours(23);
      invoiceDateEndHelper.setMinutes(59);
      setFilter({ ...filter, invoice_dt_end: invoiceDateEndHelper });
    }
  }, [invoiceDateEnd]);

  useEffect(() => {
    if (isPaidLabel === ""){
      const filterHelper = filter
      delete filterHelper.is_paid
      setFilter({...filterHelper})
    } else {
      setFilter({ ...filter, is_paid: isPaidLabel });
    }
  }, [isPaidLabel]);

  useEffect(() => {
    if (isInternalLabel === ""){
      const filterHelper = filter
      delete filterHelper.is_internal
      setFilter({...filterHelper})
    } else {
      setFilter({ ...filter, is_internal: isInternalLabel });
    }
  }, [isInternalLabel]);

  return (
    <div className="page-content">
      {dialog && <ReimbursementsFormDialog row={editRow} />}
      {open &&
        <Dialog open={open} onClose={handleClose}>
          <img src={img_url} alt="Imagem do comprovante" />
        </Dialog>
      }
      <Card className="data-grid-card">
        <CardHeader className="card-header" title="Todas as Despesas" />
        <CardContent className="card-body">
          <div style={{ width: '100%' }}>
            <div className="data-grid-header">
              <Button 
                style={{width: "auto"}} 
                variant="outlined" 
                onClick={handleAddFilter}>
                  {filterSection ? <FilterAltOff/> : <FilterAlt/>}
                  {Object.keys(filter).length}
              </Button>
            </div>
            {filterSection && mountFilterGrid()}
            <DataGrid
              rows={allReimbursementsData.reimbursements || []}
              columns={reimbursementsColumns}
              pageSize={10}
              rowCount={(allReimbursementsData.total_pages || 1) * 10}
              page={page}
              autoHeight
              loading={loading}
              disableSelectionOnClick
              onPageChange={(newPage) => (newPage != 'NaN' ? setPage(newPage) : null)}
              onRowDoubleClick={({ row }) => handleDialogReimbursement(row)}
              localeText={dataGridLocale.components.MuiDataGrid.defaultProps.localeText}
              components={{
                Pagination: CustomPagination
              }}
              onCellClick={({ field, value }) => {
                if (field === 'file_url' && value) {
                  if (value.includes('.pdf')) {
                    getS3UrlRb(value);
                  } else {
                    getS3UrlRb(value);
                  }
                }
              }}
            />
          </div>
        </CardContent>
      </Card>
    </div>
  );
}

export default Reimbursements;
