import React  from 'react';
import {useEffect, useState} from 'react';
import { Grid } from '@mui/material';
import ArrowRightIcon from '@mui/icons-material/KeyboardDoubleArrowRight';
import ArrowDownIcon from '@mui/icons-material/KeyboardDoubleArrowDown';

import fiscal_note_example from '../../../assets/images/homepage/nota_fiscal.jpg'
import adding_expense from '../../../assets/images/homepage/adding_expense2.png'
import invoice_example from '../../../assets/images/homepage/invoice_example_mini.png'


function getWindowWidth() {
    const {innerWidth} = window;
    return innerWidth;
  }

function IlustrationSection() {
    const [windowWidth, setWindowWidth] = useState(getWindowWidth());

    useEffect(() => {
        function handleWindowResize() {
        setWindowWidth(getWindowWidth());
        }

        window.addEventListener('resize', handleWindowResize);
        
        return () => {
            window.removeEventListener('resize', handleWindowResize);
        };
    }, []);

    return (
        <div>
            <Grid container style={{ 
                backgroundColor: 'white', 
                textAlign: 'center', 
                justifyContent: 'center',
                textAlign: 'justify', 
                alignItems: 'center',
                paddingTop: '25px'}}>
                    <Grid item sm={0} md={.5}></Grid>
                    <Grid item sm={12} md={3}>
                        <div>
                            <img
                            src={fiscal_note_example}
                            alt="fiscal_note_example"
                            width="100%"
                            style={{borderRadius: "10%", 
                            maxWidth: "400px",
                            boxShadow: '0 4px 8px 0 rgba(0, 0, 0, 0.2), 0 6px 20px 0 rgba(0, 0, 0, 0.19)'}}/>
                            <p>Seu colaborador realiza um gasto em uma viagem a trabalho e guarda o recibo.</p>
                        </div>
                    </Grid>
                    <Grid item sm={0} md={1}  style={{ 
                        display: 'flex',
                        justifyContent: 'center', 
                        alignItems: 'center'}}>
                        <div>
                            {windowWidth < 900 ? <ArrowDownIcon fontSize="large" color="primary"/> : <ArrowRightIcon fontSize="large" color="primary"/>}
                        </div>
                    </Grid>
                    <Grid item sm={12} md={3}>
                        <div>
                            <img
                            src={adding_expense}
                            alt="adding_expense"
                            width="100%"
                            style={{borderRadius: "10%", 
                            maxWidth: "400px",
                            boxShadow: '0 4px 8px 0 rgba(0, 0, 0, 0.2), 0 6px 20px 0 rgba(0, 0, 0, 0.19)'}}/>
                            <p>Com a Suppi+, ele cadastra esse valor anexando o recibo 
                                via computador, smartphone ou tablet. 
                                Os gastos ficam organizados por centros de custo e por cliente.</p>
                        </div>
                    </Grid>
                    <Grid item sm={0} md={1}  style={{ 
                        display: 'flex',
                        justifyContent: 'center', 
                        alignItems: 'center'}}>
                        <div>
                            {windowWidth < 900 ? <ArrowDownIcon fontSize="large" color="primary"/> : <ArrowRightIcon fontSize="large" color="primary"/>}
                        </div>
                    </Grid>
                    <Grid item sm={12} md={3}>
                        <div>
                            <img
                            src={invoice_example}
                            alt="invoice_example"
                            width="100%"
                            style={{borderRadius: "10%", 
                            maxWidth: "400px",
                            boxShadow: '0 4px 8px 0 rgba(0, 0, 0, 0.2), 0 6px 20px 0 rgba(0, 0, 0, 0.19)'}}/>
                            <p>Após o fim de um projeto, gere uma fatura personalizada 
                                com toda a descrição das despesas dos colaboradores. 
                                Você controla as despesas realizadas e ainda envia para o seu 
                                cliente uma fatura estruturada.</p>
                        </div>
                    </Grid>
                    <Grid item sm={0} md={.5}></Grid>
            </Grid>
        </div>
        );
    }

export default IlustrationSection;