import { client } from './client';

export const getUsersDebts = (filter, session_token) => {
    return client.get(`/dashboard/users/debts`, { params: filter, headers: { Authorization: session_token } });
};

export const patchUsersDebtsPaid = (payload, session_token) => {
    return client.patch(`/dashboard/users/debts/paid`, payload, { headers: { Authorization: session_token } });
};

export const getInvoices = (filter, session_token) => {
    return client.get(`/dashboard/invoices/debts`, { params: filter, headers: { Authorization: session_token } });
}

export const updateInvoice = (payload, session_token) => {
    return client.patch(`/dashboard/invoices/debts/paid`, {...payload, set_op: true}, { headers: { Authorization: session_token } });
}

export const deleteInvoice = (id, session_token) => {
    return client.delete(`/dashboard/invoices/debts`, { data: {invoice_id: id}, headers: { Authorization: session_token } });
}

export const getDashboard = (filter, session_token) => {
    return client.get(`/dashboard/`, { params: filter, headers: { Authorization: session_token } });
}
