import React, { useContext, useState } from 'react';
import Context from '../../../context/Context';
import { TextField, Tooltip, Button, IconButton } from '@mui/material';
import { createUser } from '../../../services/user';
import { Dialog, DialogActions, DialogContent, DialogTitle } from '@mui/material';
import { Checkbox, FormControlLabel } from '@mui/material';
import CloseIcon from '@mui/icons-material/Close';
import { customMask, formatObject } from '../../../utils/helpers';
import { userForm, passwordForm } from '../../../utils/registerFields';
import { isValidPass, isValidUserForm } from '../../../utils/formValidators';
import { ExceptionsHandler } from '../../../services/exceptions';

function CollaboratorsRegisterDialog() {
  const { setAlert } = useContext(Context);
  const { registerDialog, setRegisterDialog } = useContext(Context);
  const { setRefresh } = useContext(Context);
  const session_token = localStorage.getItem('session_token');

  const [editCollaboratorForm, setEditCollaboratorForm] = useState({});
  const [confirmPassword, setConfirmPassword] = useState({});
  const [collaboratoIsAdmin, setCollaboratoIsAdmin] = useState(false);

  const handleClose = () => {
    clearFields();
    setRegisterDialog(false);
  };

  const isDisabled = () => {
    if (isValidPass(confirmPassword) && isValidUserForm(editCollaboratorForm)) return false;
    return true;
  };

  const addUser = () => {
    createUser(formatObject({ ...editCollaboratorForm, is_admin: collaboratoIsAdmin }), session_token)
      .then(() => {
        setAlert({
          state: true,
          message:
            'O novo colaborador foi cadastrado com sucesso. Ele já pode acessar a plataforma!',
          type: 'success',
          header: 'Tudo certo!'
        });
        setRefresh(true);
        handleClose();
      })
      .catch((err) => {
        setAlert(ExceptionsHandler(err));
      });
  };

  const handleChangePassword = ({ name, value }) => {
    setConfirmPassword({ ...confirmPassword, [name]: value });
  };

  const clearFields = () => {
    setEditCollaboratorForm({});
    setConfirmPassword({});
    setCollaboratoIsAdmin(false);
  };

  const handleChangeUser = ({ name, value }) => {
    setEditCollaboratorForm({ ...editCollaboratorForm, [name]: customMask(name, value) });
  };

  return (
    <>
      <Dialog open={registerDialog} onClose={handleClose} sx={{zIndex: '1202'}}>
        <DialogTitle>
          Cadastrar Novo Colaborador
          <Tooltip title="Fechar">
            <IconButton
              color="secondary"
              onClick={handleClose}
              sx={{ position: 'absolute', top: 0, right: 0,}}>
              <CloseIcon />
            </IconButton>
          </Tooltip>
        </DialogTitle>
        <DialogContent style={{ padding: '20px' }}>
          <div className="form form-user-dialog">
            {userForm.map(({ name, label, required, type }, index) => (
              <TextField
                key={index}
                name={name}
                type={type}
                value={editCollaboratorForm[name] || ''}
                required={required}
                className={`input-${name}`}
                label={label}
                onChange={({ target }) => handleChangeUser(target)}
              />
            ))}
            <div className="input-is_admin">
              <FormControlLabel
                control={
                  <Checkbox checked={collaboratoIsAdmin} onChange={() => setCollaboratoIsAdmin(!collaboratoIsAdmin)} />
                }
                label="Colaborador é um administrador"
              />
            </div>
          </div>
          <div className="form form-password-dialog">
            {passwordForm.map(({ name, label, required, type }, index) => (
              <TextField
                key={index}
                name={name}
                type={type}
                value={confirmPassword[name] || ''}
                required={required}
                className={`input-${name}`}
                label={label}
                onChange={({ target }) => {
                  switch (target.name) {
                    case 'password_confirmation':
                      handleChangePassword(target);
                      break;
                    case 'password':
                      handleChangeUser(target);
                      handleChangePassword(target);
                      break;
                  }
                }}
              />
            ))}
          </div>
        </DialogContent>
        <DialogActions sx={{ justifyContent: 'space-between' }}>
          <Button variant="outlined" onClick={clearFields}>
            Limpar
          </Button>
          <Button onClick={addUser} disabled={isDisabled()}>
            Registrar
          </Button>
        </DialogActions>
      </Dialog>
    </>
  );
}

export default CollaboratorsRegisterDialog;
