import React, { useContext  } from 'react';
import Context from '../../../../context/Context';
import { TextField } from '@mui/material';
import { cardEditForm } from '../../../../utils/registerFields';
import { customMask } from '../../../../utils/helpers';

function AddCardStep() {    
    const { planCardData, setPlanCardData } = useContext(Context);

    const handleChange = ({ name, value }) => {
        setPlanCardData({ 
            ...planCardData, 
            [name]: customMask(name, value)
        });
      };

    return (
    <div style={{
        borderStyle: "solid", 
        padding: "0px 10px 20px 10px", 
        borderWidth: "1.5px",
        borderRadius: "10px",
        borderColor: "#48c0ff"}}>
        <p style={{
            textAlign: "center", 

        }}>
            <b>Preencha os dados do cartão de crédito e o endereço de cobrança:<br/></b>
            <i style={{fontSize: '14px'}}>Cobrança segura intermediada pela Pagar.me - Stone</i>
        </p>
        <div className="form form-card-update">
            {cardEditForm
                .map(({ name, label, required }, index) => (
                <TextField
                    key={index}
                    name={name}
                    required={required}
                    value={planCardData[name] ? planCardData[name] : ''}
                    className={`input-${name}`}
                    label={label}
                    color="secondary"
                    variant="outlined"
                    onChange={({ target }) => handleChange(target)}
                    >
                </TextField>
                ))}
        </div>
    </div>
);
}
export default AddCardStep;
