import {addUserDataRequired, addCompanyDataRequired} from './registerFields';


export function isValidPass(confirmPassword){
    const { password, password_confirmation } = confirmPassword;
    if ((password === password_confirmation) && (password_confirmation !== '') && password_confirmation && password){
      return true
    }
    return false
  }

export function isValidUserForm(editUserForm){
  for (let i = 0; i < addUserDataRequired.length; i++) {
    const item = addUserDataRequired[i];
    if (item in editUserForm === false || editUserForm[item] === '' ){
      return false
    }
  } 
  return true
};

export function isValidCompanyForm(editCompanyForm){
  for (let i = 0; i < addCompanyDataRequired.length; i++) {
    const item = addCompanyDataRequired[i];
    if (item in editCompanyForm === false || editCompanyForm[item] === '' ){
      return false
    }
  } 
  return true
};
