import React, { useContext, useState, useEffect } from 'react';
import Context from '../../../../context/Context';
import { FormControl, Autocomplete, TextField } from '@mui/material';
import { getOperationsList } from '../../../../services/operations';

function SelectOperation() {
  const { setInvoiceData } = useContext(Context);
  const { setAlert } = useContext(Context);
  const [operationsData, setOperationsData] = useState([]);

  const session_token = localStorage.getItem('session_token');

  const OperationList = (filter) =>
    getOperationsList(filter, session_token)
      .then(({ data }) => data)
      .catch((err) => setAlert(ExceptionsHandler(err)));

  const operationListHandler = async () => {
    const { operations } = await OperationList({ is_closed: true });
    setOperationsData(operations);
  };

  useEffect(() => {
    operationListHandler();
  }, []);

  return (
    <div style={{marginBottom:"35px"}}>
      <FormControl className="input-operation_id">
        <Autocomplete
          disablePortal
          options={operationsData.map((operation) => ({
            id: operation.id,
            label: operation.or_tag
          }))}
          id="operation_id"
          name="operation_id"
          renderInput={(params) => (
            <TextField {...params} label="Centro de Custo" required />
            )}
            onChange={(event, value) => {
              if (value) {
                setInvoiceData((prev) => ({ ...prev, operation_id: value.id }));
            }
          }}
          isOptionEqualToValue={(option, value) => option.id === value.id}
          clearOnEscape
          clearText="Limpar"
          sx={{marginBottom:"20px"}}
          />
      </FormControl>
      <b>Note: apenas os centros de custos fechados aparecerão aqui.</b>
    </div>
  );
}
export default SelectOperation;
