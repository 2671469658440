import React, { useContext, useState } from 'react';
import PropTypes from 'prop-types';
import Context from '../../../context/Context';
import {
  Tooltip,
  Button,
  IconButton,
  MenuItem,
  Select,
  InputLabel,
  FormControl,
  TextField
} from '@mui/material';
import { Dialog, DialogActions, DialogContent, DialogTitle } from '@mui/material';
import CloseIcon from '@mui/icons-material/Close';
import { customMask, formatObject } from '../../../utils/helpers';
import { sendSignPlan } from '../../../services/user';


function PlanRegisterDialog({ valueExpenses, valueUser, selectedPlan }) {
  const { setAlert } = useContext(Context);
  const { registerDialog, setRegisterDialog } = useContext(Context);
  const [signPlanRegister, setSignPlanRegister] = useState({
                                                    "expenses_number":valueExpenses , 
                                                    "users_number": valueUser, 
                                                    "months": selectedPlan});
 
  const handleClose = () => {
    setSignPlanRegister({});
    setRegisterDialog(false);
  };

  const handleChange = ({ name, value }) => {
    setSignPlanRegister({ ...signPlanRegister, [name]: customMask(name, value) });
  };

  const isDisabled = () => {
    const {company_name, contact_mode, person_name, phone, email} = signPlanRegister
    if (contact_mode === "Telefone"){
      if (company_name && contact_mode && person_name && phone && email) return false;
    } else {
      if (company_name && contact_mode && person_name && email) return false;
    }
    return true;
  };

  const sendSignPlanHandler = () => {
    const signPlan = formatObject(signPlanRegister);
    sendSignPlan(signPlan)
      .then(async (response) => {
        setAlert({
          state: true,
          message: response.data.message,
          type: 'success',
          header: 'Tudo certo!'
        });
        handleClose();
      })
      .catch((err) => {
        setAlert(ExceptionsHandler(err));
      });
  };

  return (
    <>
      <Dialog open={registerDialog} onClose={handleClose} sx={{zIndex: '1202'}}>
        <DialogTitle>
          <b>Bem vindo a Suppi+ Gestão de Despesas!</b>
          <Tooltip title="Fechar">
            <IconButton
              color="secondary"
              onClick={handleClose}
              sx={{ position: 'absolute', top: 0, right: 0 }}>
              <CloseIcon />
            </IconButton>
          </Tooltip>
        </DialogTitle>
        <DialogContent style={{
          textAlign: "center",
        }}>
          <div>
            Você selecionou o plano <b>Suppi+ {selectedPlan}</b> para <b>{valueUser} usuários </b>
            e até <b>{valueExpenses} despesas mensais</b>. Preencha as informações abaixo e 
            em breve entraremos em contato para iniciar a integração com sua empresa.<br/>
            Qualquer dúvida fale com a gente: contato@suppimais.com.br
          </div>
           <div className="form form-sign-plan" style={{paddingTop: "15px"}}>
            <FormControl className="input-company_name">
              <TextField
                label="Nome da Empresa *"
                name="company_name"
                multiline
                value={signPlanRegister.company_name || ''}
                onChange={({ target }) => handleChange(target)}
              />
            </FormControl>
            <FormControl className="input-contact_mode">
              <InputLabel id="contact_mode-label">Como contacta-lô? *</InputLabel>
              <Select
                labelId="contact_mode-label"
                name="contact_mode"
                required
                value={signPlanRegister.contact_mode || ''}
                label="Como contacta-lô?"
                onChange={({ target }) => handleChange(target)}
                >
                <MenuItem value="Telefone">Telefone</MenuItem>
                <MenuItem value="Email">Email</MenuItem>
              </Select>
            </FormControl>
            <FormControl className="input-person_name">
              <TextField
                label="Seu Nome *"
                name="person_name"
                multiline
                value={signPlanRegister.person_name || ''}
                onChange={({ target }) => handleChange(target)}
              />
            </FormControl>
            <FormControl className="input-phone">
              <TextField
                label="Telefone de Contato"
                name="phone"
                multiline
                value={signPlanRegister.phone || ''}
                onChange={({ target }) => handleChange(target)}
              />
            </FormControl>
            <FormControl className="input-email">
              <TextField
                label="Email de Contato *"
                name="email"
                multiline
                value={signPlanRegister.email || ''}
                onChange={({ target }) => handleChange(target)}
              />
            </FormControl>
            
        </div>
        </DialogContent>
        <DialogActions sx={{ justifyContent: 'space-between' }}>
          <Button disabled={isDisabled()} onClick={sendSignPlanHandler}>Solicitar Assinatura</Button>
        </DialogActions>
      </Dialog>
    </>
  );
}

PlanRegisterDialog.propTypes = {
  valueUser: PropTypes.number,
  valueExpenses: PropTypes.number,
  selectedPlan: PropTypes.number,
};

export default PlanRegisterDialog;
