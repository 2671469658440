import React, { useContext, useState, useEffect } from 'react';
import Context from '../../../context/Context';
import {
  TextField,
  Tooltip,
  Button,
  FormControl,
  Autocomplete,
  IconButton
} from '@mui/material';
import { Dialog, DialogActions, DialogContent, DialogTitle } from '@mui/material';
import { operationForm } from '../../../utils/registerFields';
import CloseIcon from '@mui/icons-material/Close';
import { customMask, formatObject } from '../../../utils/helpers';
import { ExceptionsHandler } from '../../../services/exceptions';
import { createOperation } from '../../../services/operations';
import { getClientsList } from '../../../services/clients';


function OperationsRegisterDialog() {
  const { setAlert } = useContext(Context);
  const [operationRegister, setOperationRegister] = useState({});
  const { registerDialog, setRegisterDialog } = useContext(Context);
  const [clientsData, setClientsData] = useState([]);
  const [registerButtonState, setRegisterButtonState] = useState(false);
  const { setRefresh } = useContext(Context);
  const session_token = localStorage.getItem('session_token');

  const ClientsList = () =>
  getClientsList(session_token)
    .then(({ data }) => data)
    .catch((err) => setAlert(ExceptionsHandler(err)));

  const clientsListHandler = async () => {
    const { clients } = await ClientsList();
    setClientsData(clients);
  };

  const handleClose = () => {
    clearFields();
    setRegisterDialog(false);
  };

  const createOperationHandler = () => {
    const operation = formatObject(operationRegister);
    if (operation.or_name) {
      setRegisterButtonState(true);
      createOperation(operation, session_token)
        .then(() => {
          setAlert({
            state: true,
            message: 'Centro de Custo foi cadastrado com sucesso!',
            type: 'success',
            header: 'Deu certo!'
          });
          setRegisterButtonState(false);
          setRefresh(true);
          handleClose();
        })
        .catch((err) => {
          setAlert(ExceptionsHandler(err));
        });
    }
  };

  const clearFields = () => {
    setOperationRegister({});
  };

  const handleChange = ({ name, value }) => {
    setOperationRegister({ ...operationRegister, [name]: customMask(name, value) });
  };

  useEffect(() => {
    clientsListHandler();
  }, []);

  return (
    <>
      <Dialog open={registerDialog} onClose={handleClose} sx={{zIndex: '1202'}}>
        <DialogTitle>
          Cadastrar Centro de Custo
          <Tooltip title="Fechar">
            <IconButton
              color="secondary"
              onClick={handleClose}
              sx={{ position: 'absolute', top: 0, right: 0 }}>
              <CloseIcon />
            </IconButton>
          </Tooltip>
        </DialogTitle>
        <DialogContent style={{ padding: '20px' }}>
        <div className="form form-operation">
            {operationForm
              .filter(({ name }) => ['or_name'].includes(name))
              .map(({ name, label, required }, index) => (
                <TextField
                  key={index}
                  name={name}
                  value={operationRegister[name] || ''}
                  className={`input-${name}`}
                  label={label}
                  required={required}
                  onChange={({ target }) => handleChange(target)}
                />
              ))}
            <FormControl className="input-client_name">
              <Autocomplete
                disablePortal
                options={clientsData.map((client) => ({id: client.id, label: client.name}))}
                id="clients_id"
                name="clients_id"
                renderInput={(params) => <TextField {...params} label="Cliente" required />}
                onChange={(event, value) => handleChange({ name: 'clients_id', value: value?.id })}
                isOptionEqualToValue={(option, value) => option.id === value.id}
                clearOnEscape
                clearText='Limpar'
              />
            </FormControl>
        </div>
        </DialogContent>
        <DialogActions sx={{ justifyContent: 'space-between' }}>
          <Button variant="outlined" onClick={clearFields}>Limpar</Button>
          <Button disabled={registerButtonState} onClick={createOperationHandler}>Registrar</Button>
        </DialogActions>
      </Dialog>
    </>
  );
}

export default OperationsRegisterDialog;
