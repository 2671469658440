import React, { useContext } from 'react';
import Context from '../../../../context/Context';
import { getDisccount } from '../../../../utils/helpers';

function ConfirmPlanStep() {    
    const { planData, planCardData } = useContext(Context);
    
    const getPlanFinalValue = () => {
        if ( planData.paid_value !== undefined){
            return (planData.paid_value*getDisccount(planData.plan)).toFixed(2)
        }
        return 0.00

    };

    return (
    <div style={{
        borderStyle: "solid", 
        padding: "0px 10px 20px 10px", 
        borderWidth: "1.5px",
        borderRadius: "10px",
        borderColor: "#48c0ff"}}>
        <p style={{
            textAlign: "center"
        }}>
            <b>Prosseguir com a assinatura?<br/></b>
        </p>
            <b>Cobrança</b>: Mensal no cartão de crédito ****{planCardData.card_number.substr(-4,4)} <br/>
            <b>Período do Plano</b>: {planData.plan}<br/>
            <b>Número de usuários</b>: {planData.allowed_accounts}<br/>
            <b>Número de despesas</b>: {planData.allowed_monthly_rb} <br/>
            <b>Valor mensal</b>: R$ {planData.paid_value} 
            <i style={{color: "#2400b4"}}> - {Math.abs((getDisccount(planData.plan))*100-100)}%
            </i> = <b style={{color: "green"}}>
                R$ {getPlanFinalValue()}
            </b><br/>
    </div>
);
}
export default ConfirmPlanStep;
