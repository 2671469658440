import React from 'react';
import NavBar from '../components/NavBar';
import TermsPage from '../components/pages/TermsPage';

function Terms() {

  return (
    <>
      <NavBar login/>
      <TermsPage/>
    </>
  )
};

export default Terms;