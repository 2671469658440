import React from 'react';
import { Grid } from '@mui/material';
import dashboard_img from '../../../assets/images/homepage/dashboard.png'
import mine_reimbursement_screen_img from '../../../assets/images/homepage/mine_reimbursement_screen.png'
import generate_invoice_screen_img from '../../../assets/images/homepage/generate_invoice_screen.png'

function DashboardSection() {


return (
    <div>
        <Grid container  style={{ 
            backgroundColor: '#2400b4',
            textAlign: 'center', 
            padding: '25px',
            color: 'white', 
            display: 'flex',
            justifyContent: 'center',
            alignItems: 'center',
        }}>
            <Grid item sm={0} md={.5} style={{padding: "30px 10px 30px 5px"}}></Grid>
            <Grid item sm={12} md={6} style={{padding: "30px 10px 30px 5px"}}>
                <div>
                    <h1>
                        Sua equipe cadastra solicitações de reembolso ou de pagamento de maneira simples e prática.
                    </h1>
                </div>
            </Grid>
            <Grid item sm={12} md={5} style={{padding: "30px 10px 30px 5px"}}>
                <div>
                    <img
                    src={mine_reimbursement_screen_img}
                    alt="logo_suppi_mini"
                    width="100%"
                    style={{
                        borderRadius: "4%",
                        maxWidth: "450px",
                        transform: "rotate(-2deg)",
                        boxShadow: "4px 14px 18px 6px rgba(0, 0, 0, 0.4)"
                    }}/>
                </div>
            </Grid>
            <Grid item sm={0} md={.5} style={{padding: "30px 10px 30px 5px"}}></Grid>

            <Grid item sm={0} md={.5} style={{padding: "30px 10px 30px 5px"}}></Grid>
            <Grid item sm={0} md={5} sx={{padding: "30px 0 30px 0", display: { xs: 'none', sm: 'none', md: 'block' }}}>
                <div>
                    <img
                    src={generate_invoice_screen_img}
                    alt="logo_suppi_mini"
                    width="100%"
                    style={{
                        borderRadius: "4%",
                        maxWidth: "450px",
                        transform: "rotate(2deg)",
                        boxShadow: "4px 14px 18px 6px rgba(0, 0, 0, 0.4)"
                    }}/>
                </div>
            </Grid>
            <Grid item sm={12} md={6} style={{padding: "30px 10px 30px 5px"}}>
                <div>
                    <h1>Gere faturas personalizadas com todas as despesas e comprovantes em poucos segundos.</h1>
                </div>
            </Grid>
            <Grid item sm={12} md={0} sx={{padding: "30px 0 30px 0", display: { xs: 'block', sm: 'block', md: 'none' }}}>
                <div>
                    <img
                    src={generate_invoice_screen_img}
                    alt="logo_suppi_mini"
                    width="100%"
                    style={{
                        borderRadius: "4%",
                        maxWidth: "450px",
                        transform: "rotate(2deg)",
                        boxShadow: "4px 14px 18px 6px rgba(0, 0, 0, 0.4)"
                    }}/>
                </div>
            </Grid>
            <Grid item sm={0} md={.5} style={{padding: "30px 10px 30px 5px"}}></Grid>

            <Grid item sm={0} md={.5} style={{padding: "30px 10px 30px 5px"}}></Grid>
            <Grid item sm={12} md={6} style={{padding: "30px 10px 30px 5px"}}>
                <div>
                    <h1>Tenha acesso a um dashboard gráfico completo para acompanhar e entender suas operações.</h1>
                </div>
            </Grid>
            <Grid item sm={12} md={5} style={{padding: "30px 10px 30px 5px"}}>
                <div>
                    <img
                    src={dashboard_img}
                    alt="logo_suppi_mini"
                    width="100%"
                    style={{
                        borderRadius: "4%",
                        maxWidth: "450px",
                        transform: "rotate(-2deg)",
                        boxShadow: "4px 14px 18px 6px rgba(0, 0, 0, 0.4)"
                    }}/>
                </div>
            </Grid>
            <Grid item sm={0} md={.5} style={{padding: "30px 10px 30px 5px"}}></Grid>
        </Grid>
    </div>
    );
}

export default DashboardSection;