import React, { useContext, useState, useEffect } from 'react';
import Context from '../../../context/Context';
import { Button, CardActions, TextField } from '@mui/material';
import { Card, CardHeader, CardContent } from '@mui/material';
import { getUser, updateUser, changePassword, getLogoUrl, postLogoUrl } from '../../../services/user';
import { userForm, companyForm, changePasswordForm } from '../../../utils/registerFields';
import { currencyMask, customMask, formatObject, handleClickOpenFile } from '../../../utils/helpers';
import { ExceptionsHandler } from '../../../services/exceptions';
import { sendS3File } from '../../../services/client';
import { getProduct } from '../../../services/product';
import EditCardOrAddPlanFormDialog from '../../dialogs/planDialog/EditCardOrAddPlanFormDialog';

function User() {
  const { setAlert } = useContext(Context);
  const [editUserForm, setEditUserForm] = useState();
  const [editCompanyForm, setEditCompanyForm] = useState();
  const [dialogMode, setDialogMode] = useState();
  const { dialog, setDialog } = useContext(Context);
  const [editProductInfo, setProductInfo] = useState({});
  const [editUser, setEditUser] = useState(true);
  const [editPasswordForm, setEditPasswordForm] = useState(true);
  const [editCompany, setEditCompany] = useState(true);
  const [logoFile, setLogoFile] = useState(null);
  const { refresh, setRefresh } = useContext(Context);

  const session_token = localStorage.getItem('session_token');
  const is_admin = localStorage.getItem('is_admin') === 'true';
  const is_main_user = localStorage.getItem('is_main_user') === 'true';

  // Controle do logo -----------------------------------------------
  const GetLogoUrlHandle = () => 
  getLogoUrl(session_token)
  .then(({ data }) => data)
    .catch((err) => setAlert(ExceptionsHandler(err)));
  
  const getS3UrlLogo = async (value) => {
    const data = await GetLogoUrlHandle(value);
    const { file_url } = data;
    handleClickOpenFile(file_url);
    return file_url
  };

  const LoadUserData = async () => {
    getUser(session_token)
      .then(({ data }) => {
        setEditCompanyForm(data.company);
        setEditUserForm(data);
      })
      .catch((err) => {
        setAlert(ExceptionsHandler(err));
      });
      if (is_main_user){
        getProduct(session_token)
        .then(({ data }) => {
          setProductInfo(data);
        })
        .catch((err) => {
          setAlert(ExceptionsHandler(err));
        });
      }
    };

  const handleFile = (e) => {
    const file = e.target.files[0];
    file['dispotition'] = 'attachment';
    const file_type = file.type.split('/')[1];
    const valid_file_types = ['png', 'jpg', 'jpeg', 'heic', 'webp'];
    if (valid_file_types.includes(file_type)) {
        setLogoFile(file);
    } else {
      setAlert({
        state: true,
        message: "Infelizmente não aceitamos o formato escolhido, por favor escolha um arquivo desses formatos: " + valid_file_types,
        type: 'error',
        header: 'Ops, formato de arquivo inválido!'
      });
    } 
  };

  const createLogoFileHandler = async (id) => {
    const file_info = { extension: logoFile.type.split('/')[1] };
    const data = await postLogoUrl(file_info, session_token);
    const { url, fields } = data.data;
    fields.file = logoFile;
    var s3FormData = new FormData();
    for (var key in fields) {
      s3FormData.append(key, fields[key]);
    }
    sendS3File(url, s3FormData);
    setLogoFile(null);
  };
  //-----------------------------------------------------------------

  // Controle de requisições de atualização -------------------------
  const handleEditUser = () => {
    const user = formatObject(editUserForm);
    const company = formatObject(editCompanyForm);
    const obj = { ...user, company };
    updateUser(obj, session_token)
      .then(async () => {
        if (logoFile) await createLogoFileHandler();
        setAlert({
          state: true,
          message: 'Informação foi atualizada!',
          type: 'success',
          header: 'Pronto!'
        });
        setEditUser(true);
        setEditCompany(true);
      })
      .catch((err) => {
        setAlert(ExceptionsHandler(err));
      });
  };

  const handleEditPassword = () => {
    const { old_password, new_password, new_password_confirmation } = editPasswordForm;
    if (new_password === new_password_confirmation && new_password !== '' && old_password !== '') {
      changePassword(
        {
          old_password: old_password,
          new_password: new_password
        },
        session_token
      )
        .then(() => {
          setAlert({
            state: true,
            message: 'Sua senha foi alterada!',
            type: 'success',
            header: 'Pronto!'
          });
        })
        .catch((err) => {
          setAlert(ExceptionsHandler(err));
        });
    } else {
      setAlert({
        state: true,
        type: 'error',
        header: 'Ops, tivemos um probleminha!',
        message: 'As senhas digitadas não conferem.'
      });
    }
  };
  //-----------------------------------------------------------------
  
  // Variáveis de controle de edição 
  const handleClickEditCompany = () => {
    setEditCompany(!editCompany);
  };

  const handleClickEditUser = () => {
    setEditUser(!editUser);
  };
  
  const handleEditPasswordForm = ({ name, value }) => {
    setEditPasswordForm({ ...editPasswordForm, [name]: customMask(name, value) });
  };
  //-----------------------------------------------------------------
  
  // Controle dos payloads de atualização
  const handleUserChange = ({ name, value }) => {
    setEditUserForm({ ...editUserForm, [name]: customMask(name, value) });
  };

  const handleCompanyChange = ({ name, value }) => {
    setEditCompanyForm({ ...editCompanyForm, [name]: customMask(name, value) });
  };
  //-----------------------------------------------------------------
  const getDiscount = (value) => {
    if (value == 1){
      return "0%"
    }
    if (value == 3){
      return "10%"
    }
    if (value == 12){
      return "15%"
    }
  }

  const CustomCardActions = (handleClickEdit, editControl) => (
    <CardActions className="card-actions">
      <Button onClick={handleClickEdit}>Editar</Button>
      <Button onClick={handleEditUser} disabled={editControl}>
        Salvar
      </Button>
    </CardActions>
  );

  useEffect(() => {
    if (refresh === true) {
      LoadUserData();
      setRefresh(false);
    }
  }, [refresh]);

  useEffect(() => {
    LoadUserData();
  }, []);

  return (
    <div className="page-content">
      {dialog && <EditCardOrAddPlanFormDialog variant={dialogMode}/>}
      {is_main_user ? (
        <Card className="card">
          <CardHeader className="card-header" title="Dados do plano" />
          <CardContent className="card-body">
            <div className="plan-info">
              <p style={{textAlign: 'center'}}>
                <b>Desconto:</b> {editProductInfo.product ? getDiscount(editProductInfo.product.plan) : "--"}
                <br/>
                <b>Fidelidade até:</b> {editProductInfo.product ? customMask("bond_dt" ,editProductInfo.product.bond_dt) : "--"}
                <br/>
                <b>Valor c/ desconto:</b> {editProductInfo.product ? currencyMask(editProductInfo.product.paid_value/100) : "--"} ao mês
                <br/>
                <b>Quantidade de usuários:
                </b> {editProductInfo.product ? editProductInfo.product.actual_accounts : "-"} de {editProductInfo.product ? editProductInfo.product.allowed_accounts : "-"}
                <br/>
                <b>Quantidade de despesas do mês:
                </b> {editProductInfo.product ? editProductInfo.product.actual_monthly_rb : "--"} de {editProductInfo.product ? editProductInfo.product.allowed_monthly_rb : "--"}
                <br/>
                <b>Cartão de Crédito: 
                </b> {editProductInfo.card ? editProductInfo.card.brand : "--"}{editProductInfo.card ? " ****"+editProductInfo.card.last_four_digits : "--"}
                <br/>
                Ainda não liberamos atualizar/cancelar seu plano por aqui.
                <br/>
                Solicite a alteração via email para financeiro@suppimais.com.br
                <br/>
                {!(editProductInfo.product && editProductInfo.product.subscription_active)? 
                  (<b style={{color: "red"}}>Plano inativo</b>) : ""}
              </p>
            </div>
          </CardContent>
          <CardActions className="card-actions">
            <Button 
              variant='outlined' 
              onClick={() => {
                setDialogMode("edit");
                setDialog(true);
              }}
              disabled={!(editProductInfo.product && editProductInfo.product.subscription_active && editProductInfo.card)}
            >
              Editar Cartão
            </Button>
            {editProductInfo.product && editProductInfo.product.subscription_active ? 
              (
                <Button variant='delete' disabled={true}>
                  Cancelar Plano
                </Button>
              ):
              (
                <Button disabled={!(editProductInfo.product)} onClick={() => {
                  setDialogMode("add");
                  setDialog(true);
                }}>
                  Assinar Plano
                </Button>
              )
            }
          </CardActions>
        </Card>
      ) : null}
      <Card className="card">
        <CardHeader className="card-header" title="Dados do Usuário" />
        <CardContent className="card-body">
          <div className="form form-user">
            {userForm.map(({ name, label }, index) => (
              <TextField
                key={index}
                name={name}
                disabled={editUser || name === 'email'}
                defaultValue={editUserForm ? customMask(name, editUserForm[name]) : undefined}
                value={editUserForm ? customMask(name, editUserForm[name]) : ''}
                className={`input-${name}`}
                label={label}
                onChange={({ target }) => handleUserChange(target)}
              />
            ))}
          </div>
        </CardContent>
        {CustomCardActions(handleClickEditUser, editUser)}
      </Card>
      {is_admin ? (
        <Card className="card">
          <CardHeader className="card-header" title="Dados da Empresa" />
          <CardContent className="card-body">
            <div className="form form-company">
              {companyForm.map(({ name, label }, index) => (
                <TextField
                  key={index}
                  defaultValue={editUserForm ? customMask(name, editCompanyForm[name]) : undefined}
                  value={editUserForm ? customMask(name, editCompanyForm[name]) : ''}
                  disabled={editCompany}
                  onChange={({ target }) => handleCompanyChange(target)}
                  className={`input-${name}`}
                  name={name}
                  label={label}
                />
              ))}
                <label htmlFor="raised-button-file">
                  <input
                    accept=".png, .jpg, .jpeg, .heic, .webp"
                    style={{ display: 'none' }}
                    id="raised-button-file"
                    type="file"
                    disabled={editCompany}
                    onChange={handleFile}
                  />
                    <TextField
                      className="input-file"
                      component="label"
                      label="Atualizar Logo"
                      value={logoFile ? logoFile.name : ''}
                      sx={{ width: '100%', pointerEvents: 'none' }}
                    />
                </label>
                <Button 
                  variant="outlined" 
                  disabled={logoFile !== null} 
                  onClick={getS3UrlLogo}>
                    {logoFile? "Salve p/ atualizar" : "Baixar Logo"}
                </Button>
            </div>
          </CardContent>
          {CustomCardActions(handleClickEditCompany, editCompany)}
        </Card>
      ) : null}
      <Card className="card">
        <CardHeader className="card-header" title="Alterar Senha" />
        <CardContent className="card-body">
          <div className="form form-change_password">
            {changePasswordForm.map(({ name, label, required, type }, index) => (
              <TextField
                key={name}
                name={name}
                type={type}
                required={required}
                className={`input-${name}`}
                label={label}
                onChange={({ target }) => handleEditPasswordForm(target)}
              />
            ))}
          </div>
        </CardContent>
        <CardActions className="card-actions">
          <Button onClick={handleEditPassword}>Salvar</Button>
        </CardActions>
      </Card>
    </div>
  );
}

export default User;
