import React, { useContext, useState, useEffect, useRef } from 'react';
import PropTypes from 'prop-types';
import Context from '../../context/Context';
import { Button, TextField, Link, DialogActions } from '@mui/material';
import { Dialog, DialogContent, DialogTitle } from '@mui/material';
import { forgotPassword, validateCode, newPassword } from '../../services/auth';
import { ExceptionsHandler } from '../../services/exceptions';
import { ValidatePassword } from '../../utils/validates';
import { passwordForm } from '../../utils/registerFields';

function ForgotPasswordDialog(email) {
  const { setAlert } = useContext(Context);
  const { dialog, setDialog } = useContext(Context);
  const [data, setData] = useState({});
  const [changePassword, setChangePassword] = useState(false);

  const forgotPasswordHandler = () => {
    const { email } = data;
    forgotPassword({ email: email })
      .then((res) => {
        setAlert({ state: true, message: res.data.message, type: 'success', header: 'Deu certo!' });
      })
      .catch((err) => {
        setAlert(ExceptionsHandler(err));
      });
  };

  const validateCodeHandler = () => {
    const { email, code } = data;
    validateCode({ email: email, code: code })
      .then(() => {
        setChangePassword(true);
      })
      .catch((err) => {
        setAlert(ExceptionsHandler(err));
        setData({ ...data, code: '······' });
      });
  };

  const newPasswordHandler = () => {
    const { email, code, password } = data;
    newPassword({ email: email, code: code, password: password })
      .then(({ data }) => {
        setAlert({ state: true, message: data.message, type: 'success', header: 'Deu certo!' });
        handleClose();
      })
      .catch((err) => {
        console.log(err)
        setAlert(ExceptionsHandler(err));
      });
  };

  const handleChange = ({ name, value }) => {
    setData({ ...data, [name]: value });
  };

  const handleClose = () => {
    setData({});
    setChangePassword(false);
    setDialog(false);
  };

  const codeHandler = () => {
    const { code } = data;
    code && code.replace(/[^0-9]/g, '').length === 6 ? validateCodeHandler() : null;
  };

  const InputVerificationCode = () => {
    const [code] = useState(['', '', '', '', '', '']);
    const codeRef = useRef(code);

    const handleChangeCode = ({ name, value }, index) => {
      value = value.replace(/[^0-9]/g, '');
      if (value.length == '') return codeRef.current[index] = '';
      code[index] = value;
      if (index < 5) codeRef.current[index+1].focus();
      else setData({ ...data, code: code.join('') });
    };

    return (
      <div>
        {code.map((item, index) => (
          <TextField
            inputRef={(element) => codeRef.current[index] = element}
            key={index}
            autoFocus={index === 0}
            name={`code${index}`}
            placeholder={'·'}
            onKeyUp={({ target }) => handleChangeCode(target, index)}
            variant="outlined"
            InputProps={{
              inputProps: {
                maxLength: 1,
                pattern: '[0-9]*',
              },
              style: {
                width: '3rem',
                height: '3rem',
                fontSize: '1.5rem',
                margin: '0 0.2rem',
              },
            }}
            sx={{ '& input': { textAlign: 'center' } }}
          />
        ))}
      </div>
    );
  };

  useEffect(() => {
    setData({ ...data, ...email });
  }, [email]);

  useEffect(() => {
    codeHandler();
  }, [data.code]);

  return (
    <>
      <Dialog className='card' open={dialog} onClose={handleClose} sx={{zIndex: '1202'}}>
        <DialogTitle>Digite o código que recebeu por email:</DialogTitle>
        {!changePassword && <DialogContent className="card-body" style={{ padding: "20px" }}>
          <div className="form form-forgot_password">
            <InputVerificationCode />
            <div className='input-new_code'>
              <span>Não possuo um </span>
              <Link onClick={() => forgotPasswordHandler()} href="#" >Código de Verificação</Link>
              <span>.</span>
            </div>
          </div>
        </DialogContent>}
        {changePassword && <>
          <DialogContent className="card-body" style={{ padding: "20px" }}>
            <div className="form form-password">
            {passwordForm.map(({ name, label, required, type }, index) => (
              <TextField
                key={index}
                name={name}
                type={type}
                required={required}
                className={`input-${name}`}
                label={label}
                onChange={({ target }) => handleChange(target)}
              />
            ))}
          </div>
          </DialogContent>
          <DialogActions
            style={{ padding: "0 20px 10px" }}
          >
            <Button
              className="action"
              disabled={!ValidatePassword(data)}
              onClick={newPasswordHandler}
            >Cadastrar Nova Senha</Button>
          </DialogActions>
        </>}
      </Dialog>
    </>
  );
};

ForgotPasswordDialog.propTypes = {
  email: PropTypes.string,
};

export default ForgotPasswordDialog;