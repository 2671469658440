import React, { useState } from 'react';
import PropTypes from 'prop-types';
import Context from './Context';
import { useNavigate } from 'react-router';

const Provider = ({ children }) => {
  const [ dialog, setDialog ] = useState(false);
  const [ registerDialog, setRegisterDialog ] = useState(false);
  const [ confirmeDialog, setConfirmeDialog ] = useState(false);
  const [ confirmeDialogSecond, setConfirmeDialogSecond ] = useState(false);
  const [ sessionData, setSessionData ] = useState({});
  const [ invoiceData, setInvoiceData ] = useState({print_all: false, attach_files: false});
  const [ planCardData, setPlanCardData  ] = useState({});
  const [ planData, setPlanData  ] = useState({});
  const [ alert, setAlert ] = useState({ state: false });
  const [ drawer, setDrawer ] = useState(false);
  const [ refresh, setRefresh ] = useState(false);
  const navigate = useNavigate();

  Object.entries(sessionData).forEach(([key, value]) => {
    localStorage.setItem(key, value);
    setSessionData({})
  });
    
  const toggleDrawer = (drawer) => (event) => {
    if (event.type === 'keydown' && (event.key === 'Tab' || event.key === 'Shift')) {
      return;
    }
    setDrawer(drawer);
  };

  const states = {
    navigate,
    sessionData, setSessionData,
    alert, setAlert,
    drawer, toggleDrawer,
    dialog, setDialog,
    refresh, setRefresh,
    confirmeDialog, setConfirmeDialog,
    confirmeDialogSecond, setConfirmeDialogSecond,
    registerDialog, setRegisterDialog,
    invoiceData, setInvoiceData,
    planCardData, setPlanCardData,
    planData, setPlanData,
  };

  return (
    <Context.Provider value={states}>
      {children}
    </Context.Provider>
  );
}

Provider.propTypes = {
  children: PropTypes.oneOfType([
    PropTypes.arrayOf(PropTypes.node),
    PropTypes.node,
  ]).isRequired,
};

export default Provider;