import { allDataRequired } from "./registerFields";

export function ValidateForm (userRegister, companyRegister) {
    const userValidation = Object.entries(userRegister).filter((key) => key[1] !== '' && allDataRequired.includes(key[0])).map((key) => key[0]);
    const companyValidation = Object.keys(companyRegister).filter((value) => value !== '');
    const response = allDataRequired.every((value) => [...userValidation, ...companyValidation].includes(value));
    return response;
};

export function ValidatePassword ({password, password_confirmation}) {
    return password && password_confirmation && password === password_confirmation;
};
