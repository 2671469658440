import React, { useContext, useState, useEffect } from 'react';
import { Card, CardHeader, CardContent, TextField, Grid, Button } from '@mui/material';
import Context from '../../../context/Context';
import { DataGrid, ptBR as dataGridLocale } from '@mui/x-data-grid';
import { customMask } from '../../../utils/helpers';
import { debtsUserForm } from '../../../utils/registerFields';
import { CustomPagination } from '../../../utils/customGrid';
import { ExceptionsHandler } from '../../../services/exceptions';
import { getUsersDebts } from '../../../services/dashboards';
import CollaboratorsDebtDialog from '../../dialogs/collaboratorsDialog/CollaboratorsDebtDialog';
import { CleaningServices, FilterAlt, FilterAltOff } from '@mui/icons-material';

function CollaboratorsDebts() {
  const { setAlert } = useContext(Context);
  const [allCollaboratorsDebtData, setAllCollaboratorsDebtData] = useState([]);
  const { setConfirmeDialogSecond } = useContext(Context);
  const [editRow, setEditRow] = useState({});
  const { refresh, setRefresh } = useContext(Context);
  const [filter, setFilter] = useState({});
  const [page, setPage] = React.useState(0);
  const [loading, setLoading] = useState(false);  
  // Filter section vars
  const [ filterSection, setFilterSection ] = useState(false);

  const session_token = localStorage.getItem('session_token');

  const handleValues = (params) => {
    const { field, value } = params;
    return customMask(field, value);
  };

  const DebtsUsersColumns = debtsUserForm.map(({ name, label, width, align, type, hiden }) => ({
    field: name,
    headerName: label,
    flex: width,
    minWidth: 100 * width,
    align: align,
    headerAlign: align,
    type: type,
    hide: hiden,
    filterable: false,
    sortable: false,
    valueGetter: (params) => handleValues(params)
  }));

  const AllDebtsUsersList = (filter) =>
    getUsersDebts(filter, session_token)
      .then(({ data }) => data)
      .catch((err) => setAlert(ExceptionsHandler(err)));

  const allDebtsUsersListHandler = async (method) => {
    setLoading(true);
    const prevPage = allCollaboratorsDebtData.actual_page;
    const data = await AllDebtsUsersList({ ...filter, page: page + 1 });
    const { users } = data;
    if (method === 'add') {
      let newDebtsUsers = [];
      for (let i = prevPage; i < page; i++) {
        const remainingData = await AllDebtsUsersList({ ...filter, page: i + 1 });
        newDebtsUsers.push(remainingData.users);
      }
      newDebtsUsers.push(users);
      setAllCollaboratorsDebtData({
        ...data,
        users: allCollaboratorsDebtData.users.concat(newDebtsUsers.flat())
      });
    } else setAllCollaboratorsDebtData(data);
    setLoading(false);
  };

  const handleDialogDebts = (row) => {
    setEditRow(row);
    setConfirmeDialogSecond(true);
  };

  useEffect(() => {
    if (refresh === true) {
      setPage(0);
      allDebtsUsersListHandler();
      setRefresh(false);
    }
  }, [refresh]);

    //Filter Section Stuff

    const handleAddFilter = () => {
      setFilterSection(!filterSection);
    };
  
    const mountFilterGrid = () => {
      const styleForGrid = {
        paddingBottom: "10px",
        textAlign: 'center',
        display: 'flex',
        justifyContent: 'center',
        alignItems: 'center',
      }
  
      const styleForInputs = {
        width: "100%"
      }
  
      const handleFilterChange = ({ name, value }) => {
        if (value === null || value === ''){
          const filterHelper = filter
          delete filterHelper[name]
          setFilter({...filterHelper})
        }else{
          setFilter({ ...filter, [name]: customMask(name, value) });
        }
      };
  
      const handleFilterClean = () => {
        setFilter({});
        setRefresh(true);
      };
  
      const handleFilterAction = () => {
        setRefresh(true);
      };
      
      return (
        <div style={{paddingBottom: "20px"}}>
          <Grid container>
            <Grid item xs={12}><h3>Filtros:</h3></Grid>
            <Grid item xs={12} sm={4} style={styleForGrid}>
              <TextField
                  label="Colaborador"
                  name="user_name"
                  multiline
                  value={filter.user_name || ''}
                  onChange={({ target }) => handleFilterChange(target)}
                  style={styleForInputs}
                  />
            </Grid>
            <Grid item xs={12} sm={4} style={styleForGrid}>
              <TextField
                  label="Email"
                  name="user_email"
                  multiline
                  value={filter.user_email || ''}
                  onChange={({ target }) => handleFilterChange(target)}
                  style={styleForInputs}
                  />
            </Grid>
            <Grid item xs={12} sm={4} style={styleForGrid}>
              <TextField
                  label="CPF"
                  name="user_cpf"
                  multiline
                  value={filter.user_cpf || ''}
                  onChange={({ target }) => handleFilterChange(target)}
                  style={styleForInputs}
                  />
            </Grid>
            <Grid item xs={12} sm={12} style={styleForGrid}></Grid>
            <Grid item xs={12} sm={6} style={styleForGrid}>
              <Button 
                style={{height: "100%"}} 
                onClick={handleFilterAction}>
                  Filtrar <FilterAlt/>
              </Button>
            </Grid>
            <Grid item xs={12} sm={6} style={styleForGrid}>
              <Button 
                style={{height: "100%"}}  
                variant="outlined" 
                onClick={handleFilterClean}>
                  Limpar <CleaningServices/>
              </Button>
            </Grid>
          </Grid>
        </div>
      )
    }

  useEffect(() => {
    allDebtsUsersListHandler();
  }, []);

  useEffect(() => {
    allCollaboratorsDebtData.has_next && allCollaboratorsDebtData.actual_page <= page
      ? allDebtsUsersListHandler('add')
      : null;
  }, [page]);

  return (
    <div className="page-content">
      <CollaboratorsDebtDialog row={editRow}/>
      <Card className="data-grid-card">
        <CardHeader className="card-header" title="Débitos em Aberto" />
        <CardContent className="card-body">
          <div style={{ width: '100%' }}>
            <div className="data-grid-header">
              <Button 
                style={{width: "auto", marginRight: "5px"}} 
                variant="outlined" 
                onClick={handleAddFilter}>
                  {filterSection ? <FilterAltOff/> : <FilterAlt/>}
                  {Object.keys(filter).length}
              </Button>
            </div>
            {filterSection && mountFilterGrid()}
            <DataGrid
              getRowId={(row) => row.user_id}
              rows={allCollaboratorsDebtData.users || []}
              columns={DebtsUsersColumns}
              pageSize={10}
              rowCount={(allCollaboratorsDebtData.total_pages || 1) * 10}
              page={page}
              autoHeight
              loading={loading}
              disableSelectionOnClick
              onPageChange={(newPage) => (newPage != 'NaN' ? setPage(newPage) : null)}
              onRowDoubleClick={({ row }) => handleDialogDebts(row)}
              localeText={dataGridLocale.components.MuiDataGrid.defaultProps.localeText}
              components={{
                Pagination: CustomPagination
              }}
            />
          </div>
        </CardContent>
      </Card>
    </div>
  );
}

export default CollaboratorsDebts;
