import React from 'react';
import NavBar from '../components/NavBar';
import LoginForm from '../components/pages/LoginPage';

function Login() {
  return (
    <>
      <NavBar login/>
      <LoginForm />
    </>
  )
};

export default Login;