import React from 'react';
import { Grid } from '@mui/material';
import { CloudDone, PictureAsPdf, FilterList, AssignmentTurnedIn, HealthAndSafety, MobileFriendly, InsertChart } from '@mui/icons-material';

function FeaturesSection() {
    const styleForIcon = {
        transform: 'scale(2.5)',
        color: '#2400b4'
    }

    const styleForText = {
        paddingTop: '5px'
    }

    const styleForDiv = {
        margin: '50px'
    }

    const styleForGrid = { 
        backgroundColor: 'white',
        textAlign: 'center',
        padding: '25px', 
        display: 'flex',
        justifyContent: 'center',
        alignItems: 'center',
    }

return (
    <div>
        <Grid container  style={styleForGrid}>
                <Grid item xs={0} sm={.75}></Grid>
                <Grid item xs={12} sm={3.5}>
                    <div style={styleForDiv}>
                        <CloudDone style={styleForIcon}/><br/>
                        <p style={styleForText}>Digitalize suas despesas de reembolsos e facilite o registro e o armazenamento dos seus dados.</p>
                    </div>
                </Grid>
                <Grid item xs={12} sm={3.5}>
                    <div style={styleForDiv}>
                        <PictureAsPdf style={styleForIcon}/><br/>
                        <p style={styleForText}>Gere faturas personalizadas para enviar aos seus clientes. Facilite sua comunicação com outras empresas.</p>
                    </div>
                </Grid>
                <Grid item xs={12} sm={3.5}>
                    <div style={styleForDiv}>
                        <FilterList style={styleForIcon}/><br/>
                        <p style={styleForText}>Organize em um só lugar todas as despesas: separe por categoria, por centro de custo, por cliente e até por período de tempo.</p>
                    </div>
                </Grid>
                <Grid item xs={0} sm={.75}></Grid>
                <Grid item xs={0} sm={.75}></Grid>
                <Grid item xs={12} sm={3.5}>
                    <div style={styleForDiv}>
                        <AssignmentTurnedIn style={styleForIcon}/><br/>
                        <p style={styleForText}>Aprove uma despesa cadastrada pelo seu colaborador de maneira prática e rápida.</p>
                    </div>
                </Grid>
                <Grid item xs={12} sm={3.5}>
                    <div style={styleForDiv}>
                        <HealthAndSafety style={styleForIcon}/><br/>
                        <p style={styleForText}>Fortaleça sua política de compliance e agilize os processos com eficiência.</p>
                    </div>
                </Grid>
                <Grid item xs={12} sm={3.5}>
                    <div style={styleForDiv}>
                        <InsertChart style={styleForIcon}/><br/>
                        <p style={styleForText}>Acompanhe o dia a dia das operações com um dashboard gráfico completo.</p>
                    </div>
                </Grid>
                <Grid item xs={0} sm={.75}></Grid>
                <Grid item xs={12} sm={3.5}>
                    <div style={styleForDiv}>
                        <MobileFriendly style={styleForIcon}/><br/>
                        <p style={styleForText}>Acesse de onde quiser: a plataforma possui versão mobile.</p>
                    </div>
                </Grid>
        </Grid>
    </div>
    );
}

export default FeaturesSection;