import React, { useContext, useState, useEffect } from 'react';
import PropTypes from 'prop-types';
import Context from '../../../context/Context';
import CloseIcon from '@mui/icons-material/Close';
import EditIcon from '@mui/icons-material/Edit';
import SaveIcon from '@mui/icons-material/Save';
import {
    FormControl, IconButton, InputLabel, MenuItem, Select, StyledEngineProvider, TextField,
    Tooltip
} from '@mui/material';
import Dialog from '@mui/material/Dialog';
import DialogActions from '@mui/material/DialogActions';
import DialogContent from '@mui/material/DialogContent';
import DialogTitle from '@mui/material/DialogTitle';
import { ExceptionsHandler } from '../../../services/exceptions';
import DeleteDialog from '../DeleteDialog';

import { sendS3File } from '../../../services/client';
import { putRenegUserStatus, postRenegSendInvoiceS3, postRenegSendInvoiceMail } from '../../../services/reneg';
import { customMask } from '../../../utils/helpers';

function RenegUserChangeStatusDialog({ row }) {
  const { setAlert } = useContext(Context);
  const [contractToUpdate, setContractToUpdate] = useState({charge_id: row.charge_id});
  const { setRefresh } = useContext(Context);
  const { dialog, setDialog } = useContext(Context);
  const { setConfirmeDialog } = useContext(Context);
  const [edit, setEdit] = useState(false);
  const [invoice_file, setInvoiceFile] = useState(null);

  const is_admin = localStorage.getItem('is_admin') === 'true';
  const session_token = localStorage.getItem('session_token');


  const createInvoiceFileHandler = async (file) => {
    const file_info = { charge_id: row.charge_id};
    const data = await postRenegSendInvoiceS3(file_info, session_token);
    const { url, fields } = data.data;
    fields.file = file;
    var s3FormData = new FormData();
    for (var key in fields) {
      s3FormData.append(key, fields[key]);
    }
    setInvoiceFile(file);
    sendS3File(url, s3FormData);
    
  };

  const renegUserChangeStatusHandler = async () => {
    if (contractToUpdate.status === 'renegociado') {
      if (!invoice_file) {
        setAlert({
          state: true,
          type: 'error',
          header: 'Arquivo não informado!',
          message: 'Por favor, anexe o boleto da renegociação.',
        });
        return;
      } else {
        postRenegSendInvoiceMail({ charge_id: row.charge_id }, session_token).then(() => {
          setAlert({
            state: true,
            type: 'success',
            header: 'Deu certo!',
            message: 'Status alterado com sucesso!',
          });
          handleClose();
          setDialog(false);
        })
        .catch((err) => {
          setAlert(ExceptionsHandler(err));
        });

      }
      
    } else {
      putRenegUserStatus(
        {
          charge_id: row.charge_id,
          status: contractToUpdate.status,
        },
        session_token
        )
        .then(() => {
          setAlert({
            state: true,
            type: 'success',
            header: 'Deu certo!',
            message: 'Status alterado com sucesso!',
          });
          handleClose();
          setDialog(false);
        })
        .catch((err) => {
          setAlert(ExceptionsHandler(err));
        });
    }
  };

  const handleFile = (e) => {
    const file = e.target.files[0];
    file['dispotition'] = 'attachment';
    const file_type = file.type.split('/')[1];
    const valid_file_types = ['pdf'];
    if (valid_file_types.includes(file_type)){
      createInvoiceFileHandler(file).then(() => {
        setAlert({
          state: true,
          type: 'success',
          header: 'Deu certo!',
          message: 'Arquivo anexado com sucesso!',
        });
      });
    } else{
      setAlert({
        state: true,
        message: 'Por favor, utilize um dos formatos válidos.',
        type: 'error',
        header: 'Formato de arquivo inválido!'
      });
    }
    };

  const handleChange = (target) => {
    setContractToUpdate({
      ...contractToUpdate,
      [target.name]: target.value,
    });
  };

  const handleConfirme = () => {
    renegUserChangeStatusHandler();
    setDialog(false);
    setRefresh(true);
  };

  const handleClose = () => {
    setDialog(false);
  };

  const handleClickOpen = () => {
    setConfirmeDialog(true);
  };

  const handleEdit = () => {
    setEdit(true);
  };

  return (
    <div>
      <DeleteDialog row={row} option="operation" />
      <Dialog open={dialog} onClose={handleClose} sx={{zIndex: '1202'}}>
        <DialogTitle>
          Editar {row.name}
          <Tooltip title="Fechar">
            <IconButton
              color="secondary"
              onClick={handleClose}
              sx={{ position: 'absolute', top: 0, right: 0 }}>
              <CloseIcon />
            </IconButton>
          </Tooltip>
        </DialogTitle>
        <DialogContent style={{ padding: '20px' }}>
          <div>
            <p>
            <b>Nome:</b> {row.name}<br/>
            <b>Telefone:</b> {customMask('phone', row.phone)}<br/>
            <b>Email:</b> {row.email}<br/>
            <b>CPF:</b> {customMask("cpf", row.cpf)}<br/>
            <b>Valor total dívida:</b> {customMask('valor_total', row.valor_total)}<br/>
            <b>Parcelas Selecionadas:</b> {row.parcelas_selecionadas}<br/>
            <b>Desconto:</b> {customMask('desconto', row.desconto)}<br/>
            <b>Valor Final:</b> {customMask('valor_total', row.final_value)}<br/>
            <b>Valor Parcela Final:</b> {customMask('valor_total', row.final_installments_value)}<br/>
            
            </p>

            <FormControl className="input-type">
              <InputLabel id="type-label">Status</InputLabel>
              <Select
                labelId="type-label"
                name="status"
                value={contractToUpdate.status}
                defaultValue={row.status}
                label="Status"
                onChange={({ target }) => handleChange(target)}
                disabled={!edit}>
                <MenuItem value="em_assinatura">Em Assinatura</MenuItem>
                <MenuItem value="assinado">Assinado</MenuItem>
                <MenuItem value="gerando_boletos">Emitindo Boletos</MenuItem>
                <MenuItem value="renegociado">Renegociado</MenuItem>
                <MenuItem value="recusado">Recusado</MenuItem>
              </Select>
            </FormControl>
            { contractToUpdate.status === 'renegociado' && (
              <>
                <label htmlFor="raised-button-file">
                  <b>Boleto da Renegociação: </b>
                </label>
                <input
                  accept=".pdf, .png, .jpg, .jpeg, .heic, .webp"
                  style={{  marginTop: '10px' }}
                  id="raised-button-file"
                  type="file"
                  onChange={handleFile}
                  ></input>
              </>
            )}
          </div>
        </DialogContent>
        <DialogActions sx={{ justifyContent: 'align-right' }}>
          {is_admin && (
            <>
              {!edit && (
                <Tooltip title="Editar">
                  <IconButton color="secondary" onClick={handleEdit}>
                    <EditIcon />
                  </IconButton>
                </Tooltip>
              )}
              {edit && (
                <Tooltip title="Salvar">
                  <IconButton size="large" color="primary" onClick={renegUserChangeStatusHandler}>
                    <SaveIcon fontSize="inherit" />
                  </IconButton>
                </Tooltip>
              )}
            </>
          )}
        </DialogActions>
      </Dialog>
    </div>
  );
}

RenegUserChangeStatusDialog.propTypes = {
  row: PropTypes.object
};

export default RenegUserChangeStatusDialog;
