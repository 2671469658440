import React from 'react';
import NavBar from '../components/NavBar';
import DashBoard from '../components/pages/PlatformPage';


function Platform() {
  return (
    <>
      <NavBar dashboard/>
      <DashBoard />
    </>
  )
};

export default Platform;