import React, { useContext, useEffect } from 'react';
import PropTypes from 'prop-types';
import { Navigate } from 'react-router-dom';
import Context from '../context/Context';

const RequireAuth = ({ children }) => {
  const { sessionData } = useContext(Context);
  
  const session_token = localStorage.getItem('session_token');

  return (!sessionData || !session_token) ? <Navigate to="/login" /> : children;
};

RequireAuth.propTypes = {
  children: PropTypes.oneOfType([
    PropTypes.arrayOf(PropTypes.node),
    PropTypes.node,
  ]).isRequired,
};

export default RequireAuth;