import React, { useContext, useState, useEffect } from 'react';
import PropTypes from 'prop-types';
import Context from '../../../context/Context';
import {
  Autocomplete,
  TextField,
  Tooltip,
  Select,
  MenuItem,
  InputLabel,
  FormControl,
  Button,
  OutlinedInput,
  IconButton
} from '@mui/material';
import { Dialog, DialogActions, DialogContent, DialogTitle } from '@mui/material';
import CloseIcon from '@mui/icons-material/Close';
import { LocalizationProvider, DatePicker, ptBR as datePickerLocale } from '@mui/x-date-pickers';
import { AdapterDateFns } from '@mui/x-date-pickers/AdapterDateFns';
import { ptBR as ptBRLocale } from 'date-fns/locale';
import { customMask, formatObject } from '../../../utils/helpers';
import {
    createReimbursement,
    createReimbursementFile,
} from '../../../services/reimbursements';
import { getOperationsList } from '../../../services/operations';
import { sendS3File } from '../../../services/client';
import { ExceptionsHandler } from '../../../services/exceptions';

function ReimbursementsRegisterDialog({ type }) {
  const { setAlert } = useContext(Context);
  const { registerDialog, setRegisterDialog } = useContext(Context);
  const [reimbursementRegister, setReimbursementRegister] = useState({expenses_type: type});
  const [invoice_dt, setInvoice_dt] = useState(new Date());
  const [reimbursement_file, setReimbursement_file] = useState(null);
  const [operationsData, setOperationsData] = useState([]);
  const { setRefresh } = useContext(Context);
  const [registerButtonState, setRegisterButtonState] = useState(false);

  const session_token = localStorage.getItem('session_token');

  const OperationList = (filter) =>
    getOperationsList(filter, session_token)
      .then(({ data }) => data)
      .catch((err) => setAlert(ExceptionsHandler(err)));

  const operationListHandler = async () => {
    const { operations } = await OperationList({is_closed: false});
    setOperationsData(operations);
  };

  const createReimbursementHandler = () => {
    const reimbursement = formatObject(reimbursementRegister);
    if (
      reimbursement.value_required &&
      reimbursement.type &&
      reimbursement.operation_id
    ) {
      setRegisterButtonState(true)
      createReimbursement(reimbursement, session_token)
        .then(async (response) => {
          if (reimbursement_file)
            await createReimbursementFileHandler(response.data.reimbursement.id);
          setAlert({
            state: true,
            message: 'Despesa foi cadastrada com sucesso!',
            type: 'success',
            header: 'Deu certo!'
          });
          setRegisterButtonState(false);
          setRefresh(true);
          handleClose();
        })
        .catch((err) => {
          setAlert(ExceptionsHandler(err));
          setRegisterButtonState(false);
        });
    } else {
      setAlert({
        state: true,
        message: 'Preencha todos os campos obrigatórios antes de tentar cadastrar.',
        type: 'error',
        header: 'Ops, temos um problema!'
      });
    }
  };

  const createReimbursementFileHandler = async (id) => {
    const file_info = { id: id, extension: reimbursement_file.type.split('/')[1] };
    const data = await createReimbursementFile(file_info, session_token);
    const { url, fields } = data.data;
    fields.file = reimbursement_file;
    var s3FormData = new FormData();
    for (var key in fields) {
      s3FormData.append(key, fields[key]);
    }
    sendS3File(url, s3FormData);
  };

  const clearFields = () => {
    setReimbursementRegister({});
    setReimbursement_file(null);
    setInvoice_dt(new Date())
    document.getElementById('raised-button-file').value = null;
  };

  const handleFile = (e) => {
    const file = e.target.files[0];
    file['dispotition'] = 'attachment';
    const file_type = file.type.split('/')[1];
    const valid_file_types = ['pdf', 'png', 'jpg', 'jpeg', 'heic', 'webp'];
    if (valid_file_types.includes(file_type)) setReimbursement_file(file);
    else
      setAlert({
        state: true,
        message: 'Por favor, utilize um dos formatos válidos.',
        type: 'error',
        header: 'Formato de arquivo inválido!'
      });
    };
    
  const handleChange = ({ name, value }) => {
    setReimbursementRegister({ ...reimbursementRegister, [name]: customMask(name, value) });
  };

  const handleClose = () => {
    clearFields();
    setRegisterDialog(false);
  };

  useEffect(() => {
    operationListHandler();
  }, []);

  useEffect(() => {
    setReimbursementRegister({ ...reimbursementRegister, invoice_dt: invoice_dt });
  }, [invoice_dt]);

  return (
    <>
      <Dialog open={registerDialog} onClose={handleClose} sx={{zIndex: '1202'}}>
        <DialogTitle>
          Cadastrar {type}
          <Tooltip title="Fechar">
            <IconButton
              color="secondary"
              onClick={handleClose}
              sx={{ position: 'absolute', top: 0, right: 0 }}>
              <CloseIcon />
            </IconButton>
          </Tooltip>
        </DialogTitle>
        <DialogContent style={{ padding: '20px' }}>
        <div className="form form-reimbursement">
            <FormControl className="input-type">
              <InputLabel id="type-label">Categoria *</InputLabel>
              <Select
                labelId="type-label"
                name="type"
                required
                value={reimbursementRegister.type || ''}
                label="Categoria"
                onChange={({ target }) => handleChange(target)}>
                <MenuItem value="Alimentação">Alimentação</MenuItem>
                <MenuItem value="Transporte">Transporte</MenuItem>
                <MenuItem value="Hospedagem">Hospedagem</MenuItem>
                <MenuItem value="Custas Judiciais">Custas Judiciais</MenuItem>
                <MenuItem value="Outros">Outros</MenuItem>
              </Select>
            </FormControl>
            <FormControl className="input-value">
              <InputLabel htmlFor="reimbursement_value">Valor da Despesa *</InputLabel>
              <OutlinedInput
                id="reimbursement_value"
                name="value_required"
                label="Valor da Despesa *"
                value={reimbursementRegister.value_required || ''}
                placeholder=" R$ 0,00"
                onChange={({ target }) => handleChange(target)}
              />
            </FormControl>
            <FormControl className="input-operation_id">
              <Autocomplete
                disablePortal
                options={operationsData.map((operation) => ({id: operation.id, label: operation.or_tag}))}
                id="operation_id"
                name="operation_id"
                renderInput={(params) => <TextField {...params} label="Centro de Custo" required />}
                onChange={(event, value) => handleChange({ name: 'operation_id', value: value?.id })}
                isOptionEqualToValue={(option, value) => option.id === value.id}
                clearOnEscape
                clearText='Limpar'
              />
            </FormControl>
            <input
              accept=".pdf, .png, .jpg, .jpeg, .heic, .webp"
              style={{ display: 'none' }}
              id="raised-button-file"
              type="file"
              onChange={handleFile}
            />
            <label htmlFor="raised-button-file">
              <TextField
                className="input-file"
                component="label"
                required
                label="Anexo da Despesa"
                value={reimbursement_file ? reimbursement_file.name : ''}
                sx={{ width: '100%', pointerEvents: 'none' }}
              />
            </label>
            <LocalizationProvider
              dateAdapter={AdapterDateFns}
              adapterLocale={ptBRLocale}
              localeText={
                datePickerLocale.components.MuiLocalizationProvider.defaultProps.localeText
              }>
              <DatePicker
                disableFuture={type === "Solicitação de Reembolso"}
                className="input-invoice_dt"
                name="invoice_dt"
                label={type === "Solicitação de Pagamento"? "Data de Vencimento *" : "Data da Despesa *"}
                openTo="day"
                views={['year', 'month', 'day']}
                inputFormat="dd/MM/yyyy"
                value={invoice_dt}
                onChange={(newValue) => setInvoice_dt(newValue)}
                renderInput={(params) => <TextField {...params} />}
              />
            </LocalizationProvider>
            <TextField
              className="input-description"
              label="Descrição da Despesa *"
              name="description"
              multiline
              value={reimbursementRegister.description || ''}
              onChange={({ target }) => handleChange(target)}
            />
        </div>
        </DialogContent>
        <DialogActions sx={{ justifyContent: 'space-between' }}>
          <Button variant="outlined" onClick={clearFields}>Limpar</Button>
          <Button disabled={registerButtonState} onClick={createReimbursementHandler}>Registrar</Button>
        </DialogActions>
      </Dialog>
    </>
  );
}

ReimbursementsRegisterDialog.propTypes = {
  type: PropTypes.string
};


export default ReimbursementsRegisterDialog;
