import React, { useContext } from 'react';
import PropTypes from 'prop-types';
import Context from '../../context/Context';
import Button from '@mui/material/Button';
import Dialog from '@mui/material/Dialog';
import DialogActions from '@mui/material/DialogActions';
import DialogContent from '@mui/material/DialogContent';
import DialogContentText from '@mui/material/DialogContentText';
import DialogTitle from '@mui/material/DialogTitle';
import { deleteReimbursement } from '../../services/reimbursements';
import { deleteOperation } from '../../services/operations';
import { ExceptionsHandler } from '../../services/exceptions';
import { deleteClients } from '../../services/clients';
import { deleteInvoice } from '../../services/dashboards';
import { deleteUser } from '../../services/user';

function DeleteDialog({ row, option }) {
  const { setAlert } = useContext(Context);
  const { confirmeDialog, setConfirmeDialog } = useContext(Context);
  const { setRefresh } = useContext(Context);
  const { setDialog } = useContext(Context);      
  const session_token = localStorage.getItem('session_token');

  let dialog

  switch (option) {
    case 'operation':
      dialog = {
        title: 'Deseja excluir o Centro de Custo?',
        content:
          'Ao excluir o Centro de Custo, todos os dados relacionados a ele serão excluídos permanentemente, inclusive as despesas vinculadas.'
      };
      break;
    case 'reimbursement':
      dialog = {
        title: 'Deseja excluir a despesa?',
        content:
          'Ao excluir a despesa, todos os dados relacionados a ela serão excluídos permanentemente.'
      };
      break;
    case 'userDebts':
      dialog = {
        title: 'Deseja registrar o pagamento do saldo em aberto do colaborador ',
        content: 'Ao definir como pago o saldo de '
      };
      break;
    case 'invoice':
      dialog = {
        title: 'Deseja excluir a fatura?',
        content:
          'Ao excluir a fatura, todos os dados relacionados a ela serão excluídos permanentemente.'
      };
      break;
    case 'clients':
      dialog = {
        title: 'Deseja excluir o cliente?',
        content:
          'Ao excluir a cliente, ele será excluídos permanentemente.'
      };
      break;
    case 'user':
      dialog = {
        title: 'Deseja excluir a conta do colaborador?',
        content:
          'Ao excluir a conta do colaborador ele não poderá mais acessar a plataforma.'
      };
    break;
    default:
      break;
  }

  const operationDeleteHandler = () => {
    deleteOperation(row.id, session_token)
      .then(() => {
        setAlert({
          state: true,
          type: 'success',
          header: 'Deu certo!',
          message: 'Excluímos o centro de custo com sucesso!',
        });
        handleClose();
        setConfirmeDialog(false);
      })
      .catch((err) => {
        handleClose();
        setAlert(ExceptionsHandler(err));
      });
  };

  const reimbursementDeleteHandler = () => {
    deleteReimbursement(row.id, session_token)
      .then(() => {
        setAlert({
          state: true,
          type: 'success',
          header: 'Deu certo!',
          message: 'Excluímos a despesa com sucesso!',
        });
        handleClose();
        setConfirmeDialog(false);
      })
      .catch((err) => {
        handleClose();
        setAlert(ExceptionsHandler(err));
      });
  };

  const clientsDeleteHandler = () => {
    deleteClients(row.id, session_token)
      .then(() => {
        setAlert({
          state: true,
          type: 'success',
          header: 'Deu certo!',
          message: 'Excluímos o cliente com sucesso!',
        });
        handleClose();
        setConfirmeDialog(false);
      })
      .catch((err) => {
        handleClose();
        setAlert(ExceptionsHandler(err));
      });
  };

  const invoiceDeleteHandler = () => {
    deleteInvoice(row.id, session_token)
      .then(() => {
        setAlert({
          state: true,
          type: 'success',
          header: 'Deu certo!',
          message: 'Excluímos a fatura com sucesso!',
        });
        handleClose();
        setConfirmeDialog(false);
      })
      .catch((err) => {
        handleClose();
        setAlert(ExceptionsHandler(err));
      });
  };

  const userDeleteHandler = () => {
    const payload = {id: row.id}
    deleteUser(payload, session_token)
        .then(() => {
          setAlert({
            state: true,
            message: 'Usuário removido com sucesso!',
            type: 'success',
            header: 'Deu certo!'
          });
          setRefresh(true);
          handleClose();
        })
        .catch((err) => {
          setAlert(ExceptionsHandler(err));
        });
  }

  const handleConfirme = () => {
    
    switch (option) {
      case 'operation':
        operationDeleteHandler();
        setDialog(false);
        setRefresh(true);
        break;
      case 'reimbursement':
        reimbursementDeleteHandler();
        setDialog(false);
        setRefresh(true);
        break;
      case 'clients':
        clientsDeleteHandler();
        setDialog(false);
        setRefresh(true);
        break;
      case 'invoice':
        invoiceDeleteHandler();
        setDialog(false);
        setRefresh(true);
        break;
      case 'user':
        userDeleteHandler();
        setDialog(false);
        setRefresh(true);
        break;
      default:
        break;
    }
  };

  const handleClose = () => {
    setConfirmeDialog(false);
  };

  return (
    <div>
      <Dialog
        open={confirmeDialog}
        onClose={handleClose}
        aria-labelledby="alert-dialog-title"
        aria-describedby="alert-dialog-description"
        sx={{zIndex: '1202'}}>
        <DialogTitle id="alert-dialog-title">{dialog.title}</DialogTitle>
        <DialogContent>
          <DialogContentText id="alert-dialog-description">{dialog.content}</DialogContentText>
        </DialogContent>
        <DialogActions>
          <Button onClick={handleClose}>Cancelar</Button>
          <Button variant="delete" onClick={handleConfirme} autoFocus>
            Excluir
          </Button>
        </DialogActions>
      </Dialog>
    </div>
  );
}

DeleteDialog.propTypes = {
  row: PropTypes.object,
  option: PropTypes.string
};

export default DeleteDialog;
