import {
  Button,
  Card,
  CardContent,
  CardHeader,
  TextField,
  FormControlLabel,
  Checkbox,
  Link
} from '@mui/material';
import React, { useContext, useState } from 'react';
import { userForm, companyForm, passwordForm } from '../../utils/registerFields';
import { customMask, formatObject } from '../../utils/helpers';
import { createAdmin } from '../../services/user';
import { ExceptionsHandler } from '../../services/exceptions';
import { ValidateForm, ValidatePassword } from '../../utils/validates';
import Context from '../../context/Context';
import { isValidPass, isValidUserForm, isValidCompanyForm } from '../../utils/formValidators';

const FormRegister = () => {
  const { setAlert, navigate } = useContext(Context);
  const [userRegister, setUserRegister] = useState({});
  const [companyRegister, setCompanyRegister] = useState({});
  const [confirmPassword, setConfirmPassword] = useState({});
  const [termsChecked, setTermsChecked] = useState(false);

  const steps = [
    {
      label: 'Sobre você',
      requirements: 'operation_id',
      // element: <SelectOperation />
    },
    {
      label: 'Sobre sua empresa',
      requirements: 'reimbursement_ids',
      // element: <SelectReimbursements />
    },
    {
      label: 'Defina uma senha para o seu acesso',
      requirements: 'operation_id',
      // element: <GenerateInvoice />
    },
    {
      label: 'Defina um plano Suppi+ perfeito para a sua empresa',
      requirements: 'operation_id',
      // element: <GenerateInvoice />
    },
    {
      label: 'Realize o pagamento',
      requirements: 'operation_id',
      // element: <GenerateInvoice />
    }
  ];

  const register = () => {
    const user = formatObject(userRegister);
    const company = formatObject(companyRegister);
    const { password } = formatObject(confirmPassword);

    createAdmin({ ...user, company, password })
      .then(() => {
        setAlert({
          state: true,
          message: 'Colaborador cadastrado com sucesso!',
          type: 'success',
          header: 'Sucesso'
        });
        navigate('/login');
      })
      .catch((err) => {
        setAlert(ExceptionsHandler(err));
      });
  };

  const isDisabled = () => {
    if (
      isValidPass(confirmPassword) &&
      isValidUserForm(userRegister) &&
      isValidCompanyForm(companyRegister) &&
      termsChecked
    ) {
      return false;
    }
    return true;
  };

  const handleChangePassword = ({ name, value }) => {
    setConfirmPassword({ ...confirmPassword, [name]: value });
    setUserRegister({
      ...userRegister,
      ['password']: customMask('password', confirmPassword['password'])
    });
  };

  const handleChangeUser = ({ name, value }) => {
    setUserRegister({ ...userRegister, [name]: customMask(name, value) });
  };

  const handleChangeCompany = ({ name, value }) => {
    setCompanyRegister({ ...companyRegister, [name]: customMask(name, value) });
  };

  return (
    <div className="page-content">
      <Card className="card">
        <CardHeader
          className="card-header"
          title="Sobre a Empresa"
          subheader="(*) Campos obrigatórios"
        />
        <CardContent className="card-body">
          <div className="form form-company">
            {companyForm.map(({ name, label, required }, index) => (
              <TextField
                key={index}
                name={name}
                required={required}
                value={companyRegister[name] || ''}
                className={`input-${name}`}
                label={label}
                onChange={({ target }) => handleChangeCompany(target)}
              />
            ))}
          </div>
        </CardContent>
      </Card>
      <Card className="card">
        <CardHeader
          className="card-header"
          title="Sobre Você"
          subheader="(*) Campos obrigatórios"
        />
        <CardContent className="card-body">
          <div className="form form-user">
            {userForm.map(({ name, label, required }, index) => (
              <TextField
                key={index}
                name={name}
                required={required}
                value={userRegister[name] || ''}
                className={`input-${name}`}
                label={label}
                onChange={({ target }) => handleChangeUser(target)}
              />
            ))}
          </div>
        </CardContent>
      </Card>
      <Card className="card">
        <CardContent className="card-body">
          <div className="form form-register">
            <div className="input-terms">
              <Checkbox checked={termsChecked} onChange={() => setTermsChecked(!termsChecked)} />
              <div>
                <span>Eu li e aceito as </span>
                <Link target="_blank" href={'/privacy'}>
                  Políticas de Privacidade
                </Link>
                <span>.</span>
              </div>
            </div>
            {passwordForm.map(({ name, label, required, type }, index) => (
              <TextField
                key={index}
                name={name}
                type={type}
                required={required}
                className={`input-${name}`}
                label={label}
                onChange={({ target }) => handleChangePassword(target)}
              />
            ))}
            <Button className="input-action" disabled={isDisabled()} onClick={register}>
              <b>Cadastrar</b>
            </Button>
          </div>
        </CardContent>
      </Card>
    </div>
  );
};

export default FormRegister;
