import React from 'react';
import NavBar from '../components/NavBar';
import HomePage from '../components/pages/HomePage';
import Footer from '../components/Footer';

function Home() {

  return (
    <>
      <NavBar home/>
      <HomePage/>
    </>
  )
};

export default Home;