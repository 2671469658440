import React, { useContext, useState } from 'react';
import { Button, Card, CardContent, TextField, CardHeader, Link, Checkbox } from '@mui/material';
import { forgotPassword, loginUser } from '../../services/auth';
import Context from '../../context/Context';
import { ExceptionsHandler } from '../../services/exceptions';
import ForgotPasswordDialog from '../dialogs/ForgotPasswordDialog';

function LoginForm() {
  const [user, setUser] = useState({});
  const { navigate, setAlert } = useContext(Context);
  const { sessionData, setSessionData } = useContext(Context);
  const { setDialog } = useContext(Context);
  const [termsChecked, setTermsChecked] = useState(false);

  const handleChange = ({ name, value }) => {
    setUser({ ...user, [name]: value });
  };

  const loginHandler = () => {
    loginUser(user)
      .then(({ data }) => {
        setAlert({
          state: true,
          message: 'Estamos felizes em te ver por aqui!',
          type: 'info',
          header: 'Olá, ' + data.name
        });
        setSessionData({ ...sessionData, ...data });
        navigate('/platform');
      })
      .catch((err) => {
        setAlert(ExceptionsHandler(err));
      });
  };

  const forgotPasswordHandler = () => {
    if (user.email) {
      forgotPassword({ email: user.email })
        .then((res) => {
          setAlert({
            state: true,
            message: res.data.message,
            type: 'success',
            header: 'Deu certo!'
          });
          setDialog(true);
        })
        .catch((err) => {
          setAlert(ExceptionsHandler(err));
        });
    } else {
      setAlert({
        state: true,
        header: 'Ops, precisamos do seu email!',
        message: 'Preencha o seu email para restaurarmos sua senha.',
        type: 'error'
      });
    }
  };

  return (
    <div className="page-content" style={{paddingTop: "15px"}}>
      <ForgotPasswordDialog email={user.email} />
      <Card className="card">
        <CardHeader className="card-header" title="Faça seu Login" />
        <CardContent className="card-body">
          <div className="form form-login">
            <TextField
              className="input-email"
              color="primary"
              variant="outlined"
              type="text"
              name="email"
              label="E-mail"
              autoFocus
              onChange={({ target }) => handleChange(target)}
            />
            <TextField
              className="input-password"
              color="primary"
              variant="outlined"
              type="password"
              name="password"
              label="Senha"
              onChange={({ target }) => handleChange(target)}
            />
            <div className="input-terms">
              <div>
              <Checkbox checked={termsChecked} onChange={() => setTermsChecked(!termsChecked)} />
                <span>Eu li e aceito os </span>
                <Link target="_blank" href={'/terms'}>
                  Termos
                </Link> e a <Link target="_blank" href={'/privacy'}>
                  Políticas de Privacidade
                </Link>
                <span>.</span>
              </div>
            </div>
            <div className="input-forgot_password">
              <span>Esqueceu sua senha? Não tem problema, </span>
              <Link onClick={() => forgotPasswordHandler()} href="#" color="secondary">
                clique aqui
              </Link>
              <span>!</span>
            </div>
            <Button className="input-action" onClick={() => loginHandler()} disabled={!termsChecked}>
              <b>Entrar</b>
            </Button>
          </div>
        </CardContent>
      </Card>
    </div>
  );
}

export default LoginForm;
