import React from 'react';
import FormRegister from '../components/pages/RegisterPage';
import NavBar from '../components/NavBar';


const Register = () => {
  return (
    <>
      <NavBar login/>
      <FormRegister />
    </>
  )
};

export default Register;