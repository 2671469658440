import React, { useContext, useEffect } from 'react';
import { Button, Card, CardActions, CardContent, Grid, Input, Slider } from '@mui/material';
import PlanRegisterDialog from '../../dialogs/planDialog/PlanRegisterDialog';
import Context from '../../../context/Context';
import { calcPlan } from '../../../utils/helpers';

function PlansSection() {
    const [valueUser, setValueUser] = React.useState(10);
    const [valueExpenses, setValueExpenses] = React.useState(300);
    const [selectedPlan, setSelectedPlan] = React.useState("Error");
    const { registerDialog, setRegisterDialog, navigate } = useContext(Context);
    
    const handlePlanRegisterDialog = () => {
        setRegisterDialog(!registerDialog);
    }
    
    const handleSelectedPlan = (plan, value) => {
        localStorage.setItem('plan', plan);
        localStorage.setItem('value_user', valueUser);

        setSelectedPlan(plan);
        navigate('/register');
        // handlePlanRegisterDialog();
    }

    const [valueTotal, setValueTotal] = React.useState(calcPlan(valueUser));
    
    const handleUserSliderChange = (event, newValue) => {
        setValueUser(newValue);
      };
    
      const handleUserInputChange = (event) => {
        setValueUser(event.target.value === '' ? '' : Number(event.target.value));
      };
    
      const handleUserBlur = () => {
        if (valueUser < 1) {
            setValueUser(1);
        } else if (valueUser > 300) {
            setValueUser(300);
        }
      };


    // const handleExpensesSliderChange = (event, newValue) => {
    //     setValueExpenses(newValue);
    // };
    
    // const handleExpensesInputChange = (event) => {
    //     setValueExpenses(event.target.value === '' ? '' : Number(event.target.value));
    // };
    
    // const handleExpensesBlur = () => {
    //     if (valueExpenses < 100) {
    //         setValueExpenses(100);
    //     } else if (valueExpenses > 10000) {
    //         setValueExpenses(10000);
    //     } else if (valueExpenses % 100 != 0 ){
    //         setValueExpenses((Math.floor(valueExpenses/100)+1)*100)
    //     }
    // };

    const styleForGrid = { 
        backgroundColor: '#48c0ff',
        textAlign: 'center',
        padding: '25px', 
        display: 'flex',
        justifyContent: 'center',
        alignItems: 'center',
    }

    const styleForPlanCard = { 
        textAlign: "left",
        margin: "15px",
        borderRadius: "10%",
        boxShadow: '0 4px 8px 0 rgba(0, 0, 0, 0.5), 0 6px 20px 0 rgba(0, 0, 0, 0.19)'
    }


    const marksUsers = [
        {
          value: 1,
          label: '1 Usuário',
        },
        {
          value: 300,
          label: '300 Usuários',
        },
    ];
    
    const marksExpenses = [
        {
          value: 100,
          label: 'Até 100',
        },
        {
          value: 10000,
          label: 'Até 10.000',
        },
    ];

    useEffect(() => {
        setValueTotal(calcPlan(valueUser))
    }, [valueUser, valueExpenses]);

return (
    <div>
    {registerDialog && <PlanRegisterDialog valueUser={valueUser} valueExpenses={valueExpenses} selectedPlan={selectedPlan}/>}
        <Grid container  style={styleForGrid}>
                <Grid item xs={1} sm={2}></Grid>
                <Grid item xs={10} sm={8}>
                    <div>
                        <h1>Monte um plano Suppi+ perfeito para o seu escritório!</h1><br/>
                        Quantos usuários vão acessar a Suppi+?

                        <Input
                            value={valueUser}
                            size="small"
                            onChange={handleUserInputChange}
                            onBlur={handleUserBlur}
                            inputProps={{
                            step: 1,
                            min: 1,
                            max: 300,
                            type: 'number',
                            'aria-labelledby': 'input-slider',
                            }}
                            style={{paddingLeft: "15px"}}
                        /> usuários.

                        <Slider aria-label="Contas" 
                            step={1}
                            color="secondary"
                            valueLabelDisplay="auto"
                            value={typeof valueUser === 'number' ? valueUser : 0}
                            onChange={handleUserSliderChange}
                            aria-labelledby="input-slider"
                            min={1}
                            marks={marksUsers}
                            max={300}/>
                    </div>
                </Grid>
                <Grid item xs={1} sm={2}></Grid>
                <Grid item xs={0} sm={0} md={0} lg={1.5}></Grid>
                <Grid item xs={12} sm={9} md={4} lg={3}>
                    <Card style={styleForPlanCard}>
                        <CardContent>
                            <h2>Plano Suppi+ Mensal:</h2>
                            <hr/>
                            <b>Descrição:</b>
                            <br/>
                            Quant. usuários: {valueUser}
                            <br/>
                            Quant. despesas: Até {valueUser*40}/Mês*
                            <br/>
                            Fidelidade: 1 mês.
                            <br/>
                            <b style={{color: "#2400b4"}}>Desconto: 0% OFF</b>
                            <hr/>
                            <b style={{fontSize: "19px"}}>Total: R$ {valueTotal.toFixed(2)} / Mês</b>
                            <hr/>
                            <CardActions style={{margin: "15px 5px 0px 5px"}}>
                                <Button 
                                    variant="outlined" 
                                    onClick={() => handleSelectedPlan("Mensal", valueTotal.toFixed(2))}>
                                        <b>Assinar</b>
                                    </Button>
                            </CardActions>
                        </CardContent>
                    </Card>
                </Grid>
                <Grid item xs={12} sm={9} md={4} lg={3}>
                    <Card style={styleForPlanCard}>
                        <CardContent>
                            <h2>Plano Suppi+ Trimestral:</h2>
                            <hr/>
                            <b>Descrição:</b>
                            <br/>
                            Quant. usuários: {valueUser}
                            <br/>
                            Quant. despesas: Até {valueUser*40}/Mês*
                            <br/>
                            Fidelidade: 3 meses.
                            <br/>
                            <b style={{color: "#2400b4"}}>Desconto: 8% OFF</b>
                            <hr/>
                            <b style={{fontSize: "19px"}}>Total: R$ {(valueTotal*0.92).toFixed(2)} / Mês</b>
                            <hr/>
                            <CardActions style={{margin: "15px 5px 0px 5px"}}>
                                <Button 
                                variant="outlined"
                                onClick={() => handleSelectedPlan("Trimestral", (valueTotal*0.92).toFixed(2))}>
                                    <b>Assinar</b>
                                </Button>
                            </CardActions>
                        </CardContent>
                    </Card>
                </Grid>
                <Grid item xs={12} sm={9} md={4} lg={3}>
                    <Card style={styleForPlanCard}>
                        <CardContent>
                            <h2>Plano Suppi+ Anual:</h2>
                            <hr/>
                            <b>Descrição:</b>
                            <br/>
                            Quant. usuários: {valueUser}
                            <br/>
                            Quant. despesas: Até {valueUser*40}/Mês*
                            <br/>
                            Fidelidade: 12 meses.
                            <br/>
                            <b style={{color: "#2400b4"}}>Desconto: 15% OFF</b>
                            <hr/>
                            <b style={{fontSize: "19px"}}>Total: R$ {(valueTotal*0.85).toFixed(2)} / Mês</b>
                            <hr/>
                            <CardActions style={{margin: "15px 5px 0px 5px"}}>
                                <Button 
                                    variant="outlined"
                                    onClick={() => handleSelectedPlan("Anual", (valueTotal*0.85).toFixed(2))}>
                                        <b>Assinar</b>
                                    </Button>
                            </CardActions>
                        </CardContent>
                    </Card>
                </Grid>
                <Grid item xs={0} sm={0} md={0} lg={1.5}></Grid>
            <div>
                <br/>
                *Caso ultrapasse o limite mensal, é cobrada uma taxa de R$ 0,20 por despesa excedente, subtraído o desconto do plano escolhido.
                <br/>
                <b style={{fontSize: "18px"}}>Para planos maiores, solicitação de personalização ou 
                    dúvidas, entre em contato: contato@suppimais.com.br</b>
            </div>
        </Grid>
    </div>
    );
}

export default PlansSection;