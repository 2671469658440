import React, { useContext, useState, useEffect } from 'react';
import { Card, CardHeader, CardContent, TextField, Grid, Button, FormControl, InputLabel, Select, MenuItem } from '@mui/material';
import Context from '../../../context/Context';
import { DataGrid, ptBR as dataGridLocale } from '@mui/x-data-grid';
import { customMask, handleClickOpenFile, renderContract } from '../../../utils/helpers';
import { creditRecoverForm } from '../../../utils/registerFields';
import { CustomPagination } from '../../../utils/customGrid';
import { ExceptionsHandler } from '../../../services/exceptions';
import { getCreditRecovery, getRenegUrl } from '../../../services/reneg';
import RenegUserChangeStatusDialog from '../../dialogs/renegDialogs/RenegUserChangeStatusDialog';
import { CleaningServices, FilterAlt, FilterAltOff } from '@mui/icons-material';

function CreditRecover() {
  const { setAlert } = useContext(Context);
  const [allCollaboratorsDebtData, setAllCollaboratorsDebtData] = useState([]);
  const { dialog, setDialog } = useContext(Context);
  const [editRow, setEditRow] = useState({});
  const { refresh, setRefresh } = useContext(Context);
  const [filter, setFilter] = useState({});
  const [page, setPage] = React.useState(0);
  const [pageLoadControl, setPageLoadControl] = useState([]);
  const [loading, setLoading] = useState(false);  
  // Filter section vars
  const [ filterSection, setFilterSection ] = useState(false);

  const session_token = localStorage.getItem('session_token');

  const handleValues = (params) => {
    const { field, value } = params;
    if (field === 'status') {
      switch (value) {
        case 'pendente':
          return 'Pendente';
        case 'vizualizado':
          return 'Vizualizado';
        case 'gerando_boletos':
          return 'Emitindo Boletos';
        case 'contrato_gerado':
          return 'Reneg. Aceita';
        case 'em_assinatura':
          return 'Em Assinatura';
        case 'assinado':
          return 'Assinado';
        case 'renegociado':
          return 'Renegociado';
        case 'recusado':
          return 'Recusado';
      }
    }
    return customMask(field, value);
  };

  const GetUrlHandle = (value) => 
    getRenegUrl(value, session_token)
      .then(({ data }) => data)
      .catch((err) => setAlert(ExceptionsHandler(err)));

  const getS3UrlReneg = async (value) => {
    const data = await GetUrlHandle(value);
    const { file_url } = data;
    handleClickOpenFile(file_url);
    return file_url
  }

  const DebtsUsersColumns = creditRecoverForm.map(({ name, label, width, align, type, hiden }) => ({
    field: name,
    headerName: label,
    flex: width,
    minWidth: 100 * width,
    align: align,
    headerAlign: align,
    type: type,
    hide: hiden,
    filterable: false,
    sortable: false,
    valueGetter: (params) => handleValues(params),
    renderCell: (params) => renderContract(params)
  }));

  const AllDebtsUsersList = (filter) =>
    getCreditRecovery(filter, session_token)
      .then(({ data }) => data)
      .catch((err) => setAlert(ExceptionsHandler(err)));

  const allDebtsUsersListHandler = async (method) => {
    // TODO: Replicar logica de paginação para outros submenus
    setLoading(true);
    const prevPage = allCollaboratorsDebtData.debt_users;
    console.log(method);
    if (method === 'add') {
      if (!pageLoadControl.includes(page + 1)){
        const data = await AllDebtsUsersList({ ...filter, page: page + 1 });
        const { debt_users } = data;
        let newDebtsUsers = [];
        for (let i = 0; i < debt_users.length; i++) {
          if (!prevPage.find((item) => item.charge_id === debt_users[i].charge_id)) {
            newDebtsUsers.push(debt_users[i]);
          }
        }
        setAllCollaboratorsDebtData({ ...allCollaboratorsDebtData, debt_users: [...allCollaboratorsDebtData.debt_users, ...newDebtsUsers] });
        setPageLoadControl([...pageLoadControl, page + 1]);
      }
    } else {
      const data = await AllDebtsUsersList(filter);
      setAllCollaboratorsDebtData(data);
      setPageLoadControl([1]);
    }
    setLoading(false);
  };

  const handleDialogDebts = (row) => {
    console.log(row);
    setEditRow(row);
    setDialog(true);
  };

  useEffect(() => {
    if (refresh === true) {
      allDebtsUsersListHandler();
      setRefresh(false);
    }
  }, [refresh]);

    //Filter Section Stuff

    const handleAddFilter = () => {
      setFilterSection(!filterSection);
    };
  
    const mountFilterGrid = () => {
      const styleForGrid = {
        paddingBottom: "10px",
        textAlign: 'center',
        display: 'flex',
        justifyContent: 'center',
        alignItems: 'center',
      }
  
      const styleForInputs = {
        width: "100%"
      }
  
      const handleFilterChange = ({ name, value }) => {
        if (value === null || value === ''){
          const filterHelper = filter
          delete filterHelper[name]
          setFilter({...filterHelper})
        }else{
          setFilter({ ...filter, [name]: customMask(name, value) });
        }
      };
  
      const handleFilterClean = () => {
        setFilter({});
        setRefresh(true);
      };
  
      const handleFilterAction = () => {
        setRefresh(true);
      };
      
      return (
        <div style={{paddingBottom: "20px"}}>
          <Grid container>
            <Grid item xs={12}><h3>Filtros:</h3></Grid>
            <Grid item xs={12} sm={4} style={styleForGrid}>
              <TextField
                  label="Nome"
                  name="name"
                  multiline
                  value={filter.name || ''}
                  onChange={({ target }) => handleFilterChange(target)}
                  style={styleForInputs}
                  />
            </Grid>
            <Grid item xs={12} sm={4} style={styleForGrid}>
              <TextField
                  label="Email"
                  name="email"
                  multiline
                  value={filter.email || ''}
                  onChange={({ target }) => handleFilterChange(target)}
                  style={styleForInputs}
                  />
            </Grid>
            <Grid item xs={12} sm={4} style={styleForGrid}>
              <TextField
                  label="CPF"
                  name="cpf"
                  multiline
                  value={filter.cpf || ''}
                  onChange={({ target }) => handleFilterChange(target)}
                  style={styleForInputs}
                  />
            </Grid>
            <Grid item xs={12} sm={4} style={styleForGrid}>
              <TextField
                  label="Cliente"
                  name="debt_owner"
                  multiline
                  value={filter.debt_owner || ''}
                  onChange={({ target }) => handleFilterChange(target)}
                  style={styleForInputs}
                  />
            </Grid>
            <Grid item xs={12} sm={4} style={styleForGrid}>
            <FormControl fullWidth>
              <InputLabel id="type-label">Status</InputLabel>
              <Select
                name="status"
                required
                value={filter.status || ''}
                label="Status"
                onChange={({ target }) => handleFilterChange(target)}
                style={styleForInputs}>
                <MenuItem value="pendente">Pendente</MenuItem>
                <MenuItem value="vizualizado">Vizualizado</MenuItem>
                <MenuItem value="contrato_gerado">Reneg. Aceita</MenuItem>
                <MenuItem value="em_assinatura">Em Assinatura</MenuItem>
                <MenuItem value="assinado">Assinado</MenuItem>
                <MenuItem value="gerando_boletos">Emitindo Boletos</MenuItem>
                <MenuItem value="renegociado">Renegociado</MenuItem>
                <MenuItem value="recusado">Recusado</MenuItem>
              </Select>
            </FormControl>
          </Grid>
            <Grid item xs={12} sm={12} style={styleForGrid}></Grid>
            <Grid item xs={12} sm={6} style={styleForGrid}>
              <Button 
                style={{height: "100%"}} 
                onClick={handleFilterAction}>
                  Filtrar <FilterAlt/>
              </Button>
            </Grid>
            <Grid item xs={12} sm={6} style={styleForGrid}>
              <Button 
                style={{height: "100%"}}  
                variant="outlined" 
                onClick={handleFilterClean}>
                  Limpar <CleaningServices/>
              </Button>
            </Grid>
          </Grid>
        </div>
      )
    }

  useEffect(() => {
    allDebtsUsersListHandler();
  }, []);

  useEffect(() => {
    allCollaboratorsDebtData.has_next && allCollaboratorsDebtData.actual_page <= page
      ? allDebtsUsersListHandler('add')
      : null;
  }, [page]);

  return (
    <div className="page-content">
      {dialog && <RenegUserChangeStatusDialog row={editRow} />}
      <Card className="data-grid-card">
        <CardHeader className="card-header" title="Recuperação de Crédito" />
        <CardContent className="card-body">
          <div style={{ width: '100%' }}>
            <div className="data-grid-header">
              <Button 
                style={{width: "auto", marginRight: "5px"}} 
                variant="outlined" 
                onClick={handleAddFilter}>
                  {filterSection ? <FilterAltOff/> : <FilterAlt/>}
                  {Object.keys(filter).length}
              </Button>
            </div>
            {filterSection && mountFilterGrid()}
            <DataGrid
              getRowId={(row) => row.charge_id}
              rows={allCollaboratorsDebtData.debt_users || []}
              columns={DebtsUsersColumns}
              pageSize={10}
              rowCount={(allCollaboratorsDebtData.total_pages || 1) * 10}
              page={page}
              autoHeight
              loading={loading}
              disableSelectionOnClick
              onPageChange={(newPage) => (newPage != 'NaN' ? setPage(newPage) : null)}
              onRowDoubleClick={({ row }) => handleDialogDebts(row)}
              onCellClick={({ field, value }) => {
                if (field === 'file_url' && value) {
                  getS3UrlReneg(value);
                }
              }}
              localeText={dataGridLocale.components.MuiDataGrid.defaultProps.localeText}
              components={{
                Pagination: CustomPagination
              }}
              
            />
          </div>
        </CardContent>
      </Card>
    </div>
  );
}

export default CreditRecover;
