import React, { useContext, useEffect, useState } from 'react';
import PropTypes from 'prop-types';
import { Button, Toolbar } from '@mui/material';
import AppBar from '@mui/material/AppBar';
import Context from '../context/Context';
import logo from '../assets/images/logo-navbar.png';
import { logoutUser } from '../services/auth';
import MenuIcon from '@mui/icons-material/Menu';
import { ExceptionsHandler } from '../services/exceptions';
import AlertDialog from './dialogs/AlertDialog';
import '../styles/NavBar.scss';
import WhatsAppIcon from '@mui/icons-material/WhatsApp';


function NavBar({ login, dashboard, home }) {
  const { navigate, drawer, toggleDrawer, setSessionData, alert, setAlert } = useContext(Context);
  const [offset, setOffset] = useState(false);

  const session_token = localStorage.getItem('session_token');

  const toLogin = () => {
    navigate('/login');
  };

  const toRegister = () => {
    navigate('/register');
  };

  const toHome = () => {
    navigate('/');
  };

  const toLogout = () => {
    logoutUser(session_token)
      .finally(() => {
        setAlert({
          state: true,
          message: 'Estaremos aqui quando você precisar!',
          type: 'info',
          header: 'Até mais!'
        });
        setSessionData({});
        localStorage.clear();
        navigate('/');
      })
      .catch((err) => {
        setAlert(ExceptionsHandler(err));
      });
  };

  useEffect(() => {
    const onScroll = () => setOffset(window.pageYOffset !== 0);
    window.removeEventListener('scroll', onScroll);
    window.addEventListener('scroll', onScroll, { passive: true });
    return () => window.removeEventListener('scroll', onScroll);
  }, []);

  return (
    <>
      {alert.state && <AlertDialog />}
      <AppBar
        className="navbar sticky top-0"
        sx={{
          display: 'flex',
          boxShadow: offset ? '' : 'none',
          bgcolor:  !offset && home ? 'transparent' : '#2400b4',
          zIndex: '1201'
        }}>
        <Toolbar>
          <div className="navbar-actions">
            <div>
              {dashboard && (
                <Button onClick={toggleDrawer(!drawer)} variant="navbar" 
                sx={{display: { sm: 'flex', md: 'none' }}}>
                  <MenuIcon />
                </Button>
              )}
            </div>
            <img
              src={logo}
              className="logo"
              alt="logo-suppi"
              width="45%"
              style={{maxWidth: "200px"}}
              onClick={dashboard ? toggleDrawer(true) : toHome}
            />
            {(login || home) && (
              <div>
                {/* <Button variant="navbar" onClick={toLogin}>
                  Sobre Nós
                </Button>
                <Button variant="navbar" onClick={toLogin}>
                  Contato
                </Button> */}
                <Button 
                  href={'https://wa.me/5513981363387?text=Ol%C3%A1%2C+gostaria+de+mais+informa%C3%A7%C3%B5es+sobre+a+plataforma+Suppi%2B.'} 
                  target="_blank" 
                  variant="navbar"
                  sx={{display: { xs: 'none', sm: 'inline-flex' }}}>
                  <WhatsAppIcon style={{paddingRight: "5px"}}/>WhatsApp
                </Button>
                <Button variant="navbar" onClick={toLogin}>
                  Login
                </Button>
                {/* <Button variant="navbar" onClick={toRegister}>
                  Register
                </Button> */}
              </div>
            )}
            {dashboard && (
              <Button variant="navbar" onClick={toLogout}>
                Logout
              </Button>
            )}
          </div>
        </Toolbar>
      </AppBar>
    </>
  );
}

NavBar.propTypes = {
  login: PropTypes.bool,
  dashboard: PropTypes.bool,
  home: PropTypes.bool,
};

export default NavBar;
