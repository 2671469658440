import { Instagram, LinkedIn } from '@mui/icons-material';
import { Box, Link } from '@mui/material';
import PropTypes from 'prop-types';

function Footer({ position }) {
    return <Box
            bgcolor="#0a0036"
            color="white"
            textAlign="center"
            padding="20px"
        >
            <Link href={'https://www.instagram.com/suppimais/'} target="_blank" color="inherit">
                <Instagram fontSize="large"/>
            </Link> <Link href={'https://www.linkedin.com/company/suppimais'} target="_blank" color="inherit">
                <LinkedIn fontSize="large"/>
            </Link><br/>
            Telefone: <Link href={'https://wa.me/5513981363387?text=Ol%C3%A1%2C+gostaria+de+mais+informa%C3%A7%C3%B5es+sobre+a+plataforma+Suppi%2B.'} target="_blank" color="inherit">
                +55 (13) 9 8136-3387
            </Link>
            <br/>
            E-mail: <Link href={'mailto:contato@suppimais.com.br'} target="_blank" color="inherit">
                contato@suppimais.com.br
            </Link>
            <br/>
            <Link href={'/terms'} color="inherit">
                Termos
            </Link> e <Link href={'/privacy'} color="inherit">
                Políticas de Privacidade
            </Link><br/>
            CNPJ: 48.690.942/0001-75<br/>
            Suppi LTDA &reg; {new Date().getFullYear()}
        </Box>
};

Footer.propTypes = {
    position: PropTypes.string,
};

export default Footer;