import { useState, useContext } from 'react';
import Context from '../context/Context';
import PropTypes from 'prop-types';
import { Box, TextField, Pagination, IconButton, Menu, InputLabel, MenuItem, Select } from '@mui/material';
import { getGridBooleanOperators, getGridDateOperators, getGridStringOperators, GridFilterInputSingleSelect } from '@mui/x-data-grid';
import { customMask } from './helpers';
import RefreshIcon from '@mui/icons-material/Refresh';
import FileDownloadIcon from '@mui/icons-material/FileDownload';
import {
  GridCsvExportMenuItem,
  GridPrintExportMenuItem,
  gridPageCountSelector,
  gridPageSelector,
  useGridApiContext,
  useGridSelector,
} from '@mui/x-data-grid';

function customStringFilterInput(props) {
    const { item, applyValue } = props;
    const [value, setValue] = useState( item.value ?? '' );

    const apiRef = useGridApiContext();

    const handleChange = (event) => {
      setValue(event.target.value);
      applyValue({ ...item, value: event.target.value });
      handleClose();
    };

    const handleClose = () => {
      apiRef.current.hideFilterPanel();
      setValue('');
    };
    
    const handleFilterChange = ({ name, value }) => setValue(customMask(name, value));
    
    const handleApplyFilterKeyDown = (event) => {
      if (event.key === 'Enter') {
        switch (item.columnField) {
          case 'client_document_number':
            if (value.length === 18 || value.length === 14) applyValue({ ...item, value });
            break;
          default:
            applyValue({ ...item, value });
            break;
        }
        handleClose();
      }
    };        

    return (
      <Box
        sx={{
          display: 'flex',
          flexDirection: 'column',
          height: '100%',
        }}
      >
        {item.columnField !== 'status' && 
          <TextField
            sx={{ width: '100%' }}
            name={item.columnField}
            autoFocus
            label="Valor"
            value={value || ''}
            variant="standard"
            onChange={({ target }) => handleFilterChange(target)}
            onKeyDown={(event) => handleApplyFilterKeyDown(event)}
          />
        }
        {item.columnField === 'status' && 
          <>
            <InputLabel id="select-standard-label">Status</InputLabel>
            <Select
              labelId="select-standard-label"
              value={value || ''}
              onChange={handleChange}
              label="Status"
            >
              <MenuItem value={'Pendente'}>Pendente</MenuItem>
              <MenuItem value={'Fechado'}>Fechado</MenuItem>
              <MenuItem value={'Aguardando Pagamento'}>Aguardando Pagamento</MenuItem>
              <MenuItem value={'Aberto'}>Aberto</MenuItem>
            </Select>
            </>
        }
      </Box>
    );
  };
  
  customStringFilterInput.propTypes = {
    applyValue: PropTypes.func.isRequired,
    item: PropTypes.shape({
      columnField: PropTypes.string.isRequired,
      id: PropTypes.oneOfType([PropTypes.number, PropTypes.string]),
      operatorValue: PropTypes.string,
      value: PropTypes.any,
    }).isRequired,
  };

export const CustomFilter = (name, type) => {
  switch (name) {
    case 'client_cnpj':
      return [{
          value: 'equals',
          label: 'Igual a',
          getApplyFilterFn: (filterItem) => {
            if (filterItem.value !== undefined) (params) => params.value  === filterItem.value;
          },
          InputComponent: customStringFilterInput,
      }];
    case 'operation_id':
      return [{
          value: 'equals',
          label: 'Igual a',
          getApplyFilterFn: (filterItem) => {
            if (filterItem.value !== undefined) (params) => params.value  === filterItem.value;            
          },
          InputComponent: GridFilterInputSingleSelect,
      }];
    default:
      switch (type) {
        case 'string':
          return getGridStringOperators();
        case 'boolean':
          return getGridBooleanOperators();
        case 'date':
          return getGridDateOperators();
        case 'singleSelect':
          return [
            {
              value: 'equals',
              label: 'Igual a',
              getApplyFilterFn: (filterItem) => {
                if (filterItem.value !== undefined) (params) => params.value  === filterItem.value;
              },
              InputComponent: GridFilterInputSingleSelect,
            }
          ]
        default:
          return getGridStringOperators();
      }
  };
};

function RefreshGrid() {  
  const { refresh, setRefresh } = useContext(Context);
  const handleClick = () => setRefresh(true);
  return (
    <IconButton
      size='small'
      color='primary'
      onClick={handleClick}
    >
      <RefreshIcon />
    </IconButton >
  );
};

function CustomExport() {
  const [anchorEl, setAnchorEl] = useState(null);
  const open = Boolean(anchorEl);

  const csvOptions = {
    fileName: `Suppi+ ${new Date().toLocaleDateString()}`,
    delimiter: ';',
    utf8WithBom: true,
  }

  const handleClick = (event) => {
    setAnchorEl(event.currentTarget);
  };

  const handleClose = () => {
    setAnchorEl(null);
  };

  return (
    <>
      <IconButton
        size='small'
        color='primary'
        onClick={handleClick}
      >
        <FileDownloadIcon />
      </IconButton >
      <Menu
        id="demo-positioned-menu"
        aria-labelledby="demo-positioned-button"
        anchorEl={anchorEl}
        open={open}
        onClose={handleClose}
      >
        <GridPrintExportMenuItem options={csvOptions}/>
        <GridCsvExportMenuItem options={csvOptions}/>
      </Menu>
    </>
  );
};

export function CustomPagination() {
  const apiRef = useGridApiContext();
  const page = useGridSelector(apiRef, gridPageSelector);
  const pageCount = useGridSelector(apiRef, gridPageCountSelector);

  return (
    <div className='data-grid-footer'>
      <CustomExport />
      <RefreshGrid />
      <Pagination
        color="primary"
        count={pageCount}
        page={page + 1}
        variant='outlined'
        onChange={(event, value) => apiRef.current.setPage(value - 1)} // TODO: fix this shit
      />
    </div>
  );
};