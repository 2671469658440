import React, { useContext, useState } from 'react';
import Context from '../../../context/Context';
import {
  TextField,
  Tooltip,
  Button,
  FormControl,
  IconButton,
  InputLabel,
  MenuItem,
  Select
} from '@mui/material';
import { Dialog, DialogActions, DialogContent, DialogTitle } from '@mui/material';
import { clientsFormRegister } from '../../../utils/registerFields';
import CloseIcon from '@mui/icons-material/Close';
import { customMask, formatObject } from '../../../utils/helpers';
import { ExceptionsHandler } from '../../../services/exceptions';
import { createClients } from '../../../services/clients';


function ClientsRegisterDialog() {
  const { setAlert } = useContext(Context);
  const [clientsRegister, setClientsRegister] = useState({});
  const { registerDialog, setRegisterDialog } = useContext(Context);
  const { setRefresh } = useContext(Context);
  const session_token = localStorage.getItem('session_token');

  const handleClose = () => {
    clearFields();
    setRegisterDialog(false);
  };

  const createClientsHandler = () => {
    const clients = formatObject(clientsRegister);
    if (clients.name && clients.document_number && clients.type && clients.initials) {
      createClients(clients, session_token)
        .then(() => {
          setAlert({
            state: true,
            message: 'Cliente foi cadastrado com sucesso!',
            type: 'success',
            header: 'Deu certo!'
          });
          setRefresh(true);
          handleClose();
        })
        .catch((err) => {
          setAlert(ExceptionsHandler(err));
        });
    }
  };

  const clearFields = () => {
    setClientsRegister({});
  };

  const handleChange = ({ name, value }) => {
    if (name === 'initials'){
      value = value.toUpperCase()
    }
    setClientsRegister({ ...clientsRegister, [name]: customMask(name, value) });
  };

  return (
    <>
      <Dialog open={registerDialog} onClose={handleClose} sx={{zIndex: '1202'}}>
        <DialogTitle>
          Cadastrar Cliente
          <Tooltip title="Fechar">
            <IconButton
              color="secondary"
              onClick={handleClose}
              sx={{ position: 'absolute', top: 0, right: 0 }}>
              <CloseIcon />
            </IconButton>
          </Tooltip>
        </DialogTitle>
        <DialogContent style={{ padding: '20px' }}>
        <div className="form form-clients">
            {clientsFormRegister
              .filter(({ name }) => ['name', 'document_number', 'email', 'initials'].includes(name))
              .map(({ name, label, required }, index) => (
                <TextField
                  key={index}
                  name={name}
                  value={clientsRegister[name] || ''}
                  className={`input-${name}`}
                  label={label}
                  required={required}
                  onChange={({ target }) => handleChange(target)}
                />
              ))}
            <FormControl className="input-type">
            <InputLabel id="type-label">Tipo do Cliente *</InputLabel>
              <Select
                labelId="type-label"
                name="type"
                required
                value={clientsRegister.type || ''}
                label="Tipo do Cliente"
                onChange={({ target }) => handleChange(target)}>
                <MenuItem value="PJ">PJ</MenuItem>
                <MenuItem value="PF">PF</MenuItem>
              </Select>
            </FormControl>
        </div>
        </DialogContent>
        <DialogActions sx={{ justifyContent: 'space-between' }}>
          <Button variant="outlined" onClick={clearFields}>Limpar</Button>
          <Button onClick={createClientsHandler}>Registrar</Button>
        </DialogActions>
      </Dialog>
    </>
  );
}

export default ClientsRegisterDialog;
