import React from 'react';
import NavBar from '../components/NavBar';
import PrivacyPage from '../components/pages/PrivacyPage';
import Footer from '../components/Footer';

function Privacy() {

  return (
    <>
      <NavBar login/>
      <PrivacyPage/>
    </>
  )
};

export default Privacy;