import { client } from './client';

export const getCreditRecovery = (filter, session_token) => {
    return client.get(`/reneg/debt_users/adm`, { params: filter, headers: { Authorization: session_token } });
};

export const getRenegUrl = (value, session_token) => {
    return client.get(`/reneg/contract`, {params: {charge_id: value}, headers: { Authorization: session_token } });
}

export const putRenegUserStatus = (value, session_token) => {
    return client.put(`/reneg/debt_users`, value, { headers: { Authorization: session_token } });
}

export const postRenegSendInvoiceMail = (value, session_token) => {
    return client.post(`/reneg/send/invoice/mail`, value, { headers: { Authorization: session_token } });
}

export const postRenegSendInvoiceS3 = (value, session_token) => {
    return client.post(`/reneg/send/invoice/s3`, value, { headers: { Authorization: session_token } });
}