import React from 'react';


function PrivacyPage() {
    return (
        <>
        <p><br/></p>
        <p><span size="10"><strong>POL&Iacute;TICA DE PRIVACIDADE&nbsp;</strong></span></p>
        <p><br/></p>
        <p><span size="3"><strong>PROP&Oacute;SITO</strong></span></p>
        <p><span size="3">A presente Pol&iacute;tica de Privacidade para Clientes, Fornecedores e Parceiros de Neg&oacute;cios (&ldquo;Pol&iacute;tica&rdquo;) apresenta as diretrizes para prote&ccedil;&atilde;o nos processos de tratamento de dados que incluem coleta, produ&ccedil;&atilde;o, recep&ccedil;&atilde;o, classifica&ccedil;&atilde;o, utiliza&ccedil;&atilde;o, acesso, reprodu&ccedil;&atilde;o, transmiss&atilde;o, distribui&ccedil;&atilde;o, processamento, armazenamento, elimina&ccedil;&atilde;o, avalia&ccedil;&atilde;o ou controle de informa&ccedil;&atilde;o, modifica&ccedil;&atilde;o, comunica&ccedil;&atilde;o, transfer&ecirc;ncia de dados pessoais pela Suppi Ltda que possui o compromisso de agir de forma respons&aacute;vel, transparente e em conformidade com a Lei Geral de Prote&ccedil;&atilde;o de Dados (&ldquo;LGPD&rdquo;).</span></p>
        <ul>
            <p><span size="3"><strong>FINALIDADE</strong></span></p>
        </ul>
        <p><span size="3">Esta Pol&iacute;tica descreve os tipos de informa&ccedil;&otilde;es que a Suppi Ltda ir&aacute; coletar quando clientes, fornecedores e parceiros de neg&oacute;cios visitarem o website oficial da empresa e/ou decidirem realizar neg&oacute;cios, seja atrav&eacute;s de contato telef&ocirc;nico, pessoal, aplicativos, mensagens eletr&ocirc;nicas e WhatsApp com colaboradores, s&oacute;cios e/ou prestadores da Suppi Ltda. Tamb&eacute;m faz parte desta Pol&iacute;tica as pr&aacute;ticas, aplicadas pela Suppi Ltda para coletar, usar, manter, proteger e divulgar estas informa&ccedil;&otilde;es.</span></p>
        <ul>
            <p><span size="3"><strong>DADOS PESSOAIS TRATADOS</strong></span></p>
        </ul>
        <p><span size="3">O fornecimento de dados pessoais &eacute; um requisito necess&aacute;rio para as rela&ccedil;&otilde;es entre a Suppi Ltda, Clientes e Fornecedores. Os dados pessoais tratados limitam-se aos dados necess&aacute;rios para cumprir a finalidade para a qual tais dados pessoais s&atilde;o coletados.</span></p>
        <p><br/></p>
        <p><span size="3"><strong>Pessoas Cadastradas na Plataforma (&ldquo;Usu&aacute;rios&rdquo;)</strong></span></p>
        <p><span size="3">A Suppi Ltda poder&aacute; tratar os seguintes dados pessoais: CNPJ, dados da empresa, nome completo, nacionalidade, endere&ccedil;o completo, n&uacute;mero de telefone e/ou celular, endere&ccedil;o de email, profiss&atilde;o, cargo na empresa, n&uacute;mero do cadastro pessoa f&iacute;sica emitido pela receita federal e registro geral emitido pelos &oacute;rg&atilde;os de seguran&ccedil;a dos s&oacute;cios ou acionistas dos clientes, profiss&atilde;o e data de nascimento.</span></p>
        <p><span size="3">Al&eacute;m disso, em decorr&ecirc;ncia da natureza dos servi&ccedil;os prestados pela Suppi Ltda s&atilde;o captados dados referentes a troca de mensagens e/ou liga&ccedil;&otilde;es, score, dados financeiros e banc&aacute;rios.&nbsp;</span></p>
        <p><br/></p>
        <p><span size="3"><strong>SUPPERS, EMPRESAS, FORNECEDORES E PARCEIROS DE NEG&Oacute;CIO</strong></span></p>
        <p><span size="3">A Suppi Ltda poder&aacute; tratar os seguintes dados pessoais: nome, endere&ccedil;o completo, nacionalidade, n&uacute;mero de telefone e/ou celular, endere&ccedil;o de e-mail, profiss&atilde;o, cargo na empresa, n&uacute;mero do cadastro pessoa f&iacute;sica emitido pela receita federal e registro geral emitido pelos &oacute;rg&atilde;os de seguran&ccedil;a dos s&oacute;cios ou acionistas dos clientes, profiss&atilde;o, sexo, idade, data e local de nascimento, n&uacute;mero da carteira de motorista, fotos e v&iacute;deos. A Suppi Ltda poder&aacute; solicitar dados pessoais de colaboradores de fornecedores e parceiros de neg&oacute;cios sempre que tais dados sejam necess&aacute;rios &agrave; consecu&ccedil;&atilde;o das atividades comerciais e ao cumprimento de obriga&ccedil;&atilde;o contratual, legal ou regulat&oacute;ria.</span></p>
        <ul>
            <p><span size="3"><strong>FINALIDADE DO PROCESSAMENTO DE DADOS</strong></span></p>
        </ul>
        <p><span size="3">O tratamento dos dados pessoais ser&aacute; realizado de acordo com a LGPD, apenas para fins limitados, expl&iacute;citos e leg&iacute;timos em conformidade com o disposto nesta Pol&iacute;tica. Os dados pessoais ser&atilde;o tratados conforme descritos abaixo, a fim de cumprir com as obriga&ccedil;&otilde;es resultantes da LGPD e obriga&ccedil;&otilde;es legais vinculadas &agrave; Suppi Ltda</span></p>
        <p><br/></p>
        <p><span size="3"><strong>SUPPERS, FORNECEDORES E EMPRESAS PARCEIRAS DE NEG&Oacute;CIOS</strong></span></p>
        <p><span size="3">a) Gerenciar relacionamento comercial, mediante negocia&ccedil;&otilde;es, solicita&ccedil;&otilde;es/atendimentos de pedidos, assinatura de contratos, analisar cr&eacute;dito dos representantes legais das empresas perante &oacute;rg&atilde;os federais, estaduais e outras autoridades competentes, realizar cadastramento interno na Suppi Ltda, realizar pagamentos, dentre outras opera&ccedil;&otilde;es internas relacionadas com os fornecedores e parceiros de neg&oacute;cios;</span></p>
        <p><span size="3">b) Realizar opera&ccedil;&otilde;es promocionais, marketing direto para promover a posi&ccedil;&atilde;o da Suppi Ltda no mercado;</span></p>
        <p><span size="3">c) Detectar e prevenir fraude para garantir a seguran&ccedil;a e integridade da Suppi Ltda;</span></p>
        <p><span size="3">d) Garantir a seguran&ccedil;a e integridade da Suppi Ltda e de seus colaboradores antes, durante e ap&oacute;s as rela&ccedil;&otilde;es comerciais;</span></p>
        <p><span size="3">e) Responder os processos legais ou de &oacute;rg&atilde;os administrativos/reguladores;</span></p>
        <p><span size="3">f) Cumprir com obriga&ccedil;&otilde;es legais e/ou investiga&ccedil;&otilde;es regulat&oacute;rias, auxiliando a Suppi Ltda no contexto de lit&iacute;gio e arbitragem e protegendo-a contra roubo, responsabilidade legal, fraude, abuso ou outra conduta impr&oacute;pria;</span></p>
        <p><span size="3">g) Gerenciar transa&ccedil;&otilde;es da Suppi Ltda (por exemplo, fus&atilde;o ou aquisi&ccedil;&atilde;o) para conduzir negocia&ccedil;&otilde;es comerciais;</span></p>
        <p><span size="3">h) Gerenciar as opera&ccedil;&otilde;es da Suppi Ltda, incluindo a opera&ccedil;&atilde;o e monitoramento do bom funcionamento e seguran&ccedil;a dos sistemas de tecnologia da informa&ccedil;&atilde;o da Suppi Ltda para garantir a prote&ccedil;&atilde;o e seguran&ccedil;a dos fornecedores e parceiros de neg&oacute;cios;</span></p>
        <p><span size="3">i) Gerenciar entregas de produtos e servi&ccedil;os para administrar os neg&oacute;cios da Suppi Ltda.</span></p>
        <p><span size="3">A Suppi Ltda garante que os procedimentos de governan&ccedil;a interna especifiquem claramente os motivos de tratamento de dados pessoais. Antes dos dados pessoais serem utilizados para uma finalidade diferente daquela para o qual foi inicialmente coletada, caber&aacute; &agrave; empresa informar o titular dos dados o motivo da utiliza&ccedil;&atilde;o.</span></p>
        <ul>
            <p><span size="3"><strong>COMPARTILHAMENTO DE DADOS</strong></span></p>
        </ul>
        <p><span size="3">A Suppi Ltda poder&aacute; divulgar ou compartilhar dados pessoais dos Usu&aacute;rios, empresas, fornecedores e de parceiros de neg&oacute;cios, para contratados, prestadores de servi&ccedil;os, autoridades competentes e outros fornecedores que a Suppi Ltda utiliza para apoiar seus neg&oacute;cios ou em conex&atilde;o com a administra&ccedil;&atilde;o e suporte de atividades ora mencionadas e eu est&atilde;o vinculadas por obriga&ccedil;&otilde;es legais e contratuais de manter os dados pessoais confidenciais e usar os mesmos apenas para os fins informados neste par&aacute;grafo.</span></p>

        <p><span size="3">A Suppi Ltda tamb&eacute;m poder&aacute; divulgar ou compartilhar dados pessoais para cumprir qualquer obriga&ccedil;&atilde;o legal, regulat&oacute;ria ou contratual, inclusive para fins de cobran&ccedil;a ou para proteger direitos, propriedade ou seguran&ccedil;a da Suppi Ltda, Suppers, fornecedores e parceiros de neg&oacute;cios.</span></p>
        <ul>
            <p><span size="3"><strong>RETEN&Ccedil;&Atilde;O DE DADOS</strong></span></p>
        </ul>
        <p><span size="3">O Suppi Ltda n&atilde;o ir&aacute; reter os dados pessoais dos titulares por mais tempo do que o necess&aacute;rio para os fins para os quais foram coletados ou de outra forma tratados, conforme estabelecido nesta Pol&iacute;tica e em conformidade com o disposto na LGPD.</span></p>
        <ul>
            <p><span size="3"><strong>DIREITOS DOS TITULARES DE DADOS</strong></span></p>
        </ul>
        <p><span size="3">Os titulares dos dados possuem os seguintes direitos:</span></p>
        <p><span size="3">a) Confirmar a exist&ecirc;ncia do tratamento dos dados;</span></p>
        <p><span size="3">b) Solicitar a corre&ccedil;&atilde;o dos dados incompletos, imprecisos ou desatualizados;</span></p>
        <p><span size="3">c) Pedir a elimina&ccedil;&atilde;o ou anonimiza&ccedil;&atilde;o dos dados desnecess&aacute;rios, excessivos ou tratados em desacordo com a LGPD;</span></p>
        <p><span size="3">d) Revogar o consentimento de tratamento dos dados pessoais, por meio de procedimento gratuito e facilitado;</span></p>
        <p><span size="3">e) Realizar oposi&ccedil;&atilde;o ao processamento dos dados se houver descumprimento das disposi&ccedil;&otilde;es da LGPD</span><span size="3"><strong>.</strong></span></p>
        <p><span size="3">Para exercer os direitos acima mencionados, a Suppi Ltda possui um &ldquo;Data Protection Officer&rdquo; (&ldquo;DPO&rdquo;), que tem como uma de suas obriga&ccedil;&otilde;es atender as demandas realizadas por fornecedores e parceiros de neg&oacute;cios relacionados ao tema LGPD. O DPO indicado pela Suppi Ltda &eacute; a Diretoria do Jur&iacute;dico e Compliance, e ser&aacute; poss&iacute;vel acess&aacute;-la atrav&eacute;s do seguinte e-mail:&nbsp;</span><span size="3"><u>legal</u></span><span size="3"><u>@suppi</u></span><span size="3"><u>mais</u></span><u>.com</u></p>
        <ul>
            <p><span size="3"><strong>MEDIDAS DE SEGURAN&Ccedil;A</strong></span></p>
        </ul>
        <p><span size="3">A Suppi Ltda tem o compromisso com a seguran&ccedil;a dos dados pessoais e toma precau&ccedil;&otilde;es para manter esta prote&ccedil;&atilde;o.</span></p>
        <p><span size="3">Os dados pessoais ser&atilde;o protegidos de acordo com as medidas administrativas, sistemas de tecnologia da informa&ccedil;&atilde;o e de seguran&ccedil;a f&iacute;sica implementadas pela Suppi Ltda usualmente adotados pelo mercado para proteger os dados contra acessos n&atilde;o autorizados ou vazamentos.</span></p>
        <p><span size="3">A Suppi Ltda compromete-se a informar aos Suppers, fornecedores e parceiros de neg&oacute;cios na hip&oacute;tese de ocorrer situa&ccedil;&otilde;es de incidentes relacionados com os dados.</span></p>
        <ul>
            <p><span size="3"><strong>MEDIDAS DE SEGURAN&Ccedil;A</strong></span></p>
        </ul>
        <p><span size="3">Monitorar e garantir a conformidade do tratamento de dados de clientes, fornecedores e parceiros de neg&oacute;cios fazem parte das responsabilidades do DPO da Suppi Ltda. Na hip&oacute;tese de necessitar solicitar esclarecimentos ou realizar uma reclama&ccedil;&atilde;o, ser&aacute; poss&iacute;vel acessar o DPO atrav&eacute;s do seguinte e-mail:&nbsp;</span><span size="3"><u>legal</u></span><span size="3"><u>@suppi</u></span><span size="3"><u>mais</u></span><u>.com</u></p>
        <p><span size="3">Esta Pol&iacute;tica poder&aacute; ser revisada, a qualquer tempo, de acordo com eventuais altera&ccedil;&otilde;es legais ou devido a conveni&ecirc;ncia da Suppi Ltda .</span></p>
        <p><span size="3">Esta Pol&iacute;tica entra imediatamente em vigor na data de sua divulga&ccedil;&atilde;o.</span></p>
        <p><span size="3">Vers&atilde;o: 21.12.2022</span></p>
        </>
    )
}

export default PrivacyPage;