export const ExceptionsHandler = (err) => {
  if (err.hasOwnProperty('response') && err.response.hasOwnProperty('data')){
    const { data } = err.response;
    if (data.message){
      return { state: true, message: data["message"], type: 'error', header: "Ops, tivemos um problema:" }
    }
    console.log("ERRO:: ", data)
    return { 
      state: true, 
      type: "error", 
      header: "Desculpe, infelizmente tivemos um problema!" ,
      message: "Caso esse erro persista favor entrar em contato com \'suporte@suppimais.com.br\'.", 
    }
  }else {
    console.log(err)
  }

};
