import { client } from './client';

export const refreshUser = (refresh_token) => {
  return client.post(`/user/refresh`, {refresh_token: refresh_token});
};

export const loginUser = (user) => {
  return client.post(`/user/login`, user);
};

export const logoutUser = (session_token) => {
  return client.post(`/user/logout`, {}, { headers: { Authorization: session_token } });
};

export const forgotPassword = (data) => {
  return client.post(`/user/forgot/password`, data);
};

export const validateCode = (data) => {
  return client.post(`/user/validate/code`, data);
};

export const newPassword = (data) => {
  return client.patch(`/user/code/password`, data);
};