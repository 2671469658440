import { FormControl, Input, InputLabel, MenuItem, Select } from '@mui/material';
import React, { useContext, useEffect } from 'react';
import Context from '../../../../context/Context';
import { calcPlan, customMask, getDisccount } from '../../../../utils/helpers';

function AddPlanStep() {    
    const { planData, setPlanData } = useContext(Context);
    
    const handleUserBlur = () => {
        if (planData.allowed_accounts < 1) {
            setPlanData({ 
                ...planData, 
                allowed_accounts: 1,
                paid_value: calcPlan(1),
                allowed_monthly_rb: 1*40
            });
        } else if (planData.allowed_accounts > 300) {
            setPlanData({ 
                ...planData, 
                allowed_accounts: 300,
                paid_value: calcPlan(300),
                allowed_monthly_rb: 300*40
            });
        }
      };

    const handleChange = ({ name, value }) => {
        if (name === "allowed_accounts"){
            setPlanData({ 
                ...planData, 
                [name]: customMask(name, value),
                paid_value: calcPlan(value),
                allowed_monthly_rb: value*40
            });
        } else{
            setPlanData({ 
                ...planData, 
                [name]: customMask(name, value)
            });
        }
      };

    const getPlanFinalValue = () => {
        if ( planData.paid_value !== undefined){
            return (planData.paid_value*getDisccount(planData.plan)).toFixed(2)
        }
        return 0.00

    };
    
    useEffect(() => {
        if (planData.plan === undefined){
            let allowed_accounts = localStorage.getItem('value_user')
            if (allowed_accounts === null){
                allowed_accounts = 10
            }
            let plan = localStorage.getItem('plan')
            if (plan === null){
                plan = 'Anual'
            }
            setPlanData({
                allowed_accounts: allowed_accounts,
                plan: plan,
                paid_value: calcPlan(allowed_accounts),
                allowed_monthly_rb: allowed_accounts*40
            });
        }
    }, []);
    
    return (
    <div style={{
        borderStyle: "solid", 
        padding: "0px 10px 20px 10px", 
        borderWidth: "1.5px",
        borderRadius: "10px",
        borderColor: "#48c0ff"}}>
        <p style={{
            textAlign: "center"
        }}>
            <b>Confirme o Plano:<br/></b>
        </p>
        <div style={{alignItems: 'center', textAlign: "center", paddingBottom: "15px"}}>
            <FormControl className="input-type">
            <InputLabel id="type-label">Período</InputLabel>
            <Select
                labelId="plan-label"
                name="plan"
                required
                value={planData.plan || ''}
                label="Plano"
                onChange={({ target }) => handleChange(target)}>
                <MenuItem value="Mensal">Mensal</MenuItem>
                <MenuItem value="Trimestral">Trimestral (8% OFF)</MenuItem>
                <MenuItem value="Anual">Anual (15% OFF)</MenuItem>
            </Select>
            </FormControl>
        </div>
            <b>Número de usuários</b>: 
            <Input
                size="small"
                name="allowed_accounts"
                onChange={({ target }) => handleChange(target)}
                value={planData.allowed_accounts || ''}
                onBlur={handleUserBlur}
                inputProps={{
                step: 1,
                min: 1,
                max: 300,
                type: 'number',
                }}
                style={{paddingLeft: "15px"}}
            /><br/>
            <b>Número de despesas</b>: {planData.allowed_monthly_rb} <br/>
            <b>Valor mensal</b>: R$ {planData.paid_value} 
            <i style={{color: "#2400b4"}}> - {Math.abs((getDisccount(planData.plan))*100-100)}%
            </i> = <b style={{color: "green"}}>
                R$ {getPlanFinalValue()}
            </b><br/>
    </div>
);
}
export default AddPlanStep;
