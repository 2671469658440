import React, { useContext } from 'react';
import { Alert, AlertTitle } from '@mui/material';
import Context from '../../context/Context';
import PropTypes from 'prop-types';


function AlertDialog() {
  const { alert, setAlert } = useContext(Context);

  const handleClose = () => {
    setAlert({ state: false });
  };

  setTimeout(() => {
    handleClose();
  }, alert.type === 'error' ? 10000 : 5000);

  return (
    <Alert
      severity={alert.type}
      onClose={handleClose}
      variant="filled"
      sx={{position: "fixed", top: 80, right: 0, width: "300px", zIndex: 99999}}
    >
      <AlertTitle>{alert.header}</AlertTitle>
      {alert.message}
    </Alert>
  );
};

AlertDialog.propTypes = {
  state: PropTypes.bool,
  type: PropTypes.string,
  message: PropTypes.string,
  header: PropTypes.string,
};

export default AlertDialog;