import { client } from './client';

export const getOperations = (filter, session_token) => {
    return client.get(`/operation/`, { params: filter, headers: { Authorization: session_token } });
};

export const getOperationsList = (filter, session_token) => {
    return client.get(`/operation/list`, { params: filter, headers: { Authorization: session_token } });
};

export const createOperation = (operation, session_token) => {
    return client.post(`/operation/`, operation, { headers: { Authorization: session_token } });
};

export const updateOperation = (operation, session_token) => {
    return client.patch(`/operation/`, operation, { headers: { Authorization: session_token } });
};

export const deleteOperation = (id, session_token) => {
    return client.delete(`/operation/`, { data: {id: id}, headers: { Authorization: session_token } });
};

export const postInvoice = (operation, session_token) => {
    return client.post(`/operation/generate/invoice`, operation, { headers: { Authorization: session_token } });
};