import React from 'react';
import { Grid } from '@mui/material';
import logo_mini_suppi from '../../../assets/images/suppi_logo_mini_positivo2.jpg'

function WhoWeAre() {


return (
    <div>
        <Grid container  style={{ 
            backgroundColor: 'white',
            textAlign: 'center', padding: '25px',
            display: 'flex',
            justifyContent: 'center',
            alignItems: 'center',

        }}>
                <Grid item sm={0} md={.5}></Grid>
                <Grid item sm={12} md={4}>
                    <div>
                        <img
                        src={logo_mini_suppi}
                        alt="logo_suppi_mini"
                        width="100%"
                        style={{borderRadius: "10%", 
                        maxWidth: "400px"}}/>
                    </div>
                </Grid>
                <Grid item sm={12} md={7}>
                    <div>
                        <h1>Quem Somos?</h1>
                        <p>Startup fundada por uma dupla que se dedicou a entender as necessidades do mundo corporativo, 
                            a <i>Suppi+ Gestão de Despesas</i> foi desenhada com o objetivo de facilitar o dia a dia do 
                            gestor e de seus funcionários. Nossa proposta é desenvolver soluções inteligentes para 
                            que seu funcionário não precise despender horas em atividades repetitivas, assim, valorizamos seus 
                            colaboradores e sua empresa cresce. A Suppi+ se encaixa na sua realidade, no seu tamanho, 
                            sempre com uma meta: transformar os processos para que o mundo digital traga mais praticidade, 
                            rapidez e controle à gestão da sua empresa.</p>
                    </div>
                </Grid>
                <Grid item sm={0} md={.5} style={{ backgroundColor: 'white'}}></Grid>
            </Grid>
    </div>
    );
}

export default WhoWeAre;