import React, { useState }from 'react';
import FirstSection from './homepage_sections/FirstSection';
import IlustrationSection from './homepage_sections/IlustrationSection';
import FeaturesSection from './homepage_sections/FeaturesSection';
import WhoWeAre from './homepage_sections/WhoWeAre';
import TeamSection from './homepage_sections/TeamSection';
import Footer from '../Footer';
import PlansSection from './homepage_sections/PlansSection';
import WhatsAppIcon from '@mui/icons-material/WhatsApp';
import DashboardSection from './homepage_sections/DashboardSection';


function HomePage() {
    const [isHover, setIsHover] = useState(false);

    const handleMouseEnter = () => {
       setIsHover(true);
    };
 
    const handleMouseLeave = () => {
       setIsHover(false);
    };

    const WhatsAppStyle = {     
        position: "fixed",
        width: "70px",
        height: "70px",
        bottom: "40px",
        right: "40px",
        backgroundColor: "#25d366",
        color: "#fff",
        borderRadius: "50px",
        textAlign: "center",
        fontSize: "30px",
        boxShadow: "1px 1px 2px #888",
        backgroundColor: isHover ? '#2400b4' : '#25d366',
        zIndex: 1201,
     };

    return (
        <div>
            <a 
                href={"https://wa.me/5513981363387?text=Ol%C3%A1%2C+gostaria+de+mais+informa%C3%A7%C3%B5es+sobre+a+plataforma+Suppi%2B."} 
                target="_blank" 
                rel="noreferrer"
                style={WhatsAppStyle}
                onMouseEnter={handleMouseEnter}
                onMouseLeave={handleMouseLeave}>
                <WhatsAppIcon fontSize="large" style={{marginTop: "18px"}}/>
            </a>
            <FirstSection/>
            {/* <IlustrationSection/> */}
            <FeaturesSection/>
            <DashboardSection/>
            <WhoWeAre/>
            <PlansSection/>
            <TeamSection/>
            <Footer/>
        </div>
    )
}

export default HomePage;