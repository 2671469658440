import React, { useContext } from 'react';
import { Grid, Dialog, Button, useMediaQuery } from '@mui/material';
import invoice_example from '../../../assets/images/homepage/invoice_example.png'
import invoice_example_2 from '../../../assets/images/homepage/invoice_example_attachments.png'
import happy_office from '../../../assets/images/homepage/happy_office.jpg'
import Context from '../../../context/Context';
import { useTheme } from '@emotion/react';

function FirstSection() {

const [open, setOpen] = React.useState(false);
const { navigate } = useContext(Context);
const theme = useTheme();

const toLogin = () => {
    navigate('/login');
  };

const handleClickOpen = () => {
  setOpen(true);
};

const handleClose = () => {
  setOpen(false);
};

const styleForGrid = {
    backgroundImage: `url(${happy_office})`,
    backgroundRepeat: 'no-repeat', 
    backgroundSize: 'cover',
    backgroundAttachment: (useMediaQuery(theme.breakpoints.up('sm'))) ? 'fixed' : 'scroll',
    backgroundPosition: 'center',
    backgroundColor: '#2400b4',
    textAlign: 'center', 
    color: 'white', 
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    textAlign: 'left',
    fontSize: "20px",
    marginTop: "-65px",

}

return (
    <div className='first-section' style={styleForGrid}>
        {open &&
        <Dialog open={open} onClose={handleClose}>
          <img src={invoice_example} alt="Exemplo de fatura" />
          <img src={invoice_example_2} alt="Exemplo de fatura" />
        </Dialog>
        }
        <Grid container style={{backgroundColor: 'rgba(36,0,180,0.7)'}}>
            <Grid item xs={0} sm={0} md={.5}></Grid>
            <Grid item xs={12} sm={12} md={6}>
                <div style={{padding: "75px 25px 25px 25px"}}>
                    <h2>Uma plataforma para a gestão das despesas da sua empresa: pensada especialmente como solução B2B!</h2>
                    <p>
                    <b>Agora você pode gerar faturas personalizadas e reduzir o seu tempo com processos lentos e manuais.</b><br/>
                    </p>
                    <p style={{
                        fontSize: "18px", 
                        textAlign: 'justify',
                        textJustify: 'inter-word',
                    }}>
                        Conheça a Suppi Mais e a nova forma de gerenciar os gastos dos seus colaboradores e franquias. 
                        Somos uma solução inteligente que automatiza desde o seu processo de reembolsos e pagamentos 
                        até a comunicação com o seu cliente. Com a Suppi Mais, tenha mais controle dos seus gastos, 
                        deixe mais profissional a cobrança para o seu cliente e melhore a cultura interna do seu escritório!
                    </p>
                </div>
            </Grid>
            <Grid item xs={12} sm={12} md={5}>
            
                <div style={{
                padding: "75px 25px 25px 25px",
                display: 'flex',
                justifyContent: 'center',
                alignItems: 'center',
                textAlign: 'center',
                fontSize: '15px'}}>
                    <figure>
                        <img
                        src={invoice_example}
                        alt="invoice"
                        width="100%"
                        onClick={handleClickOpen}
                        style={{borderRadius: "10%",
                        maxWidth: "380px",
                        boxShadow: '0 4px 8px 0 rgba(0, 0, 0, 0.2), 0 6px 20px 0 rgba(0, 0, 0, 0.19)'}}/>
                        <figcaption>Clique na imagem para ver mais</figcaption>
                    </figure>
                </div>
            </Grid>
            <Grid item xs={0} sm={0} md={.5}></Grid>
        </Grid>
    </div>
    );
}

export default FirstSection;