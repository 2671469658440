import React, { useContext, useState, useEffect } from 'react';
import PropTypes from 'prop-types';
import Context from '../../../context/Context';
import {
  Tooltip,
  IconButton,
  Button
} from '@mui/material';
import { Dialog, DialogActions, DialogContent, DialogTitle } from '@mui/material';
import CloseIcon from '@mui/icons-material/Close';
import { getUsersDebts } from '../../../services/dashboards';
import { ExceptionsHandler } from '../../../services/exceptions';
import { DataGrid, ptBR as dataGridLocale } from '@mui/x-data-grid';
import { CustomPagination } from '../../../utils/customGrid';
import { debtsUserForm } from '../../../utils/registerFields';
import CollaboratorsDebtDialog from './CollaboratorsDebtDialog';
import { changeAdminPermission } from '../../../services/user';
import DeleteDialog from '../DeleteDialog';

function CollaboratorsFormDialog({ row }) {
  const { setAlert } = useContext(Context);
  const { dialog, setDialog } = useContext(Context);
  const [debtsData, setDebtsData] = useState([]);
  const [editRow, setEditRow] = useState({});
  const { setConfirmeDialog, setConfirmeDialogSecond } = useContext(Context);
  const { refresh, setRefresh } = useContext(Context);
  const [ filterModel ] = React.useState({ items: [] });
  const [filter, setFilter] = useState({ user_cpf: row.cpf });
  const [page, setPage] = React.useState(0);
  const [loading, setLoading] = useState(false);

  const session_token = localStorage.getItem('session_token');

  const debtsColumns = debtsUserForm.map(({ name, label, width, align, type, hiden }) => ({
    field: name,
    headerName: label,
    flex: width,
    minWidth: 100 * width,
    align: align,
    headerAlign: align,
    type: type,
    hide: hiden
  }));

  const debtsList = (filter) =>
    getUsersDebts(filter, session_token)
      .then(({ data }) => data)
      .catch((err) => setAlert(ExceptionsHandler(err)));

  const debtsListHandler = async (method) => {
    setLoading(true);
    const prevPage = debtsData.actual_page;
      const data = await debtsList({ ...filter, page: page + 1 });
    const { users } = data;
    if (method === 'add') {
      let newDebts = [];
      for (let i = prevPage + 1; i < page; i++) {
        const remainingData = await debtsList({ ...filter, page: i });
        newDebts.push(remainingData.users);
      }
      newDebts.push(users);
      setDebtsData({ ...data, users: debtsData.users.concat(newDebts.flat()) });
    } else setDebtsData(data);
    setLoading(false);
  };

  const handleChangeAdmin = () => {
    const payload = {user_id: row.id, is_admin: !row.is_admin}
    changeAdminPermission(payload, session_token)
        .then(() => {
          setAlert({
            state: true,
            message: 'Permissão do usuário atualizada com sucesso!',
            type: 'success',
            header: 'Deu certo!'
          });
          setRefresh(true);
          handleClose();
        })
        .catch((err) => {
          setAlert(ExceptionsHandler(err));
        });
  }

  const handleClickOpen = () => {
    setConfirmeDialog(true);
  };

  const parseFilter = () => {
    filterModel.items.forEach((item) => {
      if (item) setFilter({ ...filter, page: page + 1, [item.columnField]: item.value });
    });
    return filter;
  };

  const handleClose = () => {
    setDialog(false);
  };

  const handleDialogDebts = (row) => {
    setEditRow(row);
    setConfirmeDialogSecond(true);
  };

  useEffect(() => {
    if (refresh === true) {
      setFilter({});
      setPage(0);
      setDebtsData([]);
      setRefresh(false);
    }
  }, [refresh]);

  useEffect(() => {
    parseFilter();
  }, [
    filterModel.items != [] &&
      filterModel.items.filter((item) => item.value != undefined).length > 0
  ]);

  useEffect(() => {
    debtsListHandler();
  }, [filter, row]);

  useEffect(() => {
    debtsData.has_next && debtsData.actual_page < page ? debtsListHandler('add') : null;
  }, [page]);

  return (
    <>
      <DeleteDialog row={row} option="user" />
      <CollaboratorsDebtDialog row={editRow}/>
      <Dialog open={dialog} onClose={handleClose} fullWidth maxWidth="xl" sx={{zIndex: '1202'}}>
        <DialogTitle>
          Débitos ao Colaborador <b>{row.name}</b>
          <Tooltip title="Fechar">
            <IconButton
              color="secondary"
              onClick={handleClose}
              sx={{ position: 'absolute', top: 0, right: 0 }}>
              <CloseIcon />
            </IconButton>
          </Tooltip>
        </DialogTitle>
        <DialogContent style={{ padding: '20px' }}>
          <div style={{ width: '100%' }}>
            <DataGrid
              filterModel={filterModel}
              onFilterModelChange={(newFilterModel) => handleFilterModelChange(newFilterModel)}
              getRowId={(row) => row.user_id}
              rows={debtsData.users || []}
              columns={debtsColumns}
              pageSize={10}
              rowCount={(debtsData.total_pages || 1) * 10}
              page={page}
              autoHeight
              loading={loading}
              disableSelectionOnClick
              onRowDoubleClick={({ row }) => handleDialogDebts(row)}
              onPageChange={(newPage) => (newPage != 'NaN' ? setPage(newPage) : null)}
              //   onRowDoubleClick={({ row }) => handleDialogReimbursement(row)}
              localeText={dataGridLocale.components.MuiDataGrid.defaultProps.localeText}
              components={{
                Pagination: CustomPagination
              }}
            />
          </div>
        </DialogContent>
        <DialogActions sx={{ justifyContent: 'space-between' }}>
          <Button variant="delete" onClick={handleClickOpen}>Remover Usuário</Button>
          {row.is_admin && (<Button onClick={handleChangeAdmin}>Remover Administração</Button>)}
          {!row.is_admin && (<Button onClick={handleChangeAdmin}>Tornar Administrador</Button>)}
        </DialogActions>
      </Dialog>
    </>
  );
}

CollaboratorsFormDialog.propTypes = {
  row: PropTypes.object
};

export default CollaboratorsFormDialog;
