import React, { useContext, useState } from 'react';
import PropTypes from 'prop-types';
import Context from '../../../context/Context';
import {
  Tooltip,
  IconButton,
  TextField,
  Button,
} from '@mui/material';
import { Dialog, DialogActions, DialogContent, DialogTitle } from '@mui/material';
import SaveIcon from '@mui/icons-material/Save';
import CloseIcon from '@mui/icons-material/Close';
import { customMask, formatObject } from '../../../utils/helpers';
import { ExceptionsHandler } from '../../../services/exceptions';
import { cardEditForm } from '../../../utils/registerFields';
import { postSubscription, updateSubscriptionCard } from '../../../services/product';

function EditCardOrAddPlanFormDialog( { variant } ) {
  const { setAlert } = useContext(Context);
  const { dialog, setDialog } = useContext(Context);
  const { setRefresh } = useContext(Context);
  const [cardToUpdate, setCardToUpdate] = useState({});

  const session_token = localStorage.getItem('session_token');

  const formatCardPayload = (payload) => {
    const line_1 = payload.address_number + ", " + payload.street + ", " + payload.neighborhood
    
    const correct_payload = {
      ...payload,
      number: payload.card_number,
      billing_address: {
        line_1: line_1,
        line_2: payload.complement,
        zip_code: payload.zip_code,
        city: payload.city,
        state: payload.state,
        country: payload.country
      }
    } 
    return correct_payload
  }

  const validateCardInfo = () => {
    let missing = ""
    cardEditForm.map(({name, label, required }) => {
      if ((cardToUpdate[name] === undefined || cardToUpdate[name] === "") && required){
        missing = missing + label + "; "
      }

    })
    if (missing !== ""){
      setAlert({
        state: true,
        message: 'Por favor preencha todos os campos com \'*\' antes de continuar. Faltantes: ' + missing,
        type: 'error',
        header: 'Campos faltantes'
      });
      return false
    }

    return true
  }

  const updateSubscriptionCardHandler = () => {
    if (validateCardInfo()) {
      updateSubscriptionCard(formatObject(formatCardPayload(cardToUpdate)), session_token)
        .then(async () => {
          setAlert({
            state: true,
            message: 'Cartão foi atualizado com sucesso!',
            type: 'success',
            header: 'Deu certo!'
          });
        })
        .catch((err) => {
          setAlert(ExceptionsHandler(err));
        })
        .finally(() => {
          handleClose();
          setRefresh(true);
        });
    }
  };

  const addSubscriptionHandler = () => {
    if (validateCardInfo()) {
      postSubscription(formatObject({card: formatCardPayload(cardToUpdate)}), session_token)
        .then(async () => {
          setAlert({
            state: true,
            message: 'Assinatura criada com sucesso!',
            type: 'success',
            header: 'Bem vindo!'
          });
          handleClose();
        })
        .catch((err) => {
          setAlert(ExceptionsHandler(err));
        })
        .finally(() => {
          handleClose();
          setRefresh(true);
        });
    }
  };

  const handleClose = () => {
    setCardToUpdate({});
    setDialog(false);
  };
  
  const handleChange = ({ name, value }) => {
    setCardToUpdate({ ...cardToUpdate, [name]: customMask(name, value) });
  };

  return (
    <>
      <Dialog open={dialog} onClose={handleClose} sx={{zIndex: '1202'}}>
        <DialogTitle>
        {variant === "edit"? "Editar Cartão de Crédito" : "Assinar Plano"}
          <Tooltip title="Fechar">
            <IconButton
              color="secondary"
              onClick={handleClose}
              sx={{ position: 'absolute', top: 0, right: 0 }}>
              <CloseIcon />
            </IconButton>
          </Tooltip>
        </DialogTitle>
        <DialogContent style={{ 
          padding: '20px'}}>
          <div style={{
            borderStyle: "solid", 
            padding: "0px 10px 20px 10px", 
            borderWidth: "1.5px",
            borderRadius: "10px",
            borderColor: "#48c0ff"}}>

            <p style={{
              textAlign: "center", 
              
            }}>
                <b>Preencha os dados do cartão de crédito e o endereço de cobrança:<br/></b>
                <i style={{fontSize: '14px'}}>Cobrança segura intermediada pela Pagar.me - Stone</i>
            </p>
            <div className="form form-card-update">
              {cardEditForm
                .map(({ name, label, required }, index) => (
                  <TextField
                    key={index}
                    name={name}
                    required={required}
                    value={cardToUpdate[name] ? cardToUpdate[name] : ''}
                    className={`input-${name}`}
                    label={label}
                    color="secondary"
                    variant="outlined"
                    onChange={({ target }) => handleChange(target)}>
                  </TextField>
                ))}
            </div>
          </div>
        </DialogContent>
        <DialogActions sx={{ justifyContent: 'right' }}>
          <Tooltip title="Salvar">
            {variant === "edit"? 
            (<Button onClick={updateSubscriptionCardHandler}>
              <SaveIcon style={{paddingRight: "15px"}}/> Atualizar Cartão
            </Button>) : 
            (<Button onClick={addSubscriptionHandler}>
              Assinar Plano
            </Button>)}
          </Tooltip>
        </DialogActions>
      </Dialog>
    </>
  );
}

EditCardOrAddPlanFormDialog.propTypes = {
  variant: PropTypes.string
};

export default EditCardOrAddPlanFormDialog;
