import React, { useContext, useState, useEffect } from 'react';
import { Button, Card, CardHeader, CardContent, Grid, TextField, FormControl, InputLabel, Select, MenuItem } from '@mui/material';
import Context from '../../../context/Context';
import { ExceptionsHandler } from '../../../services/exceptions';
import { DataGrid, ptBR } from '@mui/x-data-grid';
import { customMask, formatObject, renderContract } from '../../../utils/helpers';
import { getInvoices } from '../../../services/dashboards';
import { CustomPagination } from '../../../utils/customGrid';
import InvoiceFormDialog from '../../dialogs/invoiceDialog/InvoiceFormDialog';
import InvoiceRegisterDialog from '../../dialogs/invoiceDialog/InvoiceRegisterDialog';
import AddCircleIcon from '@mui/icons-material/AddCircle';
import { invoiceForm } from '../../../utils/registerFields';
import { CleaningServices, FilterAlt, FilterAltOff } from '@mui/icons-material';

function Invoices() {
  const { setAlert } = useContext(Context);
  const { dialog, setDialog } = useContext(Context);
  const { refresh, setRefresh } = useContext(Context);
  const { registerDialog, setRegisterDialog } = useContext(Context);
  const [invoicesData, setInvoicesData] = useState([]);
  const [editRow, setEditRow] = useState({});
  const [filter, setFilter] = useState({});
  const [page, setPage] = React.useState(0);
  const [loading, setLoading] = useState(false);
  // Filter section vars
  const [ filterSection, setFilterSection ] = useState(false);
  const [ isPaidLabel, setIsPaidLabel ] = useState("");

  const session_token = localStorage.getItem('session_token');

  const handleValues = (params) => {
    const { field, value } = params;
    return customMask(field, value);
  };

  const invoiceColumns = invoiceForm.map(({ name, label, width, align, type, hiden }) => ({
    field: name,
    headerName: label,
    flex: width,
    minWidth: 100 * width,
    align: align,
    headerAlign: align,
    type: type,
    valueOptions:
      name === 'status' ? ['Aberto', 'Fechado', 'Aguardando Pagamento', 'Pendente'] : null,
    hide: hiden,
    filterable: false,
    sortable: false,
    valueGetter: (params) => handleValues(params),
    renderCell: (params) => renderContract(params)
  }));

  const InvoiceList = (filter) =>
    getInvoices(formatObject(filter), session_token)
      .then(({ data }) => data)
      .catch((err) => setAlert(ExceptionsHandler(err)));

  const invoiceListHandler = async (method) => {
    setLoading(true);
    const prevPage = invoicesData.actual_page;
    const data = await InvoiceList({ ...filter, page: page + 1 });
    const { invoices } = data;
    if (method === 'add') {
      let newInvoices = [];
      for (let i = prevPage + 1; i < page; i++) {
        const remainingData = await InvoiceList({ ...filter, page: i });
        newInvoices.push(remainingData.invoices);
      }
      newInvoices.push(invoices);
      setInvoicesData({
        ...data,
        invoices: invoicesData.invoices.concat(newInvoices.flat())
      });
    } else setInvoicesData(data);
    setLoading(false);
  };

  const handleEditInvoice = (row) => {
    setEditRow(row);
    setDialog(true);
  };

  const handleAddInvoice = () => {
    setRegisterDialog(true);
  };

  useEffect(() => {
    if (refresh === true) {
      setPage(0);
      invoiceListHandler();
      setRefresh(false);
    }
  }, [refresh]);

  useEffect(() => {
    invoiceListHandler();
  }, []);

  useEffect(() => {
    invoicesData.has_next && invoicesData.actual_page < page ? invoiceListHandler('add') : null;
  }, [page]);

  //Filter Section Stuff

  const handleAddFilter = () => {
    setFilterSection(!filterSection);
  };

  const mountFilterGrid = () => {
    const styleForGrid = {
      paddingBottom: "10px",
      textAlign: 'center',
      display: 'flex',
      justifyContent: 'center',
      alignItems: 'center',
    }

    const styleForInputs = {
      width: "100%"
    }

    const handleFilterChange = ({ name, value }) => {
      if (value === null || value === ''){
        const filterHelper = filter
        delete filterHelper[name]
        setFilter({...filterHelper})
      }else{
        setFilter({ ...filter, [name]: customMask(name, value) });
      }
    };

    const handleFilterClean = () => {
      setIsPaidLabel("");
      setFilter({});
      setRefresh(true);
    };

    const handleFilterAction = () => {
      setRefresh(true);
    };
    
    return (
      <div style={{paddingBottom: "20px"}}>
        <Grid container>
          <Grid item xs={12}><h3>Filtros:</h3></Grid>
          <Grid item xs={12} sm={4} style={styleForGrid}>
          <TextField
                label="TAG Centro de Custo"
                name="or_tag"
                multiline
                value={filter.or_tag || ''}
                onChange={({ target }) => handleFilterChange(target)}
                style={styleForInputs}
                />
          </Grid>
          <Grid item xs={12} sm={4} style={styleForGrid}>
          <TextField
                label="Descrição"
                name="or_name"
                multiline
                value={filter.or_name || ''}
                onChange={({ target }) => handleFilterChange(target)}
                style={styleForInputs}
                />
          </Grid>
          <Grid item xs={12} sm={4} style={styleForGrid}>
            <TextField
                label="Cliente"
                name="client_name"
                multiline
                value={filter.client_name || ''}
                onChange={({ target }) => handleFilterChange(target)}
                style={styleForInputs}
                />
          </Grid>
          <Grid item xs={12} sm={4} style={styleForGrid}>
            <TextField
                label="Documento do Cliente (CPF/CNPJ)"
                name="client_document_number"
                multiline
                value={filter.client_document_number || ''}
                onChange={({ target }) => handleFilterChange(target)}
                style={styleForInputs}
                />
          </Grid>
          <Grid item xs={12} sm={4} style={styleForGrid}>
            <FormControl fullWidth>
              <InputLabel id="type-label">Pago?</InputLabel>
              <Select
                name="is_paid"
                required
                value={isPaidLabel}
                label="Pago"
                onChange={({target}) => setIsPaidLabel(target.value)}
                style={styleForInputs}>
                <MenuItem value={true}>Sim</MenuItem>
                <MenuItem value={false}>Não</MenuItem>
              </Select>
            </FormControl>
          </Grid>
          <Grid item xs={12} sm={12} style={styleForGrid}></Grid>
          <Grid item xs={12} sm={6} style={styleForGrid}>
            <Button 
              style={{height: "100%"}} 
              onClick={handleFilterAction}>
                Filtrar <FilterAlt/>
            </Button>
          </Grid>
          <Grid item xs={12} sm={6} style={styleForGrid}>
            <Button 
              style={{height: "100%"}}  
              variant="outlined" 
              onClick={handleFilterClean}>
                Limpar <CleaningServices/>
            </Button>
          </Grid>
        </Grid>
      </div>
    )
  }

  useEffect(() => {
    if (isPaidLabel === ""){
      const filterHelper = filter
      delete filterHelper.is_paid
      setFilter({...filterHelper})
    } else {
      setFilter({ ...filter, is_paid: isPaidLabel });
    }
  }, [isPaidLabel]);

  return (
    <div className="page-content">
      {dialog && <InvoiceFormDialog row={editRow} />}
      {registerDialog && <InvoiceRegisterDialog />}
      <Card className="data-grid-card">
        <CardHeader className="card-header" title="Faturas" />
        <CardContent className="card-body">
          <div style={{ width: '100%' }}>
            <div className="data-grid-header">
            <Button 
                style={{width: "auto", marginRight: "5px"}} 
                variant="outlined" 
                onClick={handleAddFilter}>
                  {filterSection ? <FilterAltOff/> : <FilterAlt/>}
                  {Object.keys(filter).length}
              </Button>
              <Button
                id="btn-reimbursements-register"
                variant="outlined"
                onClick={handleAddInvoice}>
                Gerar Fatura
                <AddCircleIcon />
              </Button>
            </div>
            {filterSection && mountFilterGrid()}
            <DataGrid
              rows={invoicesData.invoices || []}
              columns={invoiceColumns}
              pageSize={10}
              rowCount={(invoicesData.total_pages || 1) * 10}
              page={page}
              autoHeight
              loading={loading}
              disableSelectionOnClick
              onPageChange={(newPage) => (newPage != 'NaN' ? setPage(newPage) : null)}
              onRowDoubleClick={({ row }) => handleEditInvoice(row)}
              onCellClick={({ field, value }) => {
                if (field === 's3_path' && value) {
                  window.open(value);
                }
              }}
              localeText={ptBR.components.MuiDataGrid.defaultProps.localeText}
              components={{
                Pagination: CustomPagination
              }}
            />
          </div>
        </CardContent>
      </Card>
    </div>
  );
}

export default Invoices;
