import React, { useContext, useState, useEffect } from 'react';
import PropTypes from 'prop-types';
import Context from '../../../context/Context';
import {
  TextField,
  Tooltip,
  Select,
  MenuItem,
  InputLabel,
  FormControl,
  OutlinedInput,
  IconButton,
  Button,
  FormControlLabel,
  Checkbox
} from '@mui/material';
import { Dialog, DialogActions, DialogContent, DialogTitle } from '@mui/material';
import DeleteIcon from '@mui/icons-material/Delete';
import SaveIcon from '@mui/icons-material/Save';
import CloseIcon from '@mui/icons-material/Close';
import EditIcon from '@mui/icons-material/Edit';
import { LocalizationProvider, DatePicker, ptBR as datePickerLocale } from '@mui/x-date-pickers';
import { AdapterDateFns } from '@mui/x-date-pickers/AdapterDateFns';
import { ptBR as ptBRLocale } from 'date-fns/locale';
import { customMask, formatObject, handleClickOpenFile } from '../../../utils/helpers';
import {
  updateReimbursement,
  createReimbursementFile,
  getReimbursementsUrl
} from '../../../services/reimbursements';
import { sendS3File } from '../../../services/client';
import { ExceptionsHandler } from '../../../services/exceptions';
import DeleteDialog from '../DeleteDialog';

function ReimbursementsFormDialog({ row }) {
  const { setAlert } = useContext(Context);
  const { dialog, setDialog } = useContext(Context);
  const { setConfirmeDialog } = useContext(Context);
  const [reimbursementToUpdate, setReimbursementToUpdate] = useState({});
  const [isPaid, setIsPaid] = useState(row.is_paid);
  const [reimbursementFileSecond, setReimbursementFileSecond] = useState(null);
  const [invoice_dt, setInvoice_dt] = useState(new Date(row.invoice_dt));
  const [edit, setEdit] = useState(false);
  const { setRefresh } = useContext(Context);

  const session_token = localStorage.getItem('session_token');
  const is_admin = localStorage.getItem('is_admin') === 'true';

  const GetReimbursementsUrlHandle = (value) => 
    getReimbursementsUrl(value, session_token)
    .then(({ data }) => data)
      .catch((err) => setAlert(ExceptionsHandler(err)));

  const getS3UrlRb = async (value) => {
    const data = await GetReimbursementsUrlHandle(value);
    const { file_url } = data;
    handleClickOpenFile(file_url);
    return file_url
  };

  const reimbursementUpdateHandler = () => {
    if (reimbursementToUpdate) {
      updateReimbursement(formatObject(reimbursementToUpdate), session_token)
        .then(async () => {
          if (reimbursementFileSecond) await createReimbursementFileHandler(reimbursementToUpdate.id);
          setAlert({
            state: true,
            message: 'Despesa foi atualizado com sucesso!',
            type: 'success',
            header: 'Deu certo!'
          });
        })
        .catch((err) => {
          setDialog(false);
          setAlert(ExceptionsHandler(err));
        })
        .finally(() => {
          setEdit(false);
          handleClose();
          setRefresh(true);
        });
    }
  };

  const createReimbursementFileHandler = async (id) => {
    const file_info = { id: id, is_payment: true, extension: reimbursementFileSecond.type.split('/')[1] };
    const data = await createReimbursementFile(file_info, session_token);
    const { url, fields } = data.data;
    fields.file = reimbursementFileSecond;
    var s3FormData = new FormData();
    for (var key in fields) {
      s3FormData.append(key, fields[key]);
    }
    sendS3File(url, s3FormData);
  };

  const handleChange = ({ name, value }) => {
    if (name === "flow_status" && value === "Aprovado"){
      setReimbursementToUpdate.value_approved = row.value_required
      setReimbursementToUpdate({
        ...reimbursementToUpdate,
        id: row.id,
        value_approved: customMask("value_approved", row.value_required),
        [name]: customMask(name, value),
      });
    }else if (name === "flow_status"){
      setReimbursementToUpdate.value_approved = row.value_required
      setReimbursementToUpdate({
        ...reimbursementToUpdate,
        id: row.id,
        value_approved: customMask("value_approved", 0.0),
        [name]: customMask(name, value),
      });
    }else{
      setReimbursementToUpdate.value_approved = row.value_required
      setReimbursementToUpdate({
        ...reimbursementToUpdate,
        id: row.id,
        [name]: customMask(name, value),
      });
    }
    
  };

  const handleClose = () => {
    setReimbursementToUpdate({});
    setReimbursementFileSecond(null);
    setDialog(false);
  };

  const handleClickOpen = () => {
    setConfirmeDialog(true);
  };

  const handleEdit = () => {
    setEdit(true);
  };

  useEffect(() => {
    setReimbursementToUpdate({ ...reimbursementToUpdate, invoice_dt: invoice_dt });
  }, [invoice_dt]);

  const handleFile = (e) => {
    const file = e.target.files[0];
    file['dispotition'] = 'attachment';
    const file_type = file.type.split('/')[1];
    const valid_file_types = ['pdf', 'png', 'jpg', 'jpeg', 'heic', 'webp'];
    if (valid_file_types.includes(file_type)) {
        setReimbursementFileSecond(file);
        setIsPaid(true)
        setReimbursementToUpdate({ ...reimbursementToUpdate, id: row.id, is_paid: true });
    } else {
      setAlert({
        state: true,
        message: "Infelizmente não aceitamos o formato escolhido, por favor escolha um arquivo desses formatos: " + valid_file_types,
        type: 'error',
        header: 'Ops, formato de arquivo inválido!'
      });
    } 
  };

  const handleChangeCheckBox = (event) => {
    const { name, checked } = event.target;
    if(name === "is_paid"){
      setIsPaid(checked)
    }
    setReimbursementToUpdate({ ...reimbursementToUpdate, id: row.id, [name]: checked });
  };

  return (
    <>
      <DeleteDialog row={row} option="reimbursement" />
      <Dialog open={dialog} onClose={handleClose} sx={{zIndex: '1202'}}>
        <DialogTitle>
          Editar {row.expenses_type}
          <Tooltip title="Fechar">
            <IconButton
              color="secondary"
              onClick={handleClose}
              sx={{ position: 'absolute', top: 0, right: 0 }}>
              <CloseIcon />
            </IconButton>
          </Tooltip>
        </DialogTitle>
        <DialogContent style={{ padding: '20px' }}>
          <div className="form form-reimbursement-update">
            <FormControl className="input-type">
              <InputLabel id="type-label">Categoria</InputLabel>
              <Select
                labelId="type-label"
                name="type"
                value={reimbursementToUpdate.type}
                defaultValue={row.type}
                label="Categoria"
                onChange={({ target }) => handleChange(target)}
                disabled={!edit}>
                <MenuItem value="Alimentação">Alimentação</MenuItem>
                <MenuItem value="Transporte">Transporte</MenuItem>
                <MenuItem value="Hospedagem">Hospedagem</MenuItem>
                <MenuItem value="Custas Judiciais">Custas Judiciais</MenuItem>
                <MenuItem value="Outros">Outros</MenuItem>
              </Select>
            </FormControl>
            <FormControl className="input-value">
              <InputLabel htmlFor="reimbursement_value">Valor Requerido</InputLabel>
              <OutlinedInput
                id="reimbursement_value"
                name="value_required"
                label="Valor Requerido"
                value={reimbursementToUpdate.value_required}
                defaultValue={customMask('value_required', row['value_required'])}
                placeholder=" R$ 0,00"
                onChange={({ target }) => handleChange(target)}
                disabled
              />
            </FormControl>
            <Button variant="outlined" disabled={!row.file_url} onClick={() => getS3UrlRb(row.file_url)}>
              {row.file_url ? "Baixar Anexo da Despesa" : "Não possui anexo"}
            </Button>
            <LocalizationProvider
              dateAdapter={AdapterDateFns}
              adapterLocale={ptBRLocale}
              localeText={
                datePickerLocale.components.MuiLocalizationProvider.defaultProps.localeText
              }>
              <DatePicker
                disableFuture={row.expenses_type === "Solicitação de Reembolso"}
                className="input-invoice_dt"
                name="invoice_dt"
                label={row.expenses_type === "Solicitação de Pagamento"? "Data de Vencimento" : "Data da Despesa"}
                openTo="year"
                views={['year', 'month', 'day']}
                inputFormat="dd/MM/yyyy"
                value={reimbursementToUpdate.invoice_dt}
                onChange={(newValue) => setInvoice_dt(newValue)}
                renderInput={(params) => <TextField {...params} />}
                disabled={!edit}
              />
            </LocalizationProvider>
            <TextField
              className="input-description"
              label="Descrição"
              name="description"
              multiline
              value={reimbursementToUpdate.description}
              defaultValue={row.description}
              onChange={({ target }) => handleChange(target)}
              disabled={!edit}
            />
            <FormControl className="input-flow_status">
              <InputLabel id="type-label-flow_status">Status</InputLabel>
              <Select
                labelId="type-label-flow_status"
                name="flow_status"
                value={reimbursementToUpdate.flow_status}
                defaultValue={row.flow_status}
                label="Status"
                onChange={({ target }) => handleChange(target)}
                disabled={!edit}>
                <MenuItem value="Pendente">Pendente</MenuItem>
                <MenuItem value="Aprovado">Aprovado</MenuItem>
                <MenuItem value="Reprovado">Reprovado</MenuItem>
              </Select>
            </FormControl>
            <FormControl className="input-value_approved">
              <InputLabel htmlFor="reimbursement_value_approved">Valor Aprovado</InputLabel>
              <OutlinedInput
                id="reimbursement_value_approved"
                name="value_approved"
                label="Valor Aprovado"
                value={reimbursementToUpdate.value_approved}
                defaultValue={customMask('value_approved', row['value_approved'])}
                placeholder=" R$ 0,00"
                onChange={({ target }) => handleChange(target)}
                disabled={!edit}
              />
            </FormControl>
            {(row.expenses_type === "Solicitação de Pagamento" && 
            (reimbursementToUpdate.flow_status == "Aprovado" || row.flow_status == "Aprovado")) && 
              (
                <label htmlFor="raised-button-file">
                  <input
                    accept=".pdf, .png, .jpg, .jpeg, .heic, .webp"
                    style={{ display: 'none' }}
                    id="raised-button-file"
                    type="file"
                    disabled={!edit}
                    onChange={handleFile}/>
                    <TextField
                      className="input-file"
                      component="label"
                      required
                      label={row.file_url_payment ? "Atualizar Comprovante de Pagamento" : "Adicionar Comprovante de Pagamento"}
                      value={reimbursementFileSecond ? reimbursementFileSecond.name : ''}
                      sx={{ width: '100%', pointerEvents: 'none' }}
                    />
                </label>
              )
            }
            {(row.expenses_type === "Solicitação de Pagamento" && 
            (reimbursementToUpdate.flow_status == "Aprovado" || row.flow_status == "Aprovado")) && 
              (
                <Button 
                  variant="outlined" 
                  disabled={!row.file_url_payment || reimbursementFileSecond} 
                  onClick={() => getS3UrlRb(row.file_url_payment)}>
                    {reimbursementFileSecond ? "Salve p/ atualizar" :
                      (row.file_url_payment ? "Baixar Comprovante" : "Não possui Comprovante")}
                </Button>
              )
            }
            <FormControlLabel
              label="Despesa interna"
              disabled={!edit}
              control={
                <Checkbox
                  defaultChecked={row.is_internal}
                  onChange={handleChangeCheckBox}
                  name="is_internal"
                  color="secondary"
                />
              }
            />
            <FormControlLabel
              label="Pago"
              disabled={!edit}
              control={
                <Checkbox
                  checked={isPaid}
                  onChange={handleChangeCheckBox}
                  name="is_paid"
                  color="secondary"
                />
              }
            />
          </div>
        </DialogContent>
        <DialogActions sx={{ justifyContent: 'space-between' }}>
          <Tooltip title="Deletar">
            <IconButton size="large" color="error" onClick={handleClickOpen}>
              <DeleteIcon fontSize="inherit" />
            </IconButton>
          </Tooltip>
          {is_admin && (
            <>
              {!edit && (
                <Tooltip title="Editar">
                  <IconButton color="secondary" onClick={handleEdit}>
                    <EditIcon />
                  </IconButton>
                </Tooltip>
              )}
              {edit && (
                <Tooltip title="Salvar">
                  <IconButton size="large" color="primary" onClick={reimbursementUpdateHandler}>
                    <SaveIcon fontSize="inherit" />
                  </IconButton>
                </Tooltip>
              )}
            </>
          )}
        </DialogActions>
      </Dialog>
    </>
  );
}

ReimbursementsFormDialog.propTypes = {
  row: PropTypes.object
};

export default ReimbursementsFormDialog;
