import React, { useContext, useState } from 'react';
import PropTypes from 'prop-types';
import Context from '../../../context/Context';
import { TextField, MenuItem, Tooltip, IconButton } from '@mui/material';
import { Dialog, DialogActions, DialogContent, DialogTitle } from '@mui/material';
import { clientsFormRegister } from '../../../utils/registerFields';
import { customMask, formatObject } from '../../../utils/helpers';
import { ExceptionsHandler } from '../../../services/exceptions';
import DeleteDialog from '../DeleteDialog';
import DeleteIcon from '@mui/icons-material/Delete';
import SaveIcon from '@mui/icons-material/Save';
import CloseIcon from '@mui/icons-material/Close';
import EditIcon from '@mui/icons-material/Edit';
import { updateClients } from '../../../services/clients';

function ClientsFormDialog({ row }) {
  const { setAlert } = useContext(Context);
  const { dialog, setDialog } = useContext(Context);
  const { setConfirmeDialog, setRefresh } = useContext(Context);
  const [clientsToUpdate, setClientsToUpdate] = useState({});
  const [edit, setEdit] = useState(false);

  const session_token = localStorage.getItem('session_token');

  const statusOptions = [
    {
      value: 'Open',
      label: 'Aberto'
    },
    {
      value: 'Waiting for payment',
      label: 'Aguardando pagamento'
    },
    {
      value: 'Paid',
      label: 'Pago'
    }
  ];

  const clientsUpdateHandler = () => {
    if (clientsToUpdate) {
      updateClients(formatObject(clientsToUpdate), session_token)
        .then(() => {
          setAlert({
            state: true,
            message: 'Cliente foi atualizado com sucesso!',
            type: 'success',
            header: 'Deu certo!'
          });
        })
        .catch((err) => {
          setAlert(ExceptionsHandler(err));
        })
        .finally(() => {
          setEdit(false)
          handleClose();
          setRefresh(true);
        });
    }
  };

  const handleChange = ({ name, value }) => {
    setClientsToUpdate({ ...clientsToUpdate, id: row.id, [name]: customMask(name, value) });
  };

  const handleClose = () => {
    setClientsToUpdate({});
    setDialog(false);
  };

  const handleClickOpen = () => {
    setConfirmeDialog(true);
  };

  const handleEdit = () => {
    setEdit(true);
  };

  const handleChangeCheckBox = (event) => {
    const { name, checked } = event.target;
    if (name === "is_closed" && checked === false){
      setClientsToUpdate({ ...clientsToUpdate, id: row.id, [name]: checked, is_paid: false });

    }else{
      setClientsToUpdate({ ...clientsToUpdate, id: row.id, [name]: checked});
    }
  };

  return (
    <>
      <DeleteDialog row={row} option="clients" />
      <Dialog open={dialog} onClose={handleClose} sx={{zIndex: '1202'}}>
        <DialogTitle>
          Editar Cliente
          <Tooltip title="Fechar">
            <IconButton
              color="secondary"
              onClick={handleClose}
              sx={{ position: 'absolute', top: 0, right: 0 }}>
              <CloseIcon />
            </IconButton>
          </Tooltip>
        </DialogTitle>
        <DialogContent style={{ padding: '20px' }}>
          <div className="form form-clients-update">
            {clientsFormRegister
              .filter(({ name }) => ['name', 'document_number', 'type', 'email', 'initials'].includes(name))
              .map(({ name, label, editable }, index) => (
                <TextField
                  key={index}
                  name={name}
                  select={name === 'status'}
                  disabled={name !== 'document_number' && name !== 'type' ? !edit : true}
                  value={
                    !editable ? clientsToUpdate[name] : customMask(name, clientsToUpdate[name])
                  }
                  defaultValue={customMask(name, row[name])}
                  className={`input-${name}`}
                  label={label}
                  color="secondary"
                  variant="outlined"
                  onChange={({ target }) => handleChange(target)}>
                  {statusOptions.map((option, index) => (
                    <MenuItem key={index} value={option.value}>
                      {option.label}
                    </MenuItem>
                  ))}
                </TextField>
              ))}
          </div>
        </DialogContent>
        <DialogActions sx={{ justifyContent: 'space-between' }}>
          <Tooltip title="Deletar">
            <IconButton size="large" color="error" onClick={handleClickOpen}>
              <DeleteIcon fontSize="inherit" />
            </IconButton>
          </Tooltip>
          {!edit && (
            <Tooltip title="Editar">
              <IconButton color="secondary" onClick={handleEdit}>
                <EditIcon />
              </IconButton>
            </Tooltip>
          )}
          {edit && (
            <Tooltip title="Salvar">
              <IconButton size="large" color="primary" onClick={clientsUpdateHandler}>
                <SaveIcon fontSize="inherit" />
              </IconButton>
            </Tooltip>
          )}
        </DialogActions>
      </Dialog>
    </>
  );
}

ClientsFormDialog.propTypes = {
  row: PropTypes.object
};

export default ClientsFormDialog;
