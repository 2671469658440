import { PhotoLibrary, PictureAsPdf } from "@mui/icons-material";
import { IconButton } from "@mui/material";

const replaceRegex = (obj) =>{
  Object.keys(obj).forEach(function(key){
    if (['cpf', 'cnpj', 'phone', 'zipcode','client_cnpj'].includes(key))
     if (obj[key]) obj[key] = obj[key].replace(/[ #+/().-]/g, '') 
    });
  return obj;
};

export const formatObject = (obj) => {
  let new_obj = Object.fromEntries(Object.entries(obj).filter(([value]) => value !== '' && value !== undefined));
  if (new_obj.status) {
    const status = handleStatus(new_obj.status);
    new_obj = {...new_obj, ...status};
  }
  if (new_obj.value_required) {
    const value_required = {value_required: parseFloat(new_obj.value_required.replace(/\D+/g, ''))/100};
    new_obj = {...new_obj, ...value_required};
  }
  if (new_obj.value_approved) {
    const value_approved = {value_approved: parseFloat(new_obj.value_approved.replace(/\D+/g, ''))/100};
    new_obj = {...new_obj, ...value_approved};
  }
  delete new_obj.status;
  return replaceRegex(new_obj);
};

const handleStatus = (value) => {
  switch (value) {
    case 'Pendente':
      return {pending_approval: true};
    case 'Fechado':
      return {is_closed: true, is_paid: true};
    case 'Aguardando Pagamento':
      return {is_closed: true, is_paid: false};
    case 'Aberto':
      return {is_closed: false};
  }
};

const cnpjMask = (value) => {
  if (value) return value
    .replace(/\D+/g, '') // não deixa ser digitado nenhuma letra
    .replace(/(\d{2})(\d)/, '$1.$2') // captura 2 grupos de número o primeiro com 2 digitos e o segundo de com 3 digitos, apos capturar o primeiro grupo ele adiciona um ponto antes do segundo grupo de número
    .replace(/(\d{3})(\d)/, '$1.$2')
    .replace(/(\d{3})(\d)/, '$1/$2') // captura 2 grupos de número o primeiro e o segundo com 3 digitos, separados por /
    .replace(/(\d{4})(\d)/, '$1-$2')
    .replace(/(-\d{2})\d+?$/, '$1') // captura os dois últimos 2 números, com um - antes dos dois números
};

const cpfMask = (value) => {
  return value
    .replace(/\D+/g, '')
    .replace(/(\d{3})(\d)/, '$1.$2')
    .replace(/(\d{3})(\d)/, '$1.$2')
    .replace(/(\d{3})(\d)/, '$1-$2')
    .replace(/(-\d{2})\d+?$/, '$1')
    .substr(0,14);
};

const phoneMask = (value) => {
  if(value){
    switch (value.length) {
      case 12:
        return value
        .replace(/\D+/g, '')
        .replace(/(\d{2})(\d)/, '$1 ($2') 
        .replace(/(\d{2})(\d)/, '$1) $2')
        .replace(/(\d{4})(\d)/, '$1-$2')
        .replace(/(-\d{4})\d+?$/, '$1');
      case 13:
        return value
        .replace(/\D+/g, '')
        .replace(/(\d{2})(\d)/, '$1 ($2') 
        .replace(/(\d{2})(\d)/, '$1) $2')
        .replace(/(\d{5})(\d)/, '$1-$2')
        .replace(/(-\d{4})\d+?$/, '$1');
      case 15:
        return value
        .replace(/\D+/g, '')
        .replace(/(\d{2})(\d)/, '($1) $2') 
        .replace(/(\d{5})(\d)/, '$1-$2')
        .replace(/(-\d{4})\d+?$/, '$1');
      };
      return value
      .replace(/\D+/g, '')
      .replace(/(\d{2})(\d)/, '($1) $2') 
      .replace(/(\d{4})(\d)/, '$1-$2')
      .replace(/(-\d{4})\d+?$/, '$1');
  }
};

const creditCardMask = (value) => {
  if(value){
    return value
    .replace(/\D+/g, '')
    .replace(/(\d{4})(\d)/, '$1 $2') 
    .replace(/(\d{4})(\d)/, '$1 $2') 
    .replace(/(\d{4})(\d)/, '$1 $2') 
    .replace(/(\d{4})(\d)/, '$1 $2')
    .substr(0,23);
  }
};

const zipcodeMask = (value) => {
  return value
    .replace(/\D+/g, '')
    .replace(/(\d{5})(\d)/, '$1-$2')
    .replace(/(-\d{3})\d+?$/, '$1')
};

const numberMask = (value) => value.toString().replace(/\D+/g, '');
const codeMask = (value) => value.toString().replace(/\D+/g, '').replace(/(\d{6})\d+?$/, '$1');
const twoDigitMask = (value) => value.toString().replace(/\D+/g, '').replace(/(\d{2})\d+?$/, '$1');

const stringMask = (value) => value.toString().replace(/[0-9]/g, '')
const stringSize2Mask = (value) => value.toString().replace(/[0-9]/g, '').substr(0,2).toUpperCase();
const size6Mask = (value) => value.toString().substr(0,6).toUpperCase();


const currencyFormatter = new Intl.NumberFormat('pt-BR', {
  style: 'currency',
  currency: 'BRL',
});

export const currencyMask = (value) => {
  let currency = value;
  if (typeof value === 'string') {
    currency = currency.replace(/\D+/g, '');
    currency = parseFloat(currency) / 100;
  }
  return currencyFormatter.format(currency);
};

const stateMask = (value) => {
  return value
    .replace(/\W+/g, '')
    .replace(/(\w{2})\w+?$/, '$1')
    .toUpperCase();
};

export const customMask = (name, value) => {
  if (!value) return value;
  switch (name) {
    case 'cnpj': case 'client_cnpj':
      return cnpjMask(value);
    case 'cpf': case 'user_cpf':
      return cpfMask(value);
    case 'document_number': case 'client_document_number': case 'holder_document':
      const value_only_numbers = value.replace(/\W+/g, '')
      if (value_only_numbers.length <= 11) {
        return cpfMask(value_only_numbers);
      }else{
        return cnpjMask(value_only_numbers);
      }
    case 'phone':
      return phoneMask(value);
    case 'zipcode': case 'zip_code':
      return zipcodeMask(value);
    case 'state':
      return stateMask(value);
    case 'allowed_accounts': 
      return numberMask(value);
    case 'card_number':
      return creditCardMask(value);
    case 'created_dt': case 'updated_dt': case 'invoice_dt': case 'bond_dt':
      return new Date(value).toLocaleDateString('pt-BR');
    case 'code': case 'cvv': 
      return codeMask(value);
    case 'initials':
      return size6Mask(value);
    case 'value': case 'total_approved': case 'total_required': case 'value_approved': case 'value_required': case 'debt_amount': case 'valor_total':
      return currencyMask(value);
    case 'exp_month': case 'exp_year':
      return twoDigitMask(value)
    case 'holder_name':
      return stringMask(value)
    case 'country': case 'state':
      return stringSize2Mask(value)
    case 'desconto':
      return value*100 + '%'
    default:
      return value;
  };
};

export const renderCellReimbursementHelper = (params) => {
  if (params.field == 'file_url' && params.value) {
    if (params.value.includes('.pdf')) {
      return (
        <IconButton color="primary">
          <PictureAsPdf />
        </IconButton>
      );
    } else
    return (
        <IconButton color="primary">
          <PhotoLibrary />
        </IconButton>
      );
  }
}

export function renderContract(params) {
  if ((params.field == 's3_path' || params.field == 'file_url') && params.value) {
    return (
      <IconButton color="primary">
        <PictureAsPdf />
      </IconButton>
    );
  }
}

export const handleClickOpenFile = (value) => {
  if(value){
    window.open(value)
  }
};

export const calcPlan = (valueUser) => {
  if (valueUser !== undefined){
    return ((valueUser*0.8) + (valueUser*40*0.2) + 20)
  }
  return 0
}

export const getDisccount = (plan) => {
  if (plan === 'Trimestral'){
    return .92
  }else if(plan === 'Anual'){
    return .85
  }else{
    return 1
  }
}


export const ChartColors = [
  'rgba(72, 192, 255, 1)', //secundaria
  'rgba(36, 0, 180, 1)', //primaria
  'rgba(172, 62, 49, 1)',
  'rgba(72, 72, 72, 1)',
  'rgba(219, 174, 88, 1)',
  'rgba(0, 0, 0, 1)',
]

export const ChartColorsTransparent = [
  'rgba(72, 192, 255, .7)', //secundaria
  'rgba(36, 0, 180, .7)', //primaria
  'rgba(172, 62, 49, .7)',
  'rgba(72, 72, 72, .7)',
  'rgba(219, 174, 88, .7)',
  'rgba(0, 0, 0, .7)',
]

export const splitStringComma = (string) => {
  return string.split(',')
}

export const hasRule = (rules, rule) => {
  return rules.includes(rule)
}

// Trata abertura de arquivos via S3 URL
const handleClickOpen = (value) => {
  console.log(value)
  if(value){
    if (value.includes('.pdf')) {
      window.open(value)
    } else {
      setOpen(true);
      setImg_url(value);
    }
  }
};