import { client } from './client';

export const getProduct = (session_token) => {
    return client.get(`/product/`, { headers: { Authorization: session_token } });
};

export const updateSubscriptionCard = (card, session_token) => {
    return client.patch(`/product/subscription/card`, card, { headers: { Authorization: session_token } });
};

export const postSubscription = (payload, session_token) => {
    return client.post(`/product/subscription`, payload, { headers: { Authorization: session_token } });
};

