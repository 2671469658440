import axios from 'axios';
import { refreshUser } from "./auth";

// const axiosClient = axios.create({ baseURL: "http://127.0.0.1:5000" });
const axiosClient = axios.create({ baseURL: "https://api.suppimais.com.br" });

axiosClient.interceptors.response.use(
  (response) => {
    return response;
  },
  async function (error) {
    const refresh_token_old = localStorage.getItem("refresh_token");
    
    if (error.response.status === 401 && refresh_token_old) {
      // VER ISSO AQUI
      await refreshUser(refresh_token_old).then((refreshResp) => {
        const { session_token, refresh_token, is_admin, is_main_user, product_status} = refreshResp.data;
        localStorage.setItem('session_token', session_token);
        localStorage.setItem('refresh_token', refresh_token);
        localStorage.setItem('is_admin', is_admin);
        localStorage.setItem('is_main_user', is_main_user);
        localStorage.setItem('product_status', product_status);
        error.config.headers['Authorization'] = session_token;
      }).catch(() => {
        localStorage.removeItem('session_token');
        localStorage.removeItem('refresh_token');
      });

      if (localStorage.getItem("refresh_token")){
        return axios.request(error.config)
      }
      
      return Promise.reject(error);
    }
    
    return Promise.reject(error);
  }
);

export const client = axiosClient

export const sendS3File = (url, formData) => {
  axios.post(url, formData, { headers: { 'Content-Type': 'multipart/form-data' } });
};