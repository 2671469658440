import React, { useContext, useState, useEffect } from 'react';
import { 
  Card, 
  CardHeader, 
  CardContent,
  Grid,
  Select,
  InputLabel,
  MenuItem,
  TextField,
  FormControl, } from '@mui/material';
import Context from '../../../context/Context';
import { DataGrid, ptBR } from '@mui/x-data-grid';
import { customMask, formatObject } from '../../../utils/helpers';
import { getOperations } from '../../../services/operations';
import { operationForm } from '../../../utils/registerFields';
import { CustomFilter, CustomPagination } from '../../../utils/customGrid';
import { ExceptionsHandler } from '../../../services/exceptions';
import OperationsFormDialog from '../../dialogs/operationDialog/OperationsFormDialog';
import AddCircleIcon from '@mui/icons-material/AddCircle';
import OperationsRegisterDialog from '../../dialogs/operationDialog/OperationsRegisterDialog'
import {Button} from '@mui/material';
import { CleaningServices, FilterAlt, FilterAltOff } from '@mui/icons-material';
import { LocalizationProvider, DatePicker, ptBR as datePickerLocale } from '@mui/x-date-pickers';
import { AdapterDateFns } from '@mui/x-date-pickers/AdapterDateFns';
import { ptBR as ptBRLocale } from 'date-fns/locale';

function Operations() {
  const { setAlert } = useContext(Context);
  const [operationsData, setOperationsData] = useState([]);
  const [editRow, setEditRow] = useState({});
  const { dialog, setDialog } = useContext(Context);
  const { refresh, setRefresh } = useContext(Context);
  const [filter, setFilter] = useState({});
  const [page, setPage] = React.useState(0);
  const [loading, setLoading] = useState(false);
  const { registerDialog, setRegisterDialog } = useContext(Context);
  // Filter section vars
  const [ filterSection, setFilterSection ] = useState(false);
  const [createdDateInit, setCreatedDateInit] = useState(null);
  const [createdDateEnd, setCreatedDateEnd] = useState(null);

  const session_token = localStorage.getItem('session_token');

  const handleValues = (params) => {
    const { field, value } = params;
    if (field === 'status') {
      return getStatus(params);
    }
    return customMask(field, value);
  };

  function getStatus(params) {
    const { is_closed, is_paid, pending_approval } = params.row;
    if (pending_approval) return 'Despesas Pendentes';
    if (is_closed) {
      if (is_paid) return 'Fechado e Pago';
      return 'Aguardando Pagamento';
    }
    return 'Aberto';
  }

  function getStatusFilter(filter) {
    if ("status" in filter) {
      const value = filter.status
      if (value === 'Despesas Pendentes'){
        return {...filter, pending_approval: true};
      }
      if (value === 'Fechado e Pago'){
        return {...filter, is_closed: true, is_paid: true, pending_approval: false};
      }
      if (value === 'Aguardando Pagamento'){
        return {...filter, is_closed: true, is_paid: false, pending_approval: false};
      }
      if (value === 'Aberto'){
        return {...filter, pending_approval: false, is_closed: false, is_paid: false};
      }
    }
    return filter;
  }

  const operationsColumns = operationForm.map(({ name, label, width, align, type, hiden }) => ({
    field: name,
    headerName: label,
    flex: width,
    minWidth: 100 * width,
    align: align,
    headerAlign: align,
    type: type,
    valueOptions:
      name === 'status' ? ['Aberto', 'Fechado', 'Aguardando Pagamento', 'Despesas Pendentes'] : null,
    hide: hiden,
    filterable: false,
    sortable: false,
    filterOperators: CustomFilter(name),
    valueGetter: (params) => handleValues(params)
  }));

  const OperationList = (filter) =>
    getOperations(formatObject(filter), session_token)
      .then(({ data }) => data)
      .catch((err) => setAlert(ExceptionsHandler(err)));

  const operationListHandler = async (method) => {
    setLoading(true);
    const prevPage = operationsData.actual_page;
    const filterHelper = getStatusFilter(filter);
    const data = await OperationList({ ...filterHelper, page: page + 1 });
    const { operations } = data;
    if (method === 'add') {
      let newOperations = [];
      for (let i = prevPage + 1; i < page; i++) {
        const remainingData = await OperationList({ ...filter, page: i });
        newOperations.push(remainingData.operations);
      }
      newOperations.push(operations);
      setOperationsData({
        ...data,
        operations: operationsData.operations.concat(newOperations.flat())
      });
    } else setOperationsData(data);
    setLoading(false);
  };

  const handleEditOperation = (row) => {
    setEditRow(row);
    setDialog(true);
  };

  const handleAddOperations = () => {
    setRegisterDialog(true);
  };

  useEffect(() => {
    if (refresh === true) {
      setPage(0);
      operationListHandler();
      setRefresh(false);
    }
  }, [refresh]);

  useEffect(() => {
    operationsData.has_next && operationsData.actual_page < page
      ? operationListHandler('add')
      : null;
  }, [page]);

  //Filter Section Stuff

  const handleAddFilter = () => {
    setFilterSection(!filterSection);
  };

  const mountFilterGrid = () => {
    const styleForGrid = {
      paddingBottom: "10px",
      textAlign: 'center',
      display: 'flex',
      justifyContent: 'center',
      alignItems: 'center',
    }

    const styleForInputs = {
      width: "100%"
    }

    const handleFilterChange = ({ name, value }) => {
      if (value === null || value === ''){
        const filterHelper = filter
        delete filterHelper[name]
        setFilter({...filterHelper})
      }else{
        setFilter({ ...filter, [name]: customMask(name, value) });
      }
    };

    const handleFilterClean = () => {
      setCreatedDateInit(null);
      setCreatedDateEnd(null);
      setFilter({});
      setRefresh(true);
    };

    const handleFilterAction = () => {
      setRefresh(true);
    };
    
    return (
      <div style={{paddingBottom: "20px"}}>
        <Grid container>
          <Grid item xs={12}><h3>Filtros:</h3></Grid>
          <Grid item xs={12} sm={4} style={styleForGrid}>
            <TextField
                label="TAG Centro de Custo"
                name="or_tag"
                multiline
                value={filter.or_tag || ''}
                onChange={({ target }) => handleFilterChange(target)}
                style={styleForInputs}
                />
          </Grid>
          <Grid item xs={12} sm={4} style={styleForGrid}>
            <TextField
                label="Descrição"
                name="or_name"
                multiline
                value={filter.or_name || ''}
                onChange={({ target }) => handleFilterChange(target)}
                style={styleForInputs}
                />
          </Grid>
          <Grid item xs={12} sm={4} style={styleForGrid}>
            <TextField
                label="Cliente"
                name="client_name"
                multiline
                value={filter.client_name || ''}
                onChange={({ target }) => handleFilterChange(target)}
                style={styleForInputs}
                />
          </Grid>
          <Grid item xs={12} sm={4} style={styleForGrid}>
            <FormControl fullWidth>
              <InputLabel id="type-label">Status</InputLabel>
              <Select
                name="status"
                required
                value={filter.status || ''}
                label="Status"
                onChange={({ target }) => handleFilterChange(target)}
                style={styleForInputs}>
                <MenuItem value="Aberto">Aberto</MenuItem>
                <MenuItem value="Despesas Pendentes">Despesas Pendentes</MenuItem>
                <MenuItem value="Aguardando Pagamento">Aguardando Pagamento</MenuItem>
                <MenuItem value="Fechado e Pago">Fechado e Pago</MenuItem>
              </Select>
            </FormControl>
          </Grid>
          <Grid item xs={12} sm={4} style={styleForGrid}>
            <div style={styleForInputs}>
              <LocalizationProvider
                dateAdapter={AdapterDateFns}
                adapterLocale={ptBRLocale}
                localeText={
                  datePickerLocale.components.MuiLocalizationProvider.defaultProps.localeText
                }>
                <DatePicker
                  disableFuture
                  className="input-invoice_dt"
                  name="created_dt_init"
                  label="Data de criação (Inicio)"
                  openTo="day"
                  views={['year', 'month', 'day']}
                  inputFormat="dd/MM/yyyy"
                  maxDate={new Date()}
                  value={createdDateInit}
                  onChange={(newValue) => setCreatedDateInit(newValue)}
                  renderInput={(params) => <TextField {...params} />}
                  />
              </LocalizationProvider>
            </div>
          </Grid>
          <Grid item xs={12} sm={4} style={styleForGrid}>
            <div style={styleForInputs}>
              <LocalizationProvider
                dateAdapter={AdapterDateFns}
                adapterLocale={ptBRLocale}
                localeText={
                  datePickerLocale.components.MuiLocalizationProvider.defaultProps.localeText
                }>
                <DatePicker
                  disableFuture
                  className="input-invoice_dt"
                  name="created_dt_end"
                  label="Data de criação (Fim)"
                  openTo="day"
                  views={['year', 'month', 'day']}
                  inputFormat="dd/MM/yyyy"
                  maxDate={new Date()}
                  value={createdDateEnd}
                  onChange={(newValue) => setCreatedDateEnd(newValue)}
                  renderInput={(params) => <TextField {...params} />}
                />
              </LocalizationProvider>
            </div>
          </Grid>
          <Grid item xs={12} sm={12} style={styleForGrid}></Grid>
          <Grid item xs={12} sm={6} style={styleForGrid}>
            <Button 
              style={{height: "100%"}} 
              onClick={handleFilterAction}>
                Filtrar <FilterAlt/>
            </Button>
          </Grid>
          <Grid item xs={12} sm={6} style={styleForGrid}>
            <Button 
              style={{height: "100%"}}  
              variant="outlined" 
              onClick={handleFilterClean}>
                Limpar <CleaningServices/>
            </Button>
          </Grid>
        </Grid>
      </div>
    )
  }

  useEffect(() => {
    if (createdDateInit === null){
      const filterHelper = filter
      delete filterHelper.created_dt_init
      setFilter({...filterHelper})
    } else {
      const createdDateInitHelper = createdDateInit
      createdDateInitHelper.setHours(0);
      createdDateInitHelper.setMinutes(0);
      setFilter({ ...filter, created_dt_init: createdDateInitHelper });
    }
  }, [createdDateInit]);

  useEffect(() => {
    if (createdDateEnd === null){
      const filterHelper = filter
      delete filterHelper.created_dt_end
      setFilter({...filterHelper})
    } else {
      const createdDateEndHelper = createdDateEnd
      createdDateEndHelper.setHours(23);
      createdDateEndHelper.setMinutes(59);
      setFilter({ ...filter, created_dt_end: createdDateEndHelper });
    }
  }, [createdDateEnd]);

  useEffect(() => {
    operationListHandler();
  }, []);

  return (
    <div className="page-content">
      {dialog && <OperationsFormDialog row={editRow} />}
      {registerDialog && <OperationsRegisterDialog />}
      <Card className="data-grid-card">
        <CardHeader className="card-header" title="Centros de Custo" />
        <CardContent className="card-body">
          <div style={{ width: '100%' }}>
            <div className="data-grid-header">
              <Button 
                style={{width: "auto", marginRight: "5px"}} 
                variant="outlined" 
                onClick={handleAddFilter}>
                  {filterSection ? <FilterAltOff/> : <FilterAlt/>}
                  {Object.keys(filter).length}
              </Button>
              <Button style={{width: "auto", marginRight: "5px" }}
                variant="outlined" 
                onClick={handleAddOperations}>
                  Adicionar Centro de Custo
                  <AddCircleIcon/>
              </Button>
            </div>
            {filterSection && mountFilterGrid()}
            <DataGrid
              rows={operationsData.operations || []}
              columns={operationsColumns}
              pageSize={10}
              rowCount={(operationsData.total_pages || 1) * 10}
              page={page}
              autoHeight
              loading={loading}
              disableSelectionOnClick
              onPageChange={(newPage) => (newPage != 'NaN' ? setPage(newPage) : null)}
              onRowDoubleClick={({ row }) => handleEditOperation(row)}
              localeText={ptBR.components.MuiDataGrid.defaultProps.localeText}
              components={{
                Pagination: CustomPagination
              }}
            />
          </div>
        </CardContent>
      </Card>
    </div>
  );
}

export default Operations;
