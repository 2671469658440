// #TODO: Create a function to register fields
export const userForm = [
  { name: "name", label: "Nome", required: true, },
  { name: "email", label: "E-mail", required: true },
  { name: "cpf", label: "CPF", required: true },
  { name: "phone", label: "Telefone", required: false },
  { name: "bank_name", label: "Banco", required: false },
  { name: "bank_agency", label: "Agência", required: false },
  { name: "bank_account", label: "Conta", required: false },
  { name: "pix_key", label: "Chave PIX", required: false },
];
// #TODO: Check this \/
const userFormExtended = [
  { name: "is_admin", label: "Administrador", required: false },
  { name: "password", label: "Senha", required: true },
];

export const collaboratorsForm = [
  { name: "name", label: "Nome", required: true, type: "string", width: 3, align: "left", editable: true, hiden: false, customFilter: false },
  { name: "email", label: "E-mail", required: true, type: "string", width: 3, align: "left", editable: true, hiden: false, customFilter: false },
  { name: "phone", label: "Telefone", required: true, type: "string", width: 1.6, align: "left", editable: true, hiden: true, customFilter: false },
  { name: "cpf", label: "CPF", required: true, type: "string", width: 1.6, align: "left", editable: true, hiden: false, customFilter: false },
  { name: "bank_name", label: "Banco", required: false, type: "string", width: 1, align: "left", editable: true, hiden: true, customFilter: false },
  { name: "bank_agency", label: "Agência", required: false, type: "string", width: 1, align: "left", editable: true, hiden: true, customFilter: false },
  { name: "bank_account", label: "Conta", required: false, type: "string", width: 1, align: "left", editable: true, hiden: true, customFilter: false },
  { name: "pix_key", label: "Chave PIX", required: false, type: "string", width: 2, align: "left", editable: true, hiden: false, customFilter: false },
  { name: "is_admin", label: "Administrador", required: true, type: "boolean", width: 1, align: "center", editable: true, hiden: false, customFilter: false },
];

export const companyForm = [
  { name: "name", label: "Nome", required: true },
  { name: "cnpj", label: "CNPJ", required: true },
  { name: "phone", label: "Telefone", required: true },
  { name: "street", label: "Endereço", required: true },
  { name: "complement", label: "Complemento", required: false },
  { name: "website", label: "Site da Empresa", required: false },
  { name: "city", label: "Cidade", required: true },
  { name: "state", label: "Estado", required: true },
  { name: "country", label: "País", required: true },
  { name: "zipcode", label: "CEP", required: true },
  { name: "bank_name", label: "Banco", required: true, type: "string", width: 1, align: "left", editable: true, hiden: true, customFilter: false },
  { name: "bank_agency", label: "Agência", required: true, type: "string", width: 1, align: "left", editable: true, hiden: true, customFilter: false },
  { name: "bank_account", label: "Conta", required: true, type: "string", width: 1, align: "left", editable: true, hiden: true, customFilter: false },
  { name: "pix_key", label: "Chave PIX", required: true, type: "string", width: 3, align: "left", editable: true, hiden: true, customFilter: false },
];

export const passwordForm = [
  { name: "password", label: "Senha", required: true, type: "password" },
  { name: "password_confirmation", label: "Confirme a Senha", required: true, type: "password" },
];

export const changePasswordForm = [
  { name: "old_password", label: "Senha Atual", required: true, type: "password" },
  { name: "new_password", label: "Nova Senha", required: true, type: "password" },
  { name: "new_password_confirmation", label: "Confirme a Nova Senha", required: true, type: "password" },
];

export const operationForm = [
  { name: "or_tag", label: "TAG", required: true, type: "string", width: 1, align: "left", editable: true, hiden: false, customFilter: false },
  { name: "or_name", label: "Descrição", required: false, type: "string", width: 3, align: "left", editable: true, hiden: false, customFilter: false },
  { name: "client_name", label: "Cliente", required: false, type: "string", width: 1, align: "left", editable: true, hiden: false, customFilter: false },
  //{ name: "client_cnpj", label: "CNPJ do Cliente", required: false, type: "string", width: 1.6, align: "left", editable: true, hiden: false, customFilter: true },
  { name: "status", label: "Status", required: false, type: "singleSelect", width: 1.5, align: "center", editable: false, hiden: false, customFilter: false },
  { name: "is_closed", label: "Fechado", required: false, type: "boolean", width: 1, align: "center", editable: true, hiden: true, customFilter: false },
  { name: "is_paid", label: "Pago", required: false, type: "boolean", width: 1, align: "center", editable: true, hiden: true, customFilter: false },
  { name: "pending_approval", label: "Possui Pendências", required: false, type: "boolean", width: 1, align: "center", editable: false, hiden: true, customFilter: false },
  { name: "total_approved", label: "Total Aprovado", required: false, type: "number", width: 1.2, align: "right", editable: false, hiden: false, customFilter: false },
  { name: "total_required", label: "Total Requerido", required: false, type: "number", width: 1.2, align: "right", editable: false, hiden: false, customFilter: false },
  { name: "created_dt", label: "Data de Criação", required: false, type: "dateTime", width: 1, align: "center", editable: false, hiden: false, customFilter: false },
  { name: "updated_dt", label: "Data de Atualização", required: false, type: "dateTime", width: 1, align: "center", editable: false, hiden: true, customFilter: false },
];

export const reimbursementForm = [
  { name: "or_tag", label: "TAG Centro de Custo", required: false, type: "string", width: 1, align: "left", editable: false, hiden: false, customFilter: false },
  { name: "user_id", label: "Id Colaborador", required: false, type: "number", width: 1, align: "left", editable: false, hiden: true, customFilter: false },
  { name: "user_name", label: "Colaborador", required: false, type: "string", width: 2, align: "left", editable: false, hiden: false, customFilter: false },
  { name: "description", label: "Descrição", required: true, type: "string", width: 2, align: "left", editable: true, hiden: true, customFilter: false },
  { name: "expenses_type", label: "Tipo da Despesa", required: true, type: "string", width: 2, align: "left", editable: true, hiden: false, customFilter: false },
  { name: "type", label: "Categoria", required: true, type: "singleSelect", width: 1, align: "left", editable: true, hiden: false, customFilter: false },
  { name: "operation_id", label: "Id do Centro de Custo", required: true, type: "singleSelect", width: 1, align: "left", editable: true, hiden: true, customFilter: false },
  { name: "flow_status", label: "Status", required: false, type: "singleSelect", width: 1, align: "center", editable: false, hiden: false, customFilter: false },
  { name: "file_url", label: "Comprovante", required: false, type: "string", width: .8, align: "center", editable: true, hiden: false, customFilter: false },
  { name: "value_approved", label: "Valor Aprovado", required: false, type: "number", width: 1, align: "right", editable: false, hiden: false, customFilter: false },
  { name: "value_required", label: "Valor Requerido", required: false, type: "number", width: 1, align: "right", editable: false, hiden: false, customFilter: false },
  { name: "is_paid", label: "Pago", required: false, type: "boolean", width: .5, align: "center", editable: true, hiden: false, customFilter: false },
  { name: "is_internal", label: "Despesa Interna", required: false, type: "boolean", width: 1, align: "center", editable: true, hiden: false, customFilter: false },
  { name: "invoice_dt", label: "Data da Nota", required: true, type: "date", width: 1, align: "center", editable: true, hiden: false, customFilter: false },
];

export const debtsUserForm = [
  { name: "user_id", label: "Colaborador", required: false, type: "number", width: 1, align: "left", editable: false, hiden: true, customFilter: false },
  { name: "reimbursement_ids", label: "Id da Despesa", required: true, type: "string", width: 1, align: "left", editable: true, hiden: true, customFilter: false },
  { name: "name", label: "Colaborador", required: false, type: "string", width: 2, align: "left", editable: false, hiden: false, customFilter: false },
  { name: "email", label: "Email", required: true, type: "string", width: 2, align: "left", editable: true, hiden: false, customFilter: false },
  { name: "cpf", label: "CPF", required: true, type: "string", width: 1.6, align: "left", editable: true, hiden: false, customFilter: false },
  { name: "pix_key", label: "Chave PIX", required: false, type: "string", width: 3, align: "left", editable: true, hiden: false, customFilter: false }, 
  { name: "bank_name", label: "Banco", required: false, type: "string", width: 1, align: "left", editable: true, hiden: false, customFilter: false },
  { name: "bank_agency", label: "Agência", required: false, type: "string", width: 1, align: "left", editable: true, hiden: false, customFilter: false },
  { name: "bank_account", label: "Conta", required: false, type: "string", width: 1, align: "left", editable: true, hiden: false, customFilter: false },
  { name: "debt_amount", label: "Valor", required: true, type: "number", width: 1.2, align: "right", editable: true, hiden: false, customFilter: false },
]

export const invoiceForm = [
  { name: "or_tag", label: "TAG Centro de Custo", required: true, type: "string", width: 1, align: "left", editable: true, hiden: false, customFilter: false },
  { name: "or_name", label: "Descrição", required: false, type: "string", width: 2, align: "left", editable: true, hiden: false, customFilter: false },
  { name: "client_name", label: "Cliente", required: false, type: "string", width: 1.5, align: "left", editable: false, hiden: false, customFilter: false },
  { name: "client_document_number", label: "Documento do Cliente (CPF/CNPJ)", required: false, type: "string", width: 2.5, align: "left", editable: false, hiden: false, customFilter: false },
  { name: "s3_path", label: "Comprovante", required: true, type: "string", width: 1, align: "center", editable: false, hiden: false, customFilter: false },
  { name: "is_paid", label: "Pago", required: false, type: "boolean", width: 1, align: "center", editable: true, hiden: false, customFilter: false },
  { name: "value", label: "Valor", required: true, type: "number", width: 1.2, align: "right", editable: false, hiden: false, customFilter: false },
];

export const clientsForm = [
  { name: "name", label: "Nome", required: true, type: "string", width: 3, align: "left", editable: true, hiden: false, customFilter: false },
  { name: "document_number", label: "Documento do Cliente (CPF/CNPJ)", required: false, type: "string", width: 2.5, align: "left", editable: false, hiden: false, customFilter: false },
  { name: "type", label: "Categoria", required: true, type: "singleSelect", width: 0.5, align: "left", editable: true, hiden: false, customFilter: false },
  { name: "initials", label: "Sigla", required: true, type: "string", width: 1, align: "left", editable: true, hiden: false, customFilter: false },
  { name: "email", label: "E-mail", required: true, type: "string", width: 2, align: "left", editable: true, hiden: false, customFilter: false },
]

export const clientsFormRegister = [
  { name: "name", label: "Nome", required: true, type: "string", width: 3, align: "left", editable: true, hiden: false, customFilter: false },
  { name: "document_number", label: "Documento do Cliente (CPF/CNPJ)", required: false, type: "string", width: 2.5, align: "left", editable: false, hiden: false, customFilter: false },
  { name: "type", label: "Categoria", required: true, type: "singleSelect", width: 0.5, align: "left", editable: true, hiden: false, customFilter: false },
  { name: "initials", label: "Sigla (2 a 6 dígitos)", required: true, type: "string", width: 1, align: "left", editable: true, hiden: false, customFilter: false },
  { name: "email", label: "E-mail", required: false, type: "string", width: 2, align: "left", editable: true, hiden: false, customFilter: false },
]

export const cardEditForm = [
  { name: "card_number", label: "Número do Cartão", required: true, type: "string"},
  { name: "exp_month", label: "Mês de Expiração (MM)", required: true, type: "string"},
  { name: "exp_year", label: "Ano de Expiração (AA)", required: true, type: "string"},
  { name: "cvv", label: "CVV", required: true, type: "string"},
  { name: "holder_document", label: "CPF/CNPJ do Titular", required: true, type: "string"},
  { name: "holder_name", label: "Nome como no Cartão", required: true, type: "string"},
  { name: "zip_code", label: "CEP (Cobrança)", required: true, type: "string"},
  { name: "address_number", label: "Número", required: true, type: "string"},
  { name: "street", label: "Endereço", required: true, type: "string"},
  { name: "neighborhood", label: "Bairro", required: true, type: "string"},
  { name: "complement", label: "Complemento", required: false, type: "string"},
  { name: "city", label: "Cidade", required: true, type: "string"},
  { name: "state", label: "Estado (Iniciais)", required: true, type: "string"},
  { name: "country", label: "País (Iniciais) ", required: true, type: "string"},
];

// #TODO: Refactor this function to use a single array of objects
const userFieldsRequired = userForm.filter(({ required }) => required === true).map(({ name }) => name)
const companyFieldsRequired = companyForm.filter(({ required }) => required === true).map(({ name }) => name)
export const allDataRequired = [...userFieldsRequired, ...companyFieldsRequired];
export const addCompanyDataRequired = [...companyFieldsRequired];
export const addUserDataRequired = [...userFieldsRequired, ...userFormExtended.filter(({ required }) => required === true).map(({ name }) => name)];


export const creditRecoverForm = [
  { name: "charge_id", label: "ID", required: false, type: "number", width: 1, align: "left", editable: false, hiden: true, customFilter: false },
  { name: "name", label: "Nome", required: false, type: "string", width: 1.3, align: "left", editable: false, hiden: false, customFilter: false },
  { name: "email", label: "Email", required: true, type: "string", width: 1.3, align: "left", editable: false, hiden: false, customFilter: false },
  { name: "cpf", label: "CPF", required: true, type: "string", width: 1, align: "left", editable: false, hiden: false, customFilter: false },
  { name: "status", label: "Status", required: false, type: "singleSelect", width: 1.3, align: "center", editable: false, hiden: false, customFilter: false },
  { name: "file_url", label: "Contrato", required: false, type: "string", width: .8, align: "center", editable: false, hiden: false, customFilter: false },
  { name: "valor_total", label: "Dívida Tot.", required: false, type: "string", width: 1.5, align: "center", editable: false, hiden: false, customFilter: false }, 
  { name: "desconto", label: "Desconto", required: false, type: "string", width: .8, align: "center", editable: false, hiden: false, customFilter: false },
  { name: "max_parcelas", label: "Max Parc", required: false, type: "string", width: .5, align: "center", editable: false, hiden: false, customFilter: false },
  { name: "debt_owner", label: "Cliente", required: true, type: "number", width: 1, align: "center", editable: false, hiden: false, customFilter: false },
  { name: "parcelas_selecionadas", label: "Parc. Selec.", required: false, type: "string", width: 1, align: "center", editable: false, hiden: false, customFilter: false },
  { name: "base_charge_day", label: "Dia Base", required: false, type: "string", width: .8, align: "center", editable: false, hiden: false, customFilter: false },
  { name: "phone", label: "Telefone", required: false, type: "string", width: 1, align: "left", editable: false, hiden: false, customFilter: false },
  { name: "msg_enviadas", label: "Msg Enviada", required: false, type: "string", width: .8, align: "center", editable: false, hiden: true, customFilter: false },
]