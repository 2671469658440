import React from 'react';
import { Grid } from '@mui/material';
import math_carvalho from '../../../assets/images/team_photos/math_carvalho.jpeg'
import nath_cassitas from '../../../assets/images/team_photos/nath_cassitas.jpeg'


function TeamSection() {

return (
    <div>
        <Grid container style={{ 
            backgroundColor: '#2400b4',
            textAlign: 'center',
            padding: '20px', 
            color: 'white', 
            display: 'flex',
            justifyContent: 'center',
            alignItems: 'center',
        }}>
                <Grid item sm={0} md={1}></Grid>
                <Grid item sm={12} md={5}>
                    <div>
                        <div style={{marginTop: "20px"}}>
                            <img
                            src={math_carvalho}
                            alt="MathCarvalho"
                            height="200px"
                            style={{borderRadius: "30%",
                            boxShadow: '0 4px 8px 0 rgba(0, 0, 0, 0.2), 0 6px 20px 0 rgba(0, 0, 0, 0.19)'}}/>
                            <p><b style={{fontSize: "22px"}}>Matheus Carvalho</b><br/></p>
                            <p style={{margin: "0 50px 0 50px"}}>
                            Cofundador e responsável técnico da Suppi+, formado em Ciência da Computação pela FEI, 
                            possui uma vasta experiência como desenvolvedor e arquiteto de sistemas, 
                            já trabalhou em empresas nacionais e internacionais, é o idealizador da marca.
                            </p>
                        </div>
                    </div>
                </Grid>
                <Grid item sm={12} md={5}>
                    <div>
                        <div style={{marginTop: "20px"}}>
                            <img
                            src={nath_cassitas}
                            alt="NathCassitas"
                            height="200px"
                            style={{borderRadius: "30%",
                            boxShadow: '0 4px 8px 0 rgba(0, 0, 0, 0.2), 0 6px 20px 0 rgba(0, 0, 0, 0.19)'}}/>
                            <p><b style={{fontSize: "22px"}}>Nathalia Cassitas</b><br/></p>
                            <p style={{margin: "0 50px 0 50px"}}>
                            Formada em Letras pela USP, com grande experiência nas áreas comercial e de gestão, 
                            é cofundadora da Suppi+, responsável pela comunicação com o cliente, 
                            sempre visando a um atendimento humanizado e nosso compromisso com a excelência.
                            </p>
                        </div>
                    </div>
                </Grid>
                <Grid item sm={0} md={1}></Grid>
            </Grid>
    </div>
    );
}

export default TeamSection;