import { Route, Routes } from 'react-router';
import Home from './pages/Home';
import Login from './pages/Login';
import Platform from './pages/Platform';
import { ThemeProvider, createTheme } from '@mui/material/styles';
import RequireAuth from './components/RequireAuth';
import Register from './pages/Register';
import './styles/App.scss';
import { ptBR } from '@material-ui/core/locale';
import Privacy from './pages/Privacy';
import Terms from './pages/Terms';


const theme = createTheme(
  {
    typography: {
      allVariants: {
        fontFamily: 'Titillium Web',
        textTransform: 'none',
      },
    },
    palette: {
      primary: { main: '#48c0ff' },
      secondary: { main: '#2400b4' },
      defaultBg: { main: '#f0f0f0' },
    },
    components: {
      MuiToolbar: {
        styleOverrides: {
          root: {
            padding: 0,
          },
        },
      },
      MuiButton: {
        variants: [
          {
            props: { variant: 'navbar'},
            style: {
              width: "auto",
              fontSize: "larger",
              padding: 10,
              margin: 10,
              color: "#f0f0f0",
              backgroundColor: "transparent",
              '&:hover': {
                backgroundColor: "#48c0ff",
              },
            },
          },
          {
            props: { variant: 'delete'},
            style: {
              color: "#f0f0f0",
              backgroundColor: "red",
              '&:hover': {
                backgroundColor: "#48c0ff",
              },
            },
          },
          {
            props: { variant: 'outlined'},
            style: {
              variant: 'outlined',
              color: "#48c0ff",
              backgroundColor: "white",
              outlineColor: "red",
              '&:hover': {
                backgroundColor: "#48c0ff",
                color: "#f0f0f0",
              },
            },
          },
          {
            props: { variant: 'add-btn'},
            style: {
              color: "#f0f0f0",
              backgroundColor: "#48c0ff",
              outlineColor: "red",
              '&:hover': {
                backgroundColor: "#2400b4",
                color: "#f0f0f0",
              },
            },
          },
        ],
        styleOverrides: {
          root: {
            width: "100%",
            fontSize: "large",
            color: "#f0f0f0",
            backgroundColor: "#2400b4",
            '&:hover': {
              backgroundColor: "#48c0ff",
            },
            '&:disabled': {
              backgroundColor: "#80808020",
            },
          },
        },
      },
      MuiCardHeader: {
        styleOverrides: {
          root: {
            textAlign: 'center',
          },
        },
      },
      MuiDialogTitle: {
        styleOverrides: {
          root: {
            textAlign: 'center',
          },
        },
      },
      MuiCardActions: {
        styleOverrides: {
          root: {
            padding: '0 16px 16px',
          },
        },
      },
    },
  },
  ptBR,
);

function App() {
  return (
    <ThemeProvider theme={theme}>
      <Routes>
        <Route path="/" element={<Home />} />
        <Route path="/login" element={<Login />} />
        <Route path="/register" element={<Register />} />
        <Route path="/privacy" element={<Privacy />} />
        <Route path="/terms" element={<Terms />} />
        <Route
          path="/platform"
          element={
            <RequireAuth>
              <Platform />
            </RequireAuth>
          }
        />
      </Routes>
    </ThemeProvider>
  );
}

export default App;
