import React, { useContext, useState } from 'react';
import Context from '../../../context/Context';
import {
  Button,
  Stepper,
  Step,
  StepLabel,
  Typography,
} from '@mui/material';
import { Dialog, DialogActions, DialogContent, DialogTitle } from '@mui/material';
import { formatObject, getDisccount } from '../../../utils/helpers';
import { Box } from '@mui/system';
import AddCardStep from './addPlanSteps/AddCardStep';
import AddPlanStep from './addPlanSteps/AddPlanStep';
import ConfirmPlanStep from './addPlanSteps/ConfirmPlanStep';
import { cardEditForm } from '../../../utils/registerFields';
import { postSubscription } from '../../../services/product';
import { ExceptionsHandler } from '../../../services/exceptions';

function AddPlanDialog() {
  const { setAlert } = useContext(Context);
  const { planData, planCardData } = useContext(Context);
  const [activeStep, setActiveStep] = React.useState(0);

  const session_token = localStorage.getItem('session_token');
  const open_plan_dialog = localStorage.getItem('product_status') !== 'VALID';

  const formatPayload = () => {
    const line_1 = planCardData.address_number + ", " + planCardData.street + ", " + planCardData.neighborhood
    const correct_payload = {

      plan: {
        allowed_accounts: planData.allowed_accounts,
        allowed_monthly_rb: planData.allowed_monthly_rb,
        paid_value: planData.paid_value*getDisccount(planData.plan)*100,
        plan: planData.plan
      },
      card: {...planCardData,
        number: planCardData.card_number,
        billing_address: {
          line_1: line_1,
          line_2: planCardData.complement,
          zip_code: planCardData.zip_code,
          city: planCardData.city,
          state: planCardData.state,
          country: planCardData.country
        }}

    }
    return correct_payload
  } 
  
  const addSubscriptionHandler = () => {
    postSubscription(formatObject(formatPayload()), session_token)
      .then(async () => {
        setAlert({
          state: true,
          message: 'Assinatura criada com sucesso!',
          type: 'success',
          header: 'Bem vindo!'
        });
        handleClose();
      })
      .catch((err) => {
        setAlert(ExceptionsHandler(err));
      });
  };

  const handleClose = () => {
    localStorage.setItem('product_status', "VALID");
  };

  // Stepper stuff ----------------------------------------------------------------------
  const steps = [
    {
      label: 'Plano',
      requirements: 'operation_id',
      element: <AddPlanStep/>
    },
    {
      label: 'Pagamento',
      requirements: 'reimbursement_ids',
      element: <AddCardStep/>
    },
    {
      label: 'Confirmação',
      requirements: 'operation_id',
      element: <ConfirmPlanStep/>
    }
  ];

  const validateCardInfo = () => {
    let missing = ""
    cardEditForm.map(({name, label, required }) => {
      if ((planCardData[name] === undefined || planCardData[name] === "") && required){
        missing = missing + label + "; "
      }

    })
    if (missing !== ""){
      setAlert({
        state: true,
        message: 'Por favor preencha todos os campos com \'*\' antes de é para a próxima fase. Faltantes: ' + missing,
        type: 'error',
        header: 'Campos faltantes'
      });
      return false
    }

    return true
  }

  const handleNext = () => {
    if (activeStep === 1){
      if (!validateCardInfo()){
        return
      }
    }
    setActiveStep((prevActiveStep) => prevActiveStep + 1);
  };

  const handleBack = () => {
    setActiveStep((prevActiveStep) => prevActiveStep - 1);
  };
  // Stepper stuff ----------------------------------------------------------------------

  return (
    <>
      <Dialog open={open_plan_dialog} onClose={handleClose} sx={{zIndex: '1202'}}>
        <DialogTitle>
          Assinar Plano Suppi+
        </DialogTitle>
        <DialogContent style={{ padding: '20px' }}>
            <Stepper activeStep={activeStep}>
            {steps.map((obj) => {
              const stepProps = {};
              const labelProps = {};
              return (
                <Step key={obj.label} {...stepProps}>
                  <StepLabel {...labelProps}>{obj.label}</StepLabel>
                </Step>
              );
            })}
          </Stepper>
          <Box sx={{ marginTop: '2rem' }}>
            {steps[activeStep].element}
          </Box>
        </DialogContent>
        <DialogActions sx={{ justifyContent: 'right' }}>
          {activeStep === steps.length ? (
            <>
              <Box sx={{ display: 'flex', flexDirection: 'row', pt: 2 }}>
                <Box sx={{ flex: '1 1 auto' }} />
                <Button>Reset</Button>
              </Box>
            </>
            ) : (
            <>
              <Box sx={{ display: 'flex', flexDirection: 'row' }}>
                {activeStep > 0 && (
                  <Button
                    variant="outlined"
                    disabled={activeStep === 0}
                    onClick={handleBack}
                    sx={{ mr: 1 }}>
                    Voltar
                  </Button>
                )}
                {activeStep === steps.length - 1 ? (
                  <Button onClick={addSubscriptionHandler}>
                    <Typography>Assinar Plano</Typography>
                  </Button>
                ) : (
                  <Button onClick={handleNext}>
                    Próximo
                  </Button>
                )}
              </Box>
            </>
          )}
        </DialogActions>
      </Dialog>
    </>
  );
}

// AddPlanDialog.propTypes = {
//   row: PropTypes.object
// };

export default AddPlanDialog;
