import React, { useContext, useState } from 'react';
import { Drawer, Box, ListItemIcon, Toolbar } from '@mui/material';
import { List, ListItem, ListItemButton, ListItemText } from '@mui/material';
import Context from '../../context/Context';
import User from './submenus/User';
import Operations from './submenus/Operations';
import Collaborators from './submenus/Collaborators';
import Reimbursements from './submenus/Reimbursements';
import MineReimbursements from './submenus/MineReimbursements';
import Invoices from './submenus/Invoices';
import CollaboratorsDebts from './submenus/CollaboratorsDebts';
import Clients from './submenus/Clients';
import { AccountCircle, PointOfSale, TextSnippet, Topic, Groups3, Store, RequestQuote, MonetizationOn, InsertChart, CreditScore } from '@mui/icons-material';
import AddPlanDialog from '../dialogs/planDialog/AddPlanDialog';
import Dashboard from './submenus/Dashboard';
import CreditRecover from './submenus/CreditRecover';

const menuObj = [
  { icon: <AccountCircle fontSize="large" titleAccess="Minha Conta"/>, name: 'Minha Conta', isAdmin: false, element: <User /> },
  //{ icon: <InsertChart fontSize="large" titleAccess="Dashboard"/>, name: 'Dashboard', isAdmin: true, element: <Dashboard /> },
  { icon: <TextSnippet fontSize="large" titleAccess="Minhas Despesas"/>, name: 'Minhas Despesas', isAdmin: false, element: <MineReimbursements /> },
  { icon: <Topic fontSize="large" titleAccess="Todas as Despesas"/>, name: 'Todas as Despesas', isAdmin: true, element: <Reimbursements /> },
  { icon: <PointOfSale fontSize="large" titleAccess="Centros de Custo"/>, name: 'Centros de Custo', isAdmin: true, element: <Operations /> },
  { icon: <MonetizationOn fontSize="large" titleAccess="Débitos"/>, name: 'Débitos', isAdmin: true, element: <CollaboratorsDebts /> },
  { icon: <RequestQuote fontSize="large" titleAccess="Faturas"/>, name: 'Faturas', isAdmin: true, element: <Invoices /> },
  { icon: <Store fontSize="large" titleAccess="Clientes"/>, name: 'Clientes', isAdmin: true, element: <Clients /> },
  { icon: <Groups3 fontSize="large" titleAccess="Colaboradores"/>, name: 'Colaboradores', isAdmin: true, element: <Collaborators /> },
  { icon: <CreditScore fontSize="large" titleAccess="Recuperação de Crédito"/>, name: 'Recuperação de Crédito', isAdmin: true, element: <CreditRecover /> },
];

function Platform() {
  const { drawer, toggleDrawer } = useContext(Context);
  const [screen, setScreen] = useState(menuObj[1].element);
  const [selectedIndex, setSelectedIndex] = useState(1);

  const is_admin = localStorage.getItem('is_admin') === 'true';
  const open_plan_dialog = localStorage.getItem('product_status') === 'NOT_EXIST';

  const list = () => (
    <Box
      onClick={toggleDrawer(false)}
      onKeyDown={toggleDrawer(false)}
    >
      <List component="nav" style={{paddingTop: "66px"}}>
        {menuObj
          .filter((obj, _i, array) => (!is_admin) ? obj.isAdmin === false : array)
          .map((obj, index) => (
            <ListItem key={index} disablePadding >
              <ListItemButton
                selected={selectedIndex === index}
                onClick={() => {
                  setScreen(obj.element),
                  setSelectedIndex(index)
                }
                }
                sx={{
                  color: selectedIndex === index ? 'secondary.main' : null,
                  '&:hover': {
                    backgroundColor: "#48c0ff",
                  },
                }}
              >
                <ListItemIcon sx={{color: selectedIndex === index ? 'secondary.main' : null}}>
                  {obj.icon}
                </ListItemIcon>
                <ListItemText primary={obj.name} sx={{display: { sm: 'flex', md: 'none' },}}/>
              </ListItemButton>
            </ListItem>
          ))
        }
      </List>
    </Box>
  )

  const drawerWidth = 73;

  return (
    <div className="page-content">
      {open_plan_dialog && <AddPlanDialog/>}
      <Box sx={{ display: 'flex' }}>
      <Box
        component="nav"
        sx={{ width: { md: drawerWidth }, flexShrink: { sm: 0 }}}
      >
        <Drawer
          onClose={toggleDrawer(false)}
          PaperProps={{
            sx: {
              backgroundColor: "#f0f0f0",
              width: 250,
              flexShrink: { sm: 0 },
            }
          }}
          className="menu-drawer"
          anchor="left"
          ModalProps={{
            keepMounted: true, // Better open performance on mobile.
          }}
          open={drawer}
        >
          {list()}
        </Drawer>
        <Drawer
          variant="permanent"
          sx={{
            display: { xs: 'none', sm: 'none', md: 'flex' },
            '& .MuiDrawer-paper': { boxSizing: 'border-box', width: drawerWidth},
          }}
          open
        >
          {list()}
        </Drawer>
      </Box>
      <Box
        component="main"
        sx={{ flexGrow: 1, width: { sm: `calc(100% - ${drawerWidth}px)` }, paddingTop: "15px" }}
      >
        {screen}
      </Box>
    </Box>
    </div>
  );
}

export default Platform;