import React, { useState, useContext, useEffect } from 'react';
import Context from '../../../context/Context';
import { Button, FormControl, Grid, InputLabel, MenuItem, Select, TextField } from '@mui/material';
import { Card, CardHeader, CardContent } from '@mui/material';
import { getCollaborators } from '../../../services/user';
import { collaboratorsForm } from '../../../utils/registerFields';
import { customMask, formatObject } from '../../../utils/helpers';
import { DataGrid, ptBR as dataGridLocale } from '@mui/x-data-grid';
import { CustomFilter, CustomPagination } from '../../../utils/customGrid';
import { ExceptionsHandler } from '../../../services/exceptions';
import CollaboratorsFormDialog from '../../dialogs/collaboratorsDialog/CollaboratorsFormDialog';
import CollaboratorsRegisterDialog from '../../dialogs/collaboratorsDialog/CollaboratorsRegisterDialog';
import AddCircleIcon from '@mui/icons-material/AddCircle';
import { CleaningServices, FilterAlt, FilterAltOff } from '@mui/icons-material';

function Collaborators() {
  const { setAlert } = useContext(Context);
  const { registerDialog, setRegisterDialog } = useContext(Context);
  const [collaboratorsData, setCollaboratorsData] = useState([]);
  const [editRow, setEditRow] = useState({});
  const { dialog, setDialog } = useContext(Context);
  const { refresh, setRefresh } = useContext(Context);
  const [filter, setFilter] = useState({});
  const [page, setPage] = React.useState(0);
  const [loading, setLoading] = useState(false);
  // Filter section vars
  const [ filterSection, setFilterSection ] = useState(false);
  const [ isAdminLabel, setIsAdminLabel ] = useState("");

  const session_token = localStorage.getItem('session_token');

  const handleValues = (params) => {
    const { field, value } = params;
    if (field === 'status') {
      return getStatus(params);
    }
    return customMask(field, value);
  };

  const collaboratorsColumns = collaboratorsForm.map(
    ({ name, label, width, align, type, hiden }) => ({
      field: name,
      headerName: label,
      flex: width,
      minWidth: 100 * width,
      align: align,
      headerAlign: align,
      type: type,
      hide: hiden,
      filterable: false,
      sortable: false,
      filterOperators: CustomFilter(name),
      valueGetter: (params) => handleValues(params)
    })
  );

  const CollaboratorsList = (filter) =>
    getCollaborators(formatObject(filter), session_token)
      .then(({ data }) => data)
      .catch((err) => setAlert(ExceptionsHandler(err)));

  const collaboratorsListHandler = async (method) => {
    setLoading(true);
    const prevPage = collaboratorsData.actual_page;
    const data = await CollaboratorsList({ ...filter, page: page + 1 });
    const { users } = data;
    if (method === 'add') {
      let newCollaborators = [];
      for (let i = prevPage; i < page; i++) {
        const remainingData = await CollaboratorsList({ ...filter, page: i + 1});
        newCollaborators.push(remainingData.users);
      }
      newCollaborators.push(users);
      setCollaboratorsData({
        ...data,
        users: collaboratorsData.users.concat(newCollaborators.flat())
      });
    } else setCollaboratorsData(data);
    setLoading(false);
  };

  const handleDialogCollaborators = (row) => {
    setEditRow(row);
    setDialog(true);
  };

  useEffect(() => {
    if (refresh === true) {
      setPage(0);
      collaboratorsListHandler();
      setRefresh(false);
    }
  }, [refresh]);

  const handleAddUser = () => {
    setRegisterDialog(true);
  };

  useEffect(() => {
    collaboratorsListHandler();
  }, []);

  useEffect(() => {
    collaboratorsData.has_next && collaboratorsData.actual_page <= page
      ? collaboratorsListHandler('add')
      : null;
  }, [page]);

 //Filter Section Stuff

  const handleAddFilter = () => {
    setFilterSection(!filterSection);
  };

  const mountFilterGrid = () => {
    const styleForGrid = {
      paddingBottom: "10px",
      textAlign: 'center',
      display: 'flex',
      justifyContent: 'center',
      alignItems: 'center',
    }

    const styleForInputs = {
      width: "100%"
    }

    const handleFilterChange = ({ name, value }) => {
      if (value === null || value === ''){
        const filterHelper = filter
        delete filterHelper[name]
        setFilter({...filterHelper})
      }else{
        setFilter({ ...filter, [name]: customMask(name, value) });
      }
    };

    const handleFilterClean = () => {
      setIsAdminLabel("");
      setFilter({});
      setRefresh(true);
    };

    const handleFilterAction = () => {
      setRefresh(true);
    };
    
    return (
      <div style={{paddingBottom: "20px"}}>
        <Grid container>
          <Grid item xs={12}><h3>Filtros:</h3></Grid>
          <Grid item xs={12} sm={4} style={styleForGrid}>
            <TextField
                label="Nome"
                name="name"
                multiline
                value={filter.name || ''}
                onChange={({ target }) => handleFilterChange(target)}
                style={styleForInputs}
                />
          </Grid>
          <Grid item xs={12} sm={4} style={styleForGrid}>
            <TextField
                label="Email"
                name="email"
                multiline
                value={filter.email || ''}
                onChange={({ target }) => handleFilterChange(target)}
                style={styleForInputs}
                />
          </Grid>
          <Grid item xs={12} sm={4} style={styleForGrid}>
            <TextField
                label="CPF"
                name="cpf"
                multiline
                value={filter.cpf || ''}
                onChange={({ target }) => handleFilterChange(target)}
                style={styleForInputs}
                />
          </Grid>
          <Grid item xs={12} sm={4} style={styleForGrid}>
            <FormControl fullWidth>
              <InputLabel id="type-label">Administrador?</InputLabel>
              <Select
                name="is_admin"
                required
                value={isAdminLabel}
                label="Administrador?"
                onChange={({ target }) => setIsAdminLabel(target.value)}
                style={styleForInputs}>
                <MenuItem value={true}>Sim</MenuItem>
                <MenuItem value={false}>Não</MenuItem>
              </Select>
            </FormControl>
          </Grid>
          <Grid item xs={12} sm={12} style={styleForGrid}></Grid>
          <Grid item xs={12} sm={6} style={styleForGrid}>
            <Button 
              style={{height: "100%"}} 
              onClick={handleFilterAction}>
                Filtrar <FilterAlt/>
            </Button>
          </Grid>
          <Grid item xs={12} sm={6} style={styleForGrid}>
            <Button 
              style={{height: "100%"}}  
              variant="outlined" 
              onClick={handleFilterClean}>
                Limpar <CleaningServices/>
            </Button>
          </Grid>
        </Grid>
      </div>
    )
  }

  useEffect(() => {
    if (isAdminLabel === ""){
      const filterHelper = filter
      delete filterHelper.is_admin
      setFilter({...filterHelper})
    } else {
      setFilter({ ...filter, is_admin: isAdminLabel });
    }
  }, [isAdminLabel]);

  return (
    <div className="page-content">
      {dialog && <CollaboratorsFormDialog row={editRow} />}
      {registerDialog && <CollaboratorsRegisterDialog />}
      <Card className="data-grid-card">
        <CardHeader className="card-header" title="Lista de Colaboradores" />
        <CardContent className="card-body">
          <div style={{ width: '100%' }}>
            <div className="data-grid-header">
              <Button 
                style={{width: "auto", marginRight: "5px"}} 
                variant="outlined" 
                onClick={handleAddFilter}>
                  {filterSection ? <FilterAltOff/> : <FilterAlt/>}
                  {Object.keys(filter).length}
              </Button>
              <Button id="btn-reimbursements-register" variant="outlined" onClick={handleAddUser}>
                Adicionar Colaborador
                <AddCircleIcon />
              </Button>
            </div>
            {filterSection && mountFilterGrid()}
            <DataGrid
              rows={collaboratorsData.users || []}
              columns={collaboratorsColumns}
              pageSize={10}
              rowCount={(collaboratorsData.total_pages || 1) * 10}
              page={page}
              autoHeight
              loading={loading}
              disableSelectionOnClick
              onPageChange={(newPage) => (newPage != 'NaN' ? setPage(newPage) : null)}
              onRowDoubleClick={({ row }) => handleDialogCollaborators(row)}
              localeText={dataGridLocale.components.MuiDataGrid.defaultProps.localeText}
              components={{
                Pagination: CustomPagination
              }}
            />
          </div>
        </CardContent>
      </Card>
    </div>
  );
}

export default Collaborators;
