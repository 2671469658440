import { client } from './client';

export const getClientsList = (session_token) => {
    return client.get(`/client/list`, {headers: { Authorization: session_token } });
};

export const getClients = (filter, session_token) => {
    return client.get(`/client/`, { params: filter, headers: { Authorization: session_token } });
};

export const createClients = (clients, session_token) => {
    return client.post(`/client/`, clients, { headers: { Authorization: session_token } });
};

export const updateClients = (clients, session_token) => {
    return client.patch(`/client/`, clients, { headers: { Authorization: session_token } });
};

export const deleteClients = (id, session_token) => {
    return client.delete(`/client/`, { data: {id: id}, headers: { Authorization: session_token } });
};