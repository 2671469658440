import { client } from './client';

export const getUser = (session_token) => {
  return client.get(`/user/`, { headers: { Authorization: session_token } });
};
export const updateUser = (user, session_token) => {
  return client.patch(`/user/`, user, { headers: { Authorization: session_token } });
};
export const createAdmin = (user) => {
  return client.post(`/user/register/admin`, user);
};
export const createUser = (user, session_token) => {
  return client.post(`/user/register`, user, { headers: { Authorization: session_token } });
};
export const changePassword = (payload, session_token) => {
  return client.patch(`/user/password`, payload, { headers: { Authorization: session_token } });
};
export const deleteUser = (payload, session_token) => {
  return client.delete(`/user/`, { data: payload, headers: { Authorization: session_token } });
};
export const getCollaborators = (filter, session_token) => {
  return client.get(`/user/list`, { params: filter, headers: { Authorization: session_token } });
};
export const sendSignPlan = (signPlan) => {
  return client.post(`/user/sign/plan`, signPlan);
};
export const changeAdminPermission = (payload, session_token) => {
  return client.patch(`/user/admin`, payload, { headers: { Authorization: session_token } });
};
export const getLogoUrl = (session_token) => {
  return client.get(`/user/logo`, { headers: { Authorization: session_token } });
};
export const postLogoUrl = (logo_file, session_token) => {
  return client.post(`/user/logo`, logo_file, { headers: { Authorization: session_token } });
};