import { client } from './client';

export const getMineReimbursements = (filter, session_token) => {
    return client.get(`/reimbursement/mine`, { params: filter, headers: { Authorization: session_token } });
};

export const getReimbursements = (filter, session_token) => {
    return client.get(`/reimbursement/`, { params: filter, headers: { Authorization: session_token } });
};

export const createReimbursement = (reimbursement, session_token) => {
    return client.post(`/reimbursement/`, reimbursement, { headers: { Authorization: session_token } });
};

export const createReimbursementFile = (reimbursement_file, session_token) => {
    return client.post(`/reimbursement/s3`, reimbursement_file, { headers: { Authorization: session_token } });
};

export const updateReimbursement = (reimbursement, session_token) => {
    return client.patch(`/reimbursement/`, reimbursement, { headers: { Authorization: session_token } });
};

export const deleteReimbursement = (id, session_token) => {
    return client.delete(`/reimbursement/`, { data: {id: id}, headers: { Authorization: session_token } });
};

export const getReimbursementsUrl = (value, session_token) => {
    return client.get(`/reimbursement/url`, { params: {file_url: value}, headers: { Authorization: session_token } });
};