import React, { useContext } from 'react';
import Context from '../../../../context/Context';
import { Box, FormControl, InputLabel, Select, MenuItem, Typography } from '@mui/material';

function generateInvoice() {
  const [attach_files, setAttachFiles] = React.useState(false);
  const { setInvoiceData } = useContext(Context);

  const handleChange = (event) => {
    setAttachFiles(event.target.value);
    setInvoiceData((prevData) => ({ ...prevData, attach_files: event.target.value }));
  };

  return (
    <Box sx={{ display: 'flex', flexDirection: 'column',alignItems: 'center', justifyContent: 'center' }}>
      <Typography variant='h5' mb={1} mx={0}>Deseja anexar os comprovantes na fatura? </Typography>
      <FormControl sx={{width: '100px'}}>
        <Select
          value={attach_files}
          onChange={handleChange}>
          <MenuItem value={true}>Sim</MenuItem>
          <MenuItem value={false}>Não</MenuItem>
        </Select>
      </FormControl>
    </Box>
  );
}
export default generateInvoice;
