import { Card, CardContent, CardHeader, Grid, TextField, ToggleButton, ToggleButtonGroup } from '@mui/material';
import React, {useContext, useEffect, useState} from 'react';

import PieMais from '../../charts/PieMais';
import BarMais from '../../charts/BarMais';
import DoughnutMais from '../../charts/DoughnutMais';
import { customMask } from '../../../utils/helpers';
import LineMais from '../../charts/LineMais';
import { LocalizationProvider, DatePicker, ptBR as datePickerLocale } from '@mui/x-date-pickers';
import { AdapterDateFns } from '@mui/x-date-pickers/AdapterDateFns';
import { ptBR as ptBRLocale } from 'date-fns/locale';
import { getDashboard } from '../../../services/dashboards';
import Context from '../../../context/Context';

function subtractMonths(date, months) {
  const diffMonth = date.getMonth() - months
  date.setMonth(diffMonth);
  return date;
}

function Dashboard() {
  const { setAlert } = useContext(Context);

  // Filter Stuff
  const [alignment, setAlignment] = useState('1');
  const [endDate, setEndDate] = useState(new Date(Date.now()));
  const [initDate, setInitDate] = useState(subtractMonths(new Date(Date.now()), alignment));

  const session_token = localStorage.getItem('session_token');
  const [dashData, setDashData] = useState(null);
  
  const GetDashboard = async () =>{
    const initDatePayload = initDate.getFullYear() + "-" + (initDate.getMonth()+1) + "-" + initDate.getDate()
    const endDatePayload = endDate.getFullYear() + "-" + (endDate.getMonth()+1) + "-" + endDate.getDate()
    return await getDashboard({
      init_dt: initDatePayload,
      end_dt: endDatePayload
    }, session_token).then(
      ({data}) => {
        setDashData(data)
      }
    )
    .catch((err) => setAlert(ExceptionsHandler(err)));
  } 

  const handleAlignment = (event, newAlignment) => {
    switch (newAlignment) {
      case '1':
        setEndDate(new Date(Date.now()));
        setInitDate(subtractMonths(new Date(Date.now()), 1));
        break;
      case '3':
        setEndDate(new Date(Date.now()));
        setInitDate(subtractMonths(new Date(Date.now()), 3));
        break; 
      case '12':
        setEndDate(new Date(Date.now()));
        setInitDate(subtractMonths(new Date(Date.now()), 12));
        break;
      case 'Outros': 
        break;
    };
    setAlignment(newAlignment);
  };
  // Filter Stuff

  const styleForGrid = {
    padding: "10px 10px 20px 10px", 
    margin: 'auto',
    alignItems: 'center',
    justifyContent: 'center',
    textAlign: 'center',
  }

  useEffect(() => {
    GetDashboard();
  }, []);

  useEffect(() => {
    GetDashboard();
  }, [initDate, endDate]);
     
  return (
    <div className="page-content">
      <Card>
      <CardHeader className="card-header" title="Dashboard" />
        <CardContent className="card-body">
        <Grid container>
          <Grid item sm={12} style={{margin: 'auto', padding:'10px'}}>
            <b>Selecione o Período:</b><br/>
            <ToggleButtonGroup
              value={alignment}
              exclusive
              onChange={handleAlignment}
            >
              <ToggleButton value="1">
                1 M
              </ToggleButton>
              <ToggleButton value="3">
                3 M
              </ToggleButton>
              <ToggleButton value="12">
                1 A
              </ToggleButton>
              <ToggleButton value="calendar">
                Outros
              </ToggleButton>
            </ToggleButtonGroup>
          </Grid>
          {alignment === 'calendar' && <Grid item sm={12} style={{margin: 'auto', padding:'10px'}}>
            <LocalizationProvider
                dateAdapter={AdapterDateFns}
                adapterLocale={ptBRLocale}
                localeText={
                  datePickerLocale.components.MuiLocalizationProvider.defaultProps.localeText
                }>
                <DatePicker
                  disableFuture={true}
                  className="input-filter"
                  name="init_dt"
                  label="Início"
                  openTo="day"
                  views={['year', 'month', 'day']}
                  inputFormat="dd/MM/yyyy"
                  renderInput={(params) => <TextField {...params} />}
                  value={initDate}
                  onChange={(newValue) => setInitDate(newValue)}
                /> <DatePicker
                disableFuture={true}
                className="input-filter"
                name="end_dt"
                label="Fim"
                openTo="day"
                views={['year', 'month', 'day']}
                inputFormat="dd/MM/yyyy"
                renderInput={(params) => <TextField {...params} />}
                value={endDate}
                onChange={(newValue) => setEndDate(newValue)}
              />
            </LocalizationProvider>
            {(endDate < initDate) && <b style={{color: "red"}}><br/>Inválido: Data final está antes da data de início</b>}
          </Grid>}
          {dashData ? <>
          <Grid item xs={12} sm={5} md={3.5} style={styleForGrid}>
            <PieMais 
              title="Somatória dos Centros de Custos pagos e não pagos"
              data={dashData.pie_chart_sum_op.data}
              dataLabel="# Valor em R$" 
              labels={dashData.pie_chart_sum_op.labels}
              />         
          </Grid>
          <Grid item xs={12} sm={7} md={5.5} style={styleForGrid}>
            <BarMais 
              title="Quantidade de despesas criadas por mês"
              data={dashData.bar_chart_total_expenses.data}
              labels={dashData.bar_chart_total_expenses.labels}
            />
          </Grid>
          <Grid item xs={12} sm={5} md={3} style={styleForGrid}>
            <DoughnutMais 
              title="Somatória dos valores reembolsados aos colaboradores"
              data={dashData.dunut_chart_sum_workers_debts.data}
              dataLabel="# Valor em R$" 
              labels={dashData.dunut_chart_sum_workers_debts.labels}
            /> 
          </Grid>
          <Grid item xs={12} sm={7} md={8} style={styleForGrid}>
            <BarMais 
              title="Somatória das despesas aprovadas por mês (em R$)"
              data={dashData.bar_chart_sum_expenses_month.data}
              labels={dashData.bar_chart_sum_expenses_month.labels}
            />
          </Grid>
          <Grid item xs={12} sm={6} md={4} style={styleForGrid}>
            <DoughnutMais 
              title="Somatória das despesas aprovadas"
              data={dashData.dunut_chart_sum_expenses.data}
              dataLabel="# Valor em R$" 
              labels={dashData.dunut_chart_sum_expenses.labels}
            />
          </Grid>
          <Grid item xs={12} sm={6} md={4} style={styleForGrid}>
            <PieMais 
              title="Somatória de despesas (aprovadas) internas e externas"
              data={dashData.pie_chart_int_ext_expenses.data}
              dataLabel="# Valor em R$" 
              labels={dashData.pie_chart_int_ext_expenses.labels}
            />  
          </Grid>
          <Grid item xs={12} sm={7} md={8} style={styleForGrid}>
            <BarMais 
              title="Somatória de despesas (aprovadas) internas e externas por mês"
              data={dashData.bar_chart_int_ext_expenses_month.data}
              labels={dashData.bar_chart_int_ext_expenses_month.labels}
            />
          </Grid>
          <Grid item xs={12} sm={5} md={4} style={styleForGrid}>
            <div style={{textAlign: "left"}}>
              <br/>
              <hr color="#48c0ff"/>
              <div style={{margin: "0 5px 0 5px"}}>
                <b>Faturas:</b><br/>
                Total faturas emitidas: {dashData.data_info_chart.invoice_total_amount}<br/>
                Total das faturas: {customMask('value', dashData.data_info_chart.invoice_total_value)}<br/>
                Média valor das fatura: {customMask('value', dashData.data_info_chart.invoice_average_value)}<br/>
              </div>
              <hr color="#48c0ff"/>
              <div style={{margin: "0 5px 0 5px"}}>
                <b>Despesas:</b><br/>
                Média Alimentação: {customMask('value', dashData.data_info_chart.food_average_value)}<br/>
                Média Transporte: {customMask('value', dashData.data_info_chart.transport_average_value)}<br/>
                Média Hospedagem: {customMask('value', dashData.data_info_chart.accommodation_average_value)}<br/>
                Média Custas Judiciais: {customMask('value', dashData.data_info_chart.court_costs_average_value)}<br/>
                Média Outras Despesas: {customMask('value', dashData.data_info_chart.others_costs_average_value)}<br/>
              </div>
              <hr color="#48c0ff"/>
            </div>
          </Grid>
          <Grid item xs={12} sm={12} md={8} style={styleForGrid}>
            <LineMais 
              title="Acumulativo em R$"
              data={dashData.line_chart_cumulative.data}
              labels={dashData.line_chart_cumulative.labels}
              />
          </Grid>
          </> : "CARREGANDO"}
        </Grid>
      </CardContent>
      </Card>
    </div>
  );
}

export default Dashboard;
